import React, { useEffect, useState } from "react";
import ItemNecesidad from "./ItemNecesidad";
import Pager from "../common/Pager";
import { useParams, useHistory, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl, isInt } from "../common/share-util";
import "./ListaNecesidades.scss";

function ListaNecesidades(props) {

  const pageSize = 25;
  const maxPageNumbers = 5;

  const [necesidades, setNecesidades] = useState([]);

  const [necesidadesPage, setNecesidadesPage] = useState([]);

  const [loadingNecesidades, setLoadingNecesidades] = useState(false);

  const [datosContacto, setDatosContacto] = useState({
    nombre: '',
    organizacion: '',
    email: '',
    telefono: '',
    unidades: '',
    erroresValidacion: '',
    loadingResponse: false,
    errorRespuesta: false
  });

  const [categoria, setCategoria] = useState({
    name: '',
    description: ''
  });

  const [necesidadId, setNecesidadId] = useState('');

  const [currentPage, setCurrentPage] = useState(0);

  let history = useHistory();

  useEffect(() => {
    if (props.redirectAPublica) {
      props.onDidRedirect(true);
      history.push('/publica');
    }
  }, [props.redirectAPublica]);

  useEffect(() => {
    window.tippy('[data-tippy-placement]', {
      delay: 100,
      arrow: true,
      arrowType: 'sharp',
      size: 'regular',
      duration: 200,
      animation: 'shift-away',
      animateFill: true,
      theme: 'dark',
      distance: 10,
    });
    handleChangePage(0);
  }, [necesidades]);

  let { categoriaId } = useParams();

  useEffect(() => {
    setLoadingNecesidades(true);
    window.$.post(cicApiUrl + 'api/Needs/getNeeds', {
      "category": categoriaId
    }, (data, status) => {
      setLoadingNecesidades(false);
      if (status === 'success') {
        let necesidadesMapped = data.map((necesidad) => {
          if (necesidad.category === '5e90c3a229657d19444c88cf') {
            if (!necesidad.image) {
              necesidad.image = 'https://apoyacic.s3.amazonaws.com/imagenes/alimentos_despensas.jpg';
            }
          }
          if (necesidad.category === '5e904aae8ed980d4758250eb') {
            if (!necesidad.image) {
              necesidad.image = 'https://apoyacic.s3.amazonaws.com/imagenes/articulos_limpieza.jpg';
            }
          }
          if (necesidad.category === '5e904a298ed980d47582490f') {
            if (!necesidad.image) {
              necesidad.image = 'https://apoyacic.s3.amazonaws.com/imagenes/medicamentos_accesorios.jpg';
            }
          }
          if (necesidad.category === '5e8e0d7aecd79f0b79158608') {
            if (!necesidad.image) {
              necesidad.image = 'https://apoyacic.s3.amazonaws.com/imagenes/equipo_medico.jpg';
            }
          }
          return necesidad;
        });
        setNecesidades(necesidadesMapped);
      } else {
        console.log('error al obtener necesidades', status);
      }
    });
  }, [categoriaId]);

  useEffect(() => {
    if (props.listaPorEspecie.length > 0) {
      let categoria = props.listaPorEspecie.find((categoria) => {
        if (categoria.id === categoriaId) {
          return true;
        }
      });
      setCategoria(categoria);
    }
  }, [props.listaPorEspecie, categoriaId]);

  useEffect(() => {
    window.$("a.close").removeAttr("href").on('click', function () {
      function slideFade(elem) {
        var fadeOut = { opacity: 0, transition: 'opacity 0.5s' };
        elem.css(fadeOut).slideUp();
      }
      slideFade(window.$(this).parent());
    });
    window.$('.apoya-button').magnificPopup({
      type: 'inline',
      fixedContentPos: false,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close necesidad-publicada-close"></button>'
    });
    window.$('.datos-enviados-link').magnificPopup({
      type: 'inline',

      fixedContentPos: false,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close necesidad-publicada-close"></button>',
      callbacks: {
        close: function () {
        }
      }
    });
  }, []);

  function handleSetNecesidadId(necesidadId) {
    setNecesidadId(necesidadId);
    window.$('.apoya-button').magnificPopup('open');
  }

  function handleChangePage(page) {
    let paginaNecesidades = necesidades.slice(page * pageSize, (page * pageSize) + pageSize);
    setNecesidadesPage(paginaNecesidades);
    setCurrentPage(page);
  }

  function updateNombre(event) {
    let value = event.target.value;
    setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ nombre: value } } });
  }

  function updateOrganizacion(event) {
    let value = event.target.value;
    setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ organizacion: value } } });
  }

  function updateEmail(event) {
    let value = event.target.value;
    setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ email: value } } });
  }

  function updateTelefono(event) {
    let value = event.target.value;
    setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ telefono: value } } });
  }

  function updateUnidades(event) {
    let value = event.target.value;
    setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ unidades: value } } });
  }

  function enviarContacto() {
    document.getElementById('errores-validacion').removeAttribute('style');
    let mensajeError = validarDatos();
    if (mensajeError) {
      setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ erroresValidacion: mensajeError } } });
    } else {
      let payloadData = JSON.stringify({
        "name": datosContacto.nombre,
        "email": datosContacto.email,
        "cel": datosContacto.telefono,
        "organizationName": datosContacto.organizacion,
        "need": necesidadId,
        "quantity": datosContacto.unidades
      });

      setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ loadingResponse: true } } });

      window.$.ajax({
        type: 'POST', url: cicApiUrl + 'api/Needs/join', data: payloadData, success: (data, status) => {
          window.$('.apoya-button').magnificPopup('close');
          if (status === 'success' && data && data.status === 'OK') {
            setDatosContacto({
              nombre: '',
              organizacion: '',
              email: '',
              telefono: '',
              unidades: '',
              erroresValidacion: '',
              loadingResponse: false,
              errorRespuesta: false
            });
            setTimeout(() => {
              window.$('.datos-enviados-link').magnificPopup('open');
            }, 500);
          } else {
            setDatosContacto({
              nombre: '',
              organizacion: '',
              email: '',
              telefono: '',
              unidades: '',
              erroresValidacion: '',
              loadingResponse: false,
              errorRespuesta: true
            });
            setTimeout(() => {
              window.$('.datos-enviados-link').magnificPopup('open');
            }, 500);
          }
        }, contentType: 'application/json'
      });
    }
  }

  function validarDatos() {
    let mensajeError = '';
    if (!datosContacto.nombre || !datosContacto.email) {
      mensajeError = 'Por favor llene todos los campos requeridos.'
    }
    if (!datosContacto.unidades || !isInt(datosContacto.unidades) || datosContacto.unidades < 0) {
      if (mensajeError) {
        mensajeError += ' ';
      }
      mensajeError += 'La cantidad debe ser un número entero mayor a 0.';
    }
    return mensajeError;
  }

  return (
    <React.Fragment>
      <div>
        {
          necesidades.length > pageSize && <Pager currentPage={currentPage} pageSize={pageSize} maxPageNumbers={maxPageNumbers} elementos={necesidades} onPageChange={handleChangePage}></Pager>
        }
        <a style={{ display: 'none' }} href="#small-dialog" className="datos-enviados-link"></a>
        <div id="small-dialog" className="zoom-anim-dialog mfp-hide dialog-with-tabs">
          <div className="sign-in-form">
            <div className="popup-tab-content">

              <div className="welcome-text necesidad-publicada-diag-content">
                {
                  !datosContacto.errorRespuesta ? <><i className="icon-material-outline-check-circle"></i><h3>¡Gracias por tu ayuda!</h3><h3> La institución responsable se pondrá en contacto contigo </h3></>
                    :
                    <><h3>Hubo un error al enviar los datos.</h3> <h3>Por favor intenta de nuevo mas tarde, y si persiste el problema ponte en contacto con nosotros.</h3></>
                }
              </div>
            </div>
          </div>
        </div>
        <a style={{ display: 'none' }} href="#sign-in-dialog" className=" button ripple-effect big margin-top-30 apoya-button"></a>
        <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide dialog-with-tabs enviar-datos-contacto">
          {datosContacto.loadingResponse && <div className="datos-enviados-loading">
            <Loader
              type="Grid"
              color="#2a41e8"
              height={200}
              width={200}
            />
          </div>
          }
          <div className="sign-in-form">

            <div className="popup-tabs-container">

              <div className="popup-tab-content">

                <div className="welcome-text">
                  <h3>Deja tus datos si deseas apoyar esta necesidad.</h3>
                  <span>La institución responsable se pondrá en contacto contigo</span>
                </div>

                <div id="errores-validacion" className={'notification error closeable ' + (!datosContacto.erroresValidacion ? 'sin-errores' : '')}>
                  <p>{datosContacto.erroresValidacion}</p>
                  <a className="close" href="#"></a>
                </div>

                <div className="input-with-icon-left">
                  <i className="icon-material-outline-account-circle"></i>
                  <input maxLength="200" onChange={updateNombre} value={datosContacto.nombre} type="text" className="input-text with-border" placeholder="Nombre*" />
                </div>

                <div className="input-with-icon-left">
                  <i className="icon-material-outline-account-balance"></i>
                  <input maxLength="200" onChange={updateOrganizacion} value={datosContacto.organizacion} className="input-text with-border" placeholder="Organización" />
                </div>

                <div className="input-with-icon-left">
                  <i className="icon-material-baseline-mail-outline"></i>
                  <input maxLength="100" onChange={updateEmail} value={datosContacto.email} className="input-text with-border" placeholder="Email*" />
                </div>

                <div className="input-with-icon-left">
                  <i className="icon-feather-phone"></i>
                  <input onChange={updateTelefono} maxLength="50" value={datosContacto.telefono} className="input-text with-border" placeholder="Teléfono/Celular" />
                </div>

                <div className="input-with-icon-left">
                  <i className="icon-feather-heart"></i>
                  <input onChange={updateUnidades} className="input-text with-border" value={datosContacto.unidades} type="number" min="1" placeholder='Unidades*' />
                </div>
                {!datosContacto.loadingResponse &&
                  <button onClick={() => { enviarContacto() }} className="button full-width button-sliding-icon ripple-effect">Enviar <i className="icon-material-outline-arrow-right-alt"></i></button>
                }

              </div>

            </div>
          </div>
        </div>
        <div className="listings-container margin-top-15">
          {
            necesidadesPage.length > 0 ? <>
              <div style={{ width: '100%' }}><Link to={'/'}>
                <i className="icon-material-outline-arrow-back"></i>Volver a Inicio
                  </Link></div>
              {categoria && <>
                <div className="titulo-categoria">{categoria.name}</div>
                <div className="texto-categoria">{categoria.description}</div>
              </>
              }
              {
                categoriaId === 'prioritarias' && <>
                  <div className="descripcion-prioritarias-wrapper">
                    <div className="descripcion-texto-block">
                      <div className="titulo-categoria">Entre todos es más fácil</div>
                      <div className="texto-categoria">Te presentamos las necesidades prioritarias que tienen un costo superior a tres mil pesos mexicanos.</div>
                      <div className="texto-categoria-b">Ahora puedes dar tu granito de arena.</div>
                    </div>
{/*                     <div className="pasos-prioritarias-block">
                      <div className="pasos-prioritarias-label">Pasos</div>
                      <div className="pasos-prioritarias">
                        <span>Selecciona una necesidad</span>
                        <span>ApoYA con la cantidad que puedas donar</span>
                        <span>Entre varios se junta el monto</span>
                        <span>Compramos y entregamos por ti</span>
                      </div>
                    </div> */}
                  </div>
                </>
              }
              {
                necesidadesPage.map((necesidad) => {
                  necesidad.description = necesidad.description || '';
                  return <ItemNecesidad onSeleccionarNecesidadCheckout={props.onSeleccionarNecesidadCheckout} onSelectNecesidadId={handleSetNecesidadId} necesidad={necesidad} key={necesidad._id} iscic={props.iscic}></ItemNecesidad>
                }
                )}
            </> : <>
                <Link to={'/'}>
                  <i className="icon-material-outline-arrow-back"></i>Volver a Inicio
                    </Link>
                {!loadingNecesidades &&
                  <div style={{ marginTop: '20px' }}>
                    Por el momento no hay ninguna necesidad publicada en esta categoría. Si estas interesado en ayudar, te invitamos a registrarte en la sección                 <Link to={'/apoya'}>
                      {' Apoya.'}
                    </Link>
                  </div>
                }
                {
                  loadingNecesidades &&
                  <div style={{ position: 'fixed', top: 'calc(50vh - 100px)', left: 'calc(50vw - 100px)', zIndex: 999 }}>
                    <Loader
                      type="Grid"
                      color="#2a41e8"
                      height={200}
                      width={200}
                    />
                  </div>
                }
              </>
          }
        </div>
        {
          necesidades.length > pageSize && <Pager currentPage={currentPage} pageSize={pageSize} maxPageNumbers={maxPageNumbers} elementos={necesidades} onPageChange={handleChangePage}></Pager>
        }
      </div>
    </React.Fragment>
  );

}

export default ListaNecesidades;