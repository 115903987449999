import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl } from "../../common/share-util";


import './TestimoniosAdmin.scss';
import TestimoniosAdminTable from "./TestimoniosAdminTable";

function TestimoniosAdmin(props) {
	const [loading, setLoading] = useState(false);
	const [testimonios, setTestimonios] = useState([]);

	let history = useHistory();

	useEffect(() => {
		if (!props.isLoggedIn) {
			history.push('/necesidades');
		}
	});

	useEffect(() => {
		getTestimoniosAdmin();
	}, []);

	function getTestimoniosAdmin(){
		if (props.isLoggedIn) {
			let token = sessionStorage.getItem('token-apoya');
			if (token) {
				setLoading(true);
				window.$.post(cicApiUrl + 'api/Testimonies/getTestimoniesAdmin', {
					token: sessionStorage.getItem('token-apoya')
				} ,(data, status) => {
					setLoading(false);
					if (status === 'success' && data && data.length > 0) {
						setTestimonios(data);
					} else {
						if (data && data.length === 0) {
							setTestimonios([]);
						} else {
							console.log('error al obtener testimonios', status);
						}
					}
				});
			}
		}
	}

	function handleUpdateTestimonios(){
		getTestimoniosAdmin();
	}

	return (
		<React.Fragment>
			<div className="testimonios-admin">
				<div className="dashboard-content-inner" >
					{loading && <div style={{ position: 'fixed', top: 'calc(50vh - 100px)', left: 'calc(50vw - 100px)', zIndex: 999 }}>
						<Loader
							type="Grid"
							color="#2a41e8"
							height={200}
							width={200}
						/>
					</div>
					}
					{
						(testimonios.length === 0 && !loading) ?
							<>
								<div className="welcome-text">
									<h3>Aún no hay testimonios.</h3>
								</div> </> : <>
								<TestimoniosAdminTable onUpdateTestimonios={handleUpdateTestimonios} data={testimonios}></TestimoniosAdminTable>
								<div className="dashboard-footer-spacer"></div>
							</>
					}
				</div>
			</div>
		</React.Fragment>
	);
}

export default TestimoniosAdmin;
