import React from "react";
import './Compartir.scss';

class Compartir extends React.Component {
  constructor(props) {
    super(props);
  }
  cancelClick(event) {
    event.preventDefault();
  }
  render() {
    let url = ''; 
    if(!this.props.necesidadId){
      url = encodeURIComponent(window.location.href);
    }else{
      url = 'https://apoyacic.cic.mx/n/' + this.props.necesidadId;
    }
    return (
      <React.Fragment>
        <div style={this.props.style} className="share-buttons">
          <div className="share-buttons-trigger">
            <i className="icon-feather-share-2"></i>
          </div>
          <div className="share-buttons-content">
            <span className="compartir-title">
              <strong style={{ color: '#777' }}>Compartir</strong>
            </span>
            <ul className="share-buttons-icons">
              <li>
                <a
                  href={"https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=" + url + "&display=page&ref=plugin&src=share_button"}
                  data-button-color="#3b5998"
                  style={{ backgroundColor: '#3b5998' }}
                  title="Comparte en Facebook"
                  data-tippy-placement="top"
                  target="_blank"
                >
                  <i className="icon-brand-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href={"https://twitter.com/intent/tweet?url=" + url}
                  data-button-color="#1da1f2"
                  style={{ backgroundColor: '#1da1f2' }}
                  title="Comparte en Twitter"
                  data-tippy-placement="top"
                  target="_blank"
                >
                  <i className="icon-brand-twitter"></i>
                </a>
              </li>
               <li>
                <a
                  href={"https://www.linkedin.com/shareArticle?mini=true&url=" + url}
                  data-button-color="#0077b5"
                  style={{ backgroundColor: '#0077b5' }}
                  title="Comparte en LinkedIn"
                  data-tippy-placement="top"
                  target="_blank"
                >
                  <i className="icon-brand-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Compartir;
