import React from "react";
import { Link } from "react-router-dom";
import "./CarruselProveedores.scss";

class CarruselProveedores extends React.Component {

  componentDidMount() {
    window.$('.default-slick-carousel-proveedores').slick({
      infinite: false,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1292,
          settings: {
            dots: false,
            arrows: false
          }
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false
          }
        }
      ]
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="section carrusel-proveedores padding-top-65 full-width-carousel-fix">
          <div style={{ textAlign: 'center' }}>
            <div className="brigadistas-titulo margin-top-0 margin-bottom-25">
              <h3>Aliados</h3>
            </div>
          </div>

          <div style={{ margin: 'auto' }}>
            <div className="default-slick-carousel default-slick-carousel-proveedores freelancers-container freelancers-grid-layout">

            <div className="freelancer">
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    <div className="freelancer-avatar">
                      <a target="_blank" href="https://www.cemexmexico.com/">
                        <img src="https://apoyacic.s3.amazonaws.com/imagenes/cemex_logo.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="freelancer">
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    <div className="freelancer-avatar">
                      <a target="_blank" href="https://www.tigres.com.mx/">
                        <img src="https://apoyacic.s3.amazonaws.com/imagenes/Tigres_logo_apoya.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="freelancer">
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    <div className="freelancer-avatar">
                      <a target="_blank" href="https://derby.mx/">
                        <img src="https://apoyacic.s3.amazonaws.com/imagenes/logo_derby_apoya.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="freelancer">
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    <div className="freelancer-avatar">
                      <a target="_blank" href="https://vitau.mx/">
                        <img src="https://apoyacic.s3.amazonaws.com/imagenes/vitau_logo_2828.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="freelancer">
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    <div className="freelancer-avatar">
                      <a target="_blank" href="https://www.facebook.com/SuperInvernadero/">
                        <img src="https://apoyacic.s3.amazonaws.com/imagenes/sopas_proveedor.jpeg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>

{/*             <Link to="/registro-proveedor" className="col-xl-12 brigadistas-inscribete">Súmate como Proveedor Aliado <i className="icon-material-outline-arrow-right-alt"></i></Link>
 */}          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CarruselProveedores;
