import React, { useEffect, useState } from "react";
import "./AgregarGastosTestimonioDialog.scss";
import Loader from "react-loader-spinner";
import { subirImagen, cicApiUrl, isInt } from "../../common/share-util";

function AgregarGastosTestimonioDialog(props) {

  const [loadingResponse, setLoadingResponse] = useState(false);
  const [errorRespuesta, setErrorRespuesta] = useState(false);
  const [documentoDataUri, setDocumentoDataUri] = useState('');
  const [documentoBlob, setDocumentoBlob] = useState(null);
  const [documentoEsPDF, setDocumentoEsPDF] = useState(false);
  const [tipoGasto, setTipoGasto] = useState('');
  const [cantidadGasto, setCantidadGasto] = useState(0);
  const [erroresValidacion, setErroresValidacion] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');

  useEffect(() => {
    window.$("a.close").removeAttr("href").on('click', function () {
      function slideFade(elem) {
        var fadeOut = { opacity: 0, transition: 'opacity 0.5s' };
        elem.css(fadeOut).slideUp();
      }
      slideFade(window.$(this).parent());
    });
  }, []);

  useEffect(() => {
    if (!props.testimonio || !props.testimonio.ticket) {
      setDocumentoDataUri('');
      setDocumentoBlob(null);
      setDocumentoEsPDF(false);
      setErroresValidacion('');
      setLoadingResponse(false);
      setErrorRespuesta(false);
      setTipoGasto('');
      setMensajeExito('');
      window.$('.selectpicker').selectpicker('val', '');
      setCantidadGasto(0);
    }
  }, [props.testimonio]);

  function actualizarCantidadGasto(event) {
    let value = event.target.value;
    if (isInt(value) || value === '') {
      setCantidadGasto(value);
    }
  }

  function updateTipoGasto(event) {
    let input = event.target.value;
    setTipoGasto(input);
  }

  function fileChangeDocumento(event) {
    let targetElement = event.target;
    if (targetElement.files) {
      for (let i = 0; i < targetElement.files.length; i++) {
        if (targetElement.files[i].type.includes('image/') || targetElement.files[i].type.includes('pdf')) {
          if (targetElement.files[i].type.includes('pdf')) {
            setDocumentoEsPDF(true);
          } else {
            setDocumentoEsPDF(false);
          }
          let reader = new FileReader();
          setDocumentoBlob(targetElement.files[i]);
          reader.readAsDataURL(targetElement.files[i]);
          reader.onload = (event) => {
            setDocumentoDataUri(reader.result);
          }
        }
      }
    }
  }

  function quitarDocumento() {
    setDocumentoDataUri('');
    setDocumentoBlob(null);
    setDocumentoEsPDF(false);
  }

  function agregarGasto() {
    document.getElementById('errores-validacion').removeAttribute('style');
    document.getElementById('mensaje-exito').removeAttribute('style');
    setMensajeExito('');
    let mensajeError = validarInput();
    if (!mensajeError) {
      setLoadingResponse(true);
      if (documentoBlob) {
        subirImagen('apoyacic/testimonios', documentoBlob, (error, documentoS3Url) => {
          if (!error) {
            enviarDatos(documentoS3Url);
          }
        });
      } else {
        enviarDatos();
      }
    } else {
      setErroresValidacion(mensajeError);
    }
  }

  function enviarDatos(documentoS3Url) {
    let payloadData = JSON.stringify({
      token: sessionStorage.getItem('token-apoya'),
      testimonyId: props.testimonio._id,
      gastos: [{
        tipoGasto: tipoGasto,
        cantidad: cantidadGasto,
        comprobanteUrl: documentoS3Url || ''
      }]
    });
    window.$.ajax({
      type: 'POST', url: cicApiUrl + 'api/Testimonies/addExpenses', data: payloadData, success: (data, status) => {
        setLoadingResponse(false);
        if (status === 'success' && data && data.status === 'OK') {
          setDocumentoDataUri('');
          setDocumentoBlob(null);
          setDocumentoEsPDF(false);
          setErroresValidacion('');
          setLoadingResponse(false);
          setErrorRespuesta(false);
          setMensajeExito('Gasto registrado con éxito.');
          props.onUpdateTestimonios();
          window.$('.selectpicker').selectpicker('val', '');
          setTipoGasto('');
          setCantidadGasto(0);
        } else {
          setDocumentoDataUri('');
          setDocumentoBlob(null);
          setDocumentoEsPDF(false);
          setErroresValidacion('Hubo un error al registrar el gasto.');
          setLoadingResponse(false);
          setErrorRespuesta(true);
          window.$('.selectpicker').selectpicker('val', '');
          setTipoGasto('');
          setCantidadGasto(0);
        }
      }, contentType: 'application/json'
    });
  }

  function validarInput() {
    let mensajeError = '';
    if (!cantidadGasto || (cantidadGasto <= 0)) {
      mensajeError = 'La cantidad debe ser mayor a 0.';
    }
    if (!tipoGasto) {
      if (mensajeError) {
        mensajeError += ' ';
      }
      mensajeError += 'Debe seleccionar un tipo de gasto.';
    }
    return mensajeError;
  }

  return (
    <>
      <div id="small-dialog-2" className="zoom-anim-dialog mfp-hide dialog-with-tabs agregar-gastos-testimonio-dialog">
        {loadingResponse && <div className="datos-enviados-loading">
          <Loader
            type="Grid"
            color="#2a41e8"
            height={200}
            width={200}
          />
        </div>
        }
        <div className="crear-testimonio-form">

          <div className="popup-tabs-container">

            <div className="popup-tab-content">

              <div className="welcome-text">
                <h3>Gastos testimonio</h3>
                <span>De necesidad: {props.testimonio.ticket}</span>
              </div>
              <ul>
                {props.testimonio.gastos && props.testimonio.gastos.length > 0 &&
                  props.testimonio.gastos.map((gasto) => {
                    return <li key={gasto._id}>
                      <span>{gasto.tipoGasto}</span><div>${gasto.cantidad}</div><div><a target="_blank" href={gasto.comprobanteUrl}>{gasto.comprobanteUrl}</a></div>
                    </li>
                  })
                }
              </ul>
              {(!props.testimonio.gastos || props.testimonio.gastos.length === 0) &&
                <div style={{ textAlign: 'center' }}>No hay gastos registrados</div>
              }
              <div className="separacion-lista-agregar"></div>
              <h3 className="titulo-agregar-gasto">Agregar gasto</h3>
              <div id="errores-validacion" className={'notification error closeable ' + (!erroresValidacion ? 'sin-errores' : '')}>
                <p>{erroresValidacion}</p>
                <a className="close" href="#"></a>
              </div>
              <div id="mensaje-exito" className={'notification success closeable ' + (!mensajeExito ? 'sin-mensaje-exito' : '')}>
                <p>{mensajeExito}</p>
                <a className="close" href="#"></a>
              </div>
              <div className="agregar-gasto-control">
                <span className="gasto-control-label">Tipo:</span>
                <select onChange={updateTipoGasto} className="selectpicker with-border" data-size="7" title="Selecciona tipo">
                  <option>Comisión Pago</option>
                  <option>Mercancía</option>
                  <option>Logística</option>
                </select>
              </div>
              <div className="agregar-gasto-control">
                <span className="gasto-control-label">Cantidad:</span>
                <span>$</span>
                <input className="gasto-cantidad-input" type="number" min="0" step="1" value={cantidadGasto} onChange={(event) => { actualizarCantidadGasto(event) }}>
                </input>
              </div>
              {!documentoBlob &&
                <div className="uploadButton">
                  <input className="uploadButton-input" type="file" accept="image/*,.pdf" id="upload-doc" onChange={fileChangeDocumento} />
                  <label className="uploadButton-button ripple-effect" htmlFor="upload-doc">Subir documento</label>
                  <span className="uploadButton-file-name">Identificación del donatario. (Imagen o PDF)</span>
                </div>
              }
              {(documentoBlob && documentoDataUri) &&
                <>
                  <h5 style={{ marginBottom: '10px' }}>Documento seleccionado</h5>
                  {!documentoEsPDF && <img className="imagen-testimonio-preview" src={documentoDataUri}></img>}
                  {documentoEsPDF && <span className="pdf-preview">PDF</span>}
                  <span onClick={quitarDocumento} style={{ height: '42px', marginLeft: '10px', marginTop: '20px' }} className="button dark quitar-img-btn ripple-effect">
                    Quitar documento
                  </span>
                </>
              }
              {!loadingResponse &&
                <button onClick={() => { agregarGasto() }} className="button full-width button-sliding-icon ripple-effect">Guardar <i className="icon-material-outline-arrow-right-alt"></i></button>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  );

}

export default AgregarGastosTestimonioDialog;