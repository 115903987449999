import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import './ComoFunciona.scss';

function ComoFunciona(props) {

	let history = useHistory();

	useEffect(() => {
		if (props.redirectAPublica) {
			props.onDidRedirect(true);
			history.push('/publica');
		}
	}, [props.redirectAPublica]);


	return (
		<React.Fragment>
			<div className="comofunciona-wrapper">
				<div className="comofunciona-texto">
					<h1 className="comofunciona-titulo">¿Cómo funciona ApoYA?</h1>
					<h3 className="comofunciona-subtitulo">La plataforma que articula y coordina para conectar el apoyo con la necesidad.</h3>
					<div className="comofunciona-descripcion">
						ApoYA es una plataforma desarrollada por el Centro de Integración Ciudadana y Consejo Nuevo León que registra, articula, y coordina para conectar personas, empresas, sociedad civil con las necesidades de salud y económicas de población vulnerable del estado de Nuevo León.
					</div>
				</div>
				<img src="https://apoyacic.s3.amazonaws.com/imagenes/COMO_FUNCIONA.png"></img>
			</div>
		</React.Fragment>
	);
}

export default ComoFunciona;
