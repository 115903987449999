import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl } from "../common/share-util";

import './RegistroProveedor.scss';
import CarruselProveedores2 from "../categorias/CarruselProveedores2";

function RegistroProveedor(props) {
	const [datosContacto, setDatosContacto] = useState({
		nombre: '',
		organizacion: '',
		email: '',
		telefono: '',
		descripcion: '',
		erroresValidacion: '',
		loadingResponse: false,
		errorRespuesta: false,
		perteneceOrganizacion: false
	});


	const [checkBoxAportacion, setCheckBoxAportacion] = useState(false);


	let history = useHistory();

	useEffect(() => {
		if (props.redirectAPublica) {
			props.onDidRedirect(true);
			history.push('/publica');
		}
	}, [props.redirectAPublica]);

	useEffect(() => {
		window.$("a.close").removeAttr("href").on('click', function () {
			function slideFade(elem) {
				var fadeOut = { opacity: 0, transition: 'opacity 0.5s' };
				elem.css(fadeOut).slideUp();
			}
			slideFade(window.$(this).parent());
		});
		window.$(".switch, .radio").each(function() {
			var intElem = window.$(this);
			intElem.on('click', function() {
				intElem.addClass('interactive-effect');
			   setTimeout(function() {
						intElem.removeClass('interactive-effect');
			   }, 400);
			});
		});
		window.$('.datos-enviados-dialog').magnificPopup({
			type: 'inline',

			fixedContentPos: false,
			fixedBgPos: true,

			overflowY: 'auto',

			closeBtnInside: true,
			preloader: false,

			midClick: true,
			removalDelay: 300,
			mainClass: 'my-mfp-zoom-in',
			closeMarkup: '<button title="%title%" type="button" className="mfp-close necesidad-publicada-close"></button>',
			callbacks: {
				close: function () {
					history.push('/necesidades');
				}
			}
		});
	}, []);

	function updateNombre(event) {
		let value = event.target.value;
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ nombre: value } } });
	}

	function updateOrganizacion(event) {
		let value = event.target.value;
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ organizacion: value } } });
	}

	function updateEmail(event) {
		let value = event.target.value;
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ email: value } } });
	}

	function updateTelefono(event) {
		let value = event.target.value;
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ telefono: value } } });
	}

	function updateDescripcion(event) {
		let value = event.target.value;
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ descripcion: value } } });
	}

	function updatePerteneceOrganizacion(event) {
		let value = event.target.checked;
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ perteneceOrganizacion: value } } });
	}

	function updateFormaApoyo(event) {
		let value = event.target.checked;
		console.log("value");
		console.log(event);
		// setCheckBoxAportacion(value);

		// setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ descripcion: value } } });
	}


	function enviarContacto() {
		document.getElementById('errores-validacion').removeAttribute('style');
		let mensajeError = validarDatos();
		if (mensajeError) {
			window.$('html, body').animate({ scrollTop: 0 }, 500);
			setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ erroresValidacion: mensajeError } } });
		} else {
			let categorias = [];
			let categoriasSeleccionadasElements = document.querySelectorAll('.checkboxes-categorias input[type=radio]:checked');
			// let formaSeleccionada=document.querySelector('.checkboxes-deseas input[type=radio]:checked').value;
			// console.log("formaSeleccionada");
			// console.log(formaSeleccionada);
			for (let i = 0; i < categoriasSeleccionadasElements.length; i++) {
				categorias.push(categoriasSeleccionadasElements[i].value);
			}
			let payloadData = JSON.stringify({
				"name": datosContacto.nombre,
				"email": datosContacto.email,
				"cel": datosContacto.telefono,
				"organizationName": datosContacto.organizacion,
				"categories": categorias,
				"description": datosContacto.descripcion,
				"money": checkBoxAportacion,
				"formaApoyo":"proveedor"
			});

			setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ loadingResponse: true } } });

			window.$.ajax({
				type: 'POST', url: cicApiUrl + 'api/OpenContacts/create', data: payloadData, success: (data, status) => {
					setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ loadingResponse: false } } });
					if (status === 'success' && data && data.status === 'OK') {
						window.$('.datos-enviados-dialog').magnificPopup('open');
					} else {
						setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ errorRespuesta: true } } });
						window.$('.datos-enviados-dialog').magnificPopup('open');
					}
				}, contentType: 'application/json'
			});
		}
	}

	function validarDatos() {
		let mensajeError = '';
		if (!datosContacto.nombre && !datosContacto.organizacion) {
			mensajeError = 'Por favor proporcione su nombre, o de su organización.';
		}
		if (!datosContacto.email && !datosContacto.telefono) {
			if (mensajeError) {
				mensajeError += ' ';
			}
			mensajeError += 'Por favor proporcione algún medio de contacto.';
		}
		return mensajeError;
	}
	return (
		<React.Fragment>
			{datosContacto.loadingResponse && <div style={{ position: 'fixed', top: 'calc(50vh - 100px)', left: 'calc(50vw - 100px)', zIndex: 999 }}>
				<Loader
					type="Grid"
					color="#2a41e8"
					height={200}
					width={200}
				/>
			</div>
			}
			<div id="small-dialog" className="zoom-anim-dialog mfp-hide dialog-with-tabs">

				<div className="sign-in-form">
				<div className="popup-tab-content" id="login">

						<div className="welcome-text necesidad-publicada-diag-content">
							{
								!datosContacto.errorRespuesta ? <><i className="icon-material-outline-check-circle"></i><h3>¡Gracias por ayudar!</h3><h3> Estaremos en contacto si surge alguna necesidad en la que puedas apoyar. </h3></>
									:
									<><h3>Hubo un error al enviar los datos.</h3> <h3>Por favor intenta de nuevo mas tarde, y si persiste el problema ponte en contacto con nosotros.</h3></>
							}
						</div>
					</div>
				</div>
			</div>
			



			 <div className="sign-in-form apoya-open-contact">
			
			

				<div className="popup-tabs-container">

					<div className="popup-tab-content row seccion-formulario">
					
						

					<div className="single-page-section">
					<CarruselProveedores2></CarruselProveedores2>	
							<p>
							Mediante la red de proveedores aliados enlazamos necesidades.</p>
<h4>¿Cómo contribuye un proveedor aliado?</h4>
							<p>Mediante la adquisición de insumos que son requeridos en cada una de las necesidades solicitadas por la comunidad.</p>
                            <h4>¿Qué características tiene el proveedor aliado?</h4>
							<p>Es un organización de distribución de insumos alimentarios, de limpieza, medicinas así como equipo y/o accesorios médicos.  </p>
							<br></br>
							<h4>¡Súmate hoy!</h4>
							<div class="preguntas">
						
						{/* <h3 style={{ fontWeight: 600 }}>Selecciona un tipo de necesidad que desees apoyar</h3>
						
						<div className="checkboxes-categorias">
							{props.listaPorEspecie.map((categoria) => {
								return (
									<div key={categoria.id} className="radio">
										<input type="radio" value={categoria.id} id={categoria.id} name="tipoApoyo"/>
										<label htmlFor={categoria.id}><span className="radio-label"></span>{categoria.name}</label>
									</div>
								);
							})}
							
						</div>
						<br></br> */}

						


						{/* <h3 style={{ fontWeight: 600 }}>Indícanos de qué forma deseas apoyar</h3> */}
						
						{/* <div className="checkboxes-deseas" >
							<div className="radio">
								<input type="radio" id="brigadista" value="brigadista"  name="formaApoyo" />
								<label htmlFor="brigadista"><span className="radio-label"></span>Brigadista</label>
							</div>
							<div className="radio">
								<input type="radio" id="proveedor" value="proveedor"  name="formaApoyo" />
								<label htmlFor="proveedor"><span className="radio-label"></span>Proveedor</label>
							</div>
						</div> */}

						<div id="errores-validacion" className={'notification error closeable ' + (!datosContacto.erroresValidacion ? 'sin-errores' : '')}>
							<p>{datosContacto.erroresValidacion}</p>
							<a className="close" href="#"></a>
						</div>
						<br></br>
						<h3 style={{ fontWeight: 600 }}>Información de Contacto</h3>
<div className="infoContacto">

						<div className="input-with-icon-left">
							<i className="icon-material-outline-account-circle"></i>
							<input maxLength="200" onChange={updateNombre} type="text" className="input-text with-border" placeholder="Nombre" />
						</div>
						<div className="switches-list">
							<div className="switch-container">
								<label className="switch"><input type="checkbox" onChange={updatePerteneceOrganizacion} checked={datosContacto.perteneceOrganizacion} /><span className="switch-button"></span>
								 <span className="cic-switch-text">Perteneces a una organización?</span></label>
							</div>
						</div>
						{datosContacto.perteneceOrganizacion &&
							<div className="input-with-icon-left">
								<i className="icon-material-outline-account-balance"></i>
								<input maxLength="200" onChange={updateOrganizacion} className="input-text with-border" placeholder="Organización" />
							</div>
						}
						<div className="input-with-icon-left">
							<i className="icon-material-baseline-mail-outline"></i>
							<input maxLength="100" onChange={updateEmail} className="input-text with-border" placeholder="Email" />
						</div>

						<div className="input-with-icon-left">
							<i className="icon-feather-phone"></i>
							<input onChange={updateTelefono} maxLength="50" className="input-text with-border" placeholder="Teléfono/Celular" />
						</div>

						<textarea onChange={updateDescripcion} cols="30" rows="2" maxLength="1000" className="descripcion-necesidad with-border"
							placeholder="Breve descripción de como pudieras ayudar"></textarea>

						<div key="politica" className="checkbox">
							<input type="checkbox" value="politica" id="politica" />
							<label htmlFor="politica"><span className="checkbox-icon"></span>He leído y acepto la política de privacidad</label>
						</div>

						<a style={{ display: 'none' }} href="#small-dialog" className="datos-enviados-dialog"></a>
						{!datosContacto.loadingResponse &&
						<button onClick={() => { enviarContacto() }} className="button full-width button-sliding-icon boton-enviar ripple-effect">Enviar <i className="icon-material-outline-arrow-right-alt"></i></button>
						}
						</div>
						</div>
						</div>
					</div>
					</div>

				</div> 

		</React.Fragment>
	);
}

export default RegistroProveedor;
