import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { cicApiUrl } from "../common/share-util";


import './RegistroCompletado.scss';

function RegistroCompletado(props) {

	useEffect(() => {
		if (window.location.search.indexOf('token=') > -1) {
			let urlStr = window.location.href;
			let url = new URL(urlStr);
			let token = url.searchParams.get('token');
			let payloadData = JSON.stringify({
				"token": token
			});
			window.$.ajax({
				type: 'POST', url: cicApiUrl + 'api/Users/validateaccount', data: payloadData, success: (data, status) => {
					console.log(data, status);
				}, contentType: 'application/json'
			});
		}
	}, []);

	return (
		<React.Fragment>
			<div className="registro-completado">
				<div className="welcome-text">
					<h3>Gracias por completar tu registro.</h3>
					<div>Ahora puedes entrar a tu cuenta con tu correo y contraseña.</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default RegistroCompletado;
