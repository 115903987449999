import React from "react";
import { Link } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { cicApiUrl } from "../common/share-util";
import "./ItemTestimonio.scss";
import Compartir from "../compartir/Compartir";

class ItemTestimonio extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }
  render() {
    return (
      <React.Fragment>
        <div className="item-testimonio-wrapper">
          <div className="job-listing">
            <div className="job-listing-details">
              {!this.props.testimonio.videoFlag && this.props.testimonio.picture && <div className="imagen-testimonio">
                <img src={this.props.testimonio.picture} alt=""></img>
              </div>
              }
              {this.props.testimonio.videoFlag && this.props.testimonio.video &&
                <iframe className="apoya-yt" width="100%" src={'https://www.youtube.com/embed/' + this.props.testimonio.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }
              <div className="job-listing-description descripcion-testimonio">
                <h3 className="job-listing-title">{this.props.testimonio.firstName.length < 60
                  ? this.props.testimonio.firstName : this.props.testimonio.firstName.substring(0, 60) + '...'
                }</h3>
                <span className="nombre-categoria">{this.props.testimonio.categoryName}</span>
              </div>
            </div>

            <div className="job-listing-footer">
              <div className="footer-container">
                <div className="footer-item">
                  <div>Municipio</div>
                  <div>
                    <i className="icon-material-outline-location-on"></i> <span>{this.props.testimonio.municipality}</span>
                  </div>
                </div>
                <div className="footer-item">
                  <div>Monto</div>
                  ${this.props.testimonio.estimatedCost || 0} pesos
                </div>
                <div className="footer-item">
                  <div>Fecha</div>
                  {new Date(this.props.testimonio.createdAt).toLocaleDateString("es-MX")}
                </div>
                <div className="footer-item">
                  <div>Folio</div>
                  {this.props.testimonio.ticket}
                </div>
{/*                 <div className="footer-item">
                  <Link to={'/necesidad/' + this.props.testimonio.needId} className="button button-sliding-icon ripple-effect">
                    Ver más
                     <i className="icon-material-outline-arrow-right-alt"></i></Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ItemTestimonio;
