import React from "react";
import { useHistory, Link, withRouter } from "react-router-dom";
import "./Footer.scss";

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id="footer">
          <div className="footer-bottom-section">
            {/* <div className="apoya-block">
            <h3>Mapa del Sitio</h3>
            
            <h3>	<Link to={'/apoya'}>
								Quiero Apoyar
									</Link></h3>
                  <h3>	<Link to={'/publica'}>
								Registra una Necesidad
									</Link></h3>
                  <h3>	<Link to={'/testimonios'}>
								Testimonios
									</Link></h3>
                  <h3>	<Link to={'/registro-brigadista'}>
							Inscríbete como Brigadista
									</Link></h3>
            
              {/* <a href="#"><img src="https://apoyacic.s3.amazonaws.com/imagenes/apoya_a.png" /></a> */}
            {/* </div> */}
            <div className="footer-links">
              <h3>Mapa del Sitio</h3>
              <ul>
                <div className="contacto-item">	<li><Link to={'/apoya'}><span>Quiero ApoYAr</span></Link></li></div>
                <div className="contacto-item">	 <li><Link to={'/publica'}><span>Registra una Necesidad</span></Link></li></div>
                <div className="contacto-item">	 <li><Link to={'/testimonios'}><span>Testimonios</span></Link></li></div>
                <div className="contacto-item">	 <li><Link to={'/registro-brigadista'}><span>Inscríbete como Brigadista</span></Link></li></div>
                <div className="contacto-item">	 <li><Link to={'/comofunciona'}><span>Sobre Nosotros</span></Link></li></div>
                <div className="contacto-item">	 <li><a target="_blank" href="https://medium.com/@apoyacic"><span>Blog</span></a></li></div>

              </ul>
            </div>
            <div className="contacto-block">
              <h3>Contacto</h3>
              <div className="contacto-item"><i className="icon-material-outline-location-on"></i>Calzada Del Valle Oriente #400, colonia Del Valle, San Pedro Garza García, Nuevo León, C.P. 66220 Moll del Valle, local 2.</div>
              <div className="contacto-item"><i className="icon-feather-phone"></i>818378 0000</div>
              <div className="contacto-item"><i className="icon-material-outline-email"></i>contacto@cic.mx</div>
            </div>
            {/* <div className="desarrollado-block">
              <h3>Desarrollado por</h3>
              <a target="_blank" href="https://civix.mx/"><img style={{ marginBottom: '15px' }} src="https://apoyacic.s3.amazonaws.com/imagenes/Civix_Logo_blanco.png" /></a>
            </div> */}
            <div className="iniciativa-block">
              <div>
                <h3 style={{ textAlign: 'center' }} className="subtitulo-principal">Una iniciativa de</h3>
                <div className="iniciativa-de">
                  <a href="https://www.cic.mx/" target="_blank">
                    <img style={{ maxWidth: '150px', marginRight: '20px' }} src="https://apoyacic.s3.amazonaws.com/imagenes/ciclogo_blanco.png"></img>
                  </a>
                  <a href="https://www.conl.mx/" target="_blank">
                    <img style={{ maxWidth: '150px' }} src="https://apoyacic.s3.amazonaws.com/imagenes/consejo_nl_blanco.png"></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="newsletter-block">
              <h3 style={{ textAlign: 'center' }} className="subtitulo-principal"><i className="icon-feather-mail"></i>Subscríbete a nuestro boletín</h3>
              <div>Mantente informado de nuevas necesidades y de como mejoramos nuestros esfuerzos para ApoYAr</div>
              <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css" />
              <div id="mc_embed_signup">
                <form action="https://cic.us6.list-manage.com/subscribe/post?u=f516ac50bd7e30751b03ab890&amp;id=ae2d8cc00a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                  <div id="mc_embed_signup_scroll">
                    <div>
                      <input type="email" defaultValue="" name="EMAIL" className="email cic-email-subscribe" id="mce-EMAIL" placeholder="Email" required />
                    </div>
                    {/*<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_f516ac50bd7e30751b03ab890_ae2d8cc00a" tabIndex="-1" defaultValue="" /></div>
                    <button title="Subscríbete" type="submit" readOnly name="subscribe" id="mc-embedded-subscribe" className="button cic-newsletter-subscribe-btn"><i className="icon-feather-arrow-right"></i></button>
                  </div>
                </form>
              </div>
            </div>
            <div className="aviso-block">
              <h3><a href="https://6bh.b65.myftpupload.com/aviso-de-privacidad/" target="_blank">Aviso de Privacidad</a></h3>
              <div>Copyright 2011-2020 © CIC – Centro de Información Ciudadana</div>
              <div>All Rights Reserved. Todos los derechos reservados.</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
