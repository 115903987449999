import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Toolbar from "./toolbar/Toolbar";
import Footer from "./footer/Footer";
import Apoya from "./apoya/Apoya";
import Publica from "./publica/Publica";
import RegistroBrigadista from "./brigadistas/RegistroBrigadista";
import RegistroProveedor from "./proveedores/RegistroProveedor";
import "./App.css";
import ListaNecesidades from "./necesidades/ListaNecesidades";
import Categorias from "./categorias/Categorias";
import Contactos from "./misnecesidades/Contactos";
import ContactosAbiertos from "./contactosabiertos/ContactosAbiertos";
import DetalleNecesidad from "./necesidades/DetalleNecesidad";

import './App.scss';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { cicApiUrl, generarMobileMegaMenu } from "./common/share-util";
import MisNecesidades from "./misnecesidades/MisNecesidades";
import ComoFunciona from "./comofunciona/ComoFunciona";
import QuienesSomos from "./quienessomos/QuienesSomos";
import Faq from "./faq/Faq";
import Beneficios from "./beneficios/Beneficios";
import Anuncio from "./anuncio/Anuncio";
import EstablecerContrasena from "./establecercontrasena/EstablecerContrasena";
import RegistroCompletado from "./registrocompletado/RegistroCompletado";
import TodosContactos from "./todoscontactos/TodosContactos";
import CheckoutDatos from "./checkout/CheckoutDatos";
import Checkout from "./checkout/Checkout";
import ListaTestimonios from "./testimonios/ListaTestimonios";
import Proveedores from "./proveedores/Proveedores";
import Brigadistas from "./brigadistas/Brigadistas";
import BolsaApoya from "./bolsa/BolsaApoya";
import TestimoniosAdmin from "./testimonios/admin/TestimoniosAdmin";
import Transparencia from "./testimonios/Transparencia";
import BolsaApoyaDolares from "./bolsa/BolsaApoyaDolares";
import Donaciones from "./donaciones/Donaciones";

class App extends React.Component {
  constructor(props) {
    super(props);
    let categoriasMatchPath = ["/", "/necesidades", "/index.html"];
    let firstLoadHome = categoriasMatchPath.includes(window.location.pathname);
    let loggedIn = false;
    let loginTemp = '';
    let loginTempEmail = '';
    /**
     * temp login token, despues de primera vez en sesion: publico sin cuenta, cuenta creada automaticamente -> link de correo para entrar a cuenta, pedir poner contraseña
     */
    if (sessionStorage.getItem('tl-apoya')) {
      loginTemp = sessionStorage.getItem('tl-apoya');
      loginTempEmail = sessionStorage.getItem('tl-email');
      loggedIn = true;
    }
    /**
     * login token: logeado en cuenta registrada normal
     */
    else if (sessionStorage.getItem('token-apoya')) {
      loggedIn = true;
    }
    /**
     * temp login token: publico sin cuenta. primera vez en sesion que entra con cuenta creada automaticamente
     */
    else if (window.location.search.indexOf('t=') > -1) {
      let urlStr = window.location.href;
      let url = new URL(urlStr);
      loginTemp = url.searchParams.get('t');
      loginTempEmail = url.searchParams.get('email');
      sessionStorage.setItem('tl-apoya', loginTemp);
      sessionStorage.setItem('tl-email', loginTempEmail || '');
    }
    let datosNecesidades = [];
    let modoDolares = false;
    if (window.location.search.indexOf('tickets=') > -1) {
      let tickets = window.location.search.slice(window.location.search.indexOf('tickets=') + 8);
      window.$.get(
        cicApiUrl + "api/Needs/getCheckoutData?tickets=" + tickets,
        (response, status) => {
          if (status === "success" && response && response.length > 0) {
            sessionStorage.setItem('datosNecesidades', JSON.stringify(response));
            this.setState({ necesidadesCheckout: response });
          } else {
            console.log("error al obtener datos necesidades. ", response, status);
          }
        });
    } else {
      let datosNecesidadesJson = sessionStorage.getItem('datosNecesidades');
      modoDolares = sessionStorage.getItem('modoDolares') || false;
      if (datosNecesidadesJson) {
        datosNecesidades = JSON.parse(datosNecesidadesJson);
      }
    }
    this.state = {
      listaPorEspecie: [],
      listaVoluntariado: [],
      isLoggedIn: loggedIn,
      redirectAPublica: false,
      loginTemp: loginTemp,
      loginTempEmail: loginTempEmail,
      iscic: false,
      necesidadesCheckout: datosNecesidades,
      paymentId: '',
      payerId: '',
      datosDonaciones: [],
      categoriasMatchPath: categoriasMatchPath,
      firstLoadHome: firstLoadHome,
      costoTotalEstimado: 0,
      totalNecesidades: 0,
      costoTotalCubierto: 0,
      totalNecesidadesCerradas: 0,
      necesidadesTicker: [],
      modoDolares: modoDolares
    };
  }

  handleLoginChange(isLoggedIn, shouldRedirect) {
    if (isLoggedIn) {
      this.setState({ isLoggedIn: isLoggedIn });
      if (shouldRedirect) {
        this.setState({ redirectAPublica: true });
      }
      if (sessionStorage.getItem('token-apoya')) {
        window.$.post(
          cicApiUrl + "api/Users/getoptsections", {
          token: sessionStorage.getItem('token-apoya')
        },
          (response, status) => {
            if (response.status === 'OK') {
              this.setState({
                iscic: true
              }, () => {
                generarMobileMegaMenu();
              });
            } else {
              generarMobileMegaMenu();
            }
          });
      }
    } else {
      this.setState({
        isLoggedIn: false,
        loginTemp: '',
        loginTempEmail: '',
        iscic: false
      }, () => {
        generarMobileMegaMenu();
      });
    }
  }

  handleDidRedirect(didRedirect) {
    if (didRedirect) {
      this.setState({ redirectAPublica: false });
    }
  }

  handleSeleccionarNecesidadCheckout(datosNecesidad) {
    let _this = this;
    let necesidadFound = this.state.necesidadesCheckout.find((necesidad) => {
      if (necesidad.ticket === datosNecesidad.ticket) {
        return true;
      }
    });
    if (!necesidadFound && datosNecesidad.costoEstimado) {
      this.setState(prevState => ({
        necesidadesCheckout: [...prevState.necesidadesCheckout, datosNecesidad]
      }), () => {
        let datosJson = JSON.stringify(_this.state.necesidadesCheckout);
        sessionStorage.setItem('datosNecesidades', datosJson);
        if (datosNecesidad.carrito) {
          window.Snackbar.show({
            text: 'Necesidad agregada a canasta',
            pos: 'bottom-center',
            showAction: false,
            actionText: "Dismiss",
            duration: 3000,
            textColor: '#fff',
            backgroundColor: '#383838'
          });
        }
      });
    } else if (necesidadFound && datosNecesidad.costoEstimado && (datosNecesidad.costoEstimado !== necesidadFound.costoEstimado)) {
      this.setState(prevState => {
        let copyNecesidades = [...prevState.necesidadesCheckout];
        let copyNecesidad = { ...necesidadFound };
        copyNecesidad.costoEstimado = datosNecesidad.costoEstimado;
        let indexNecesidad = copyNecesidades.findIndex((necesidad) => necesidad.ticket === datosNecesidad.ticket);
        if(indexNecesidad > -1){
          copyNecesidades[indexNecesidad] = copyNecesidad;
        }
        return {
          necesidadesCheckout: copyNecesidades
        }
      }, () => {
        let datosJson = JSON.stringify(_this.state.necesidadesCheckout);
        sessionStorage.setItem('datosNecesidades', datosJson);
        window.Snackbar.show({
          text: 'Monto de donación actualizado',
          pos: 'bottom-center',
          showAction: false,
          actionText: "Dismiss",
          duration: 3000,
          textColor: '#fff',
          backgroundColor: '#383838'
        });
      });
    } else {
      if (datosNecesidad.carrito && necesidadFound) {
        window.Snackbar.show({
          text: 'La necesidad ya está en la canasta',
          pos: 'bottom-center',
          showAction: false,
          actionText: "Dismiss",
          duration: 3000,
          textColor: '#fff',
          backgroundColor: '#383838'
        });
      }
      if (!datosNecesidad.costoEstimado) {
        window.Snackbar.show({
          text: 'La necesidad aún no tiene un costo estimado',
          pos: 'bottom-center',
          showAction: false,
          actionText: "Dismiss",
          duration: 3000,
          textColor: '#fff',
          backgroundColor: '#383838'
        });
      }
    }
  }

  handleSetModoDolares() {
    sessionStorage.setItem('modoDolares', true);
    this.setState({
      modoDolares: true
    });
  }

  handleQuitarNecesidadCheckout(datosNecesidad) {
    let _this = this;
    let necesidades = [...this.state.necesidadesCheckout];
    let indexNecesidad = necesidades.findIndex((necesidad) => {
      if (necesidad.ticket === datosNecesidad.ticket) {
        return true;
      }
    });
    if (indexNecesidad > -1) {
      necesidades.splice(indexNecesidad, 1);
      this.setState({ necesidadesCheckout: necesidades }, () => {
        let datosJson = JSON.stringify(_this.state.necesidadesCheckout);
        sessionStorage.setItem('datosNecesidades', datosJson);
      });
      if(necesidades.length === 0){
        this.setState({
          modoDolares: false
        });
        sessionStorage.removeItem('modoDolares');
      }
    }
  }

  handleSetPaymentId(paymentId){
    this.setState({
      paymentId: paymentId
    });
  }

  handleSetPayerId(payerId){
    this.setState({
      payerId: payerId
    });
  }

  handleSetDatosDonaciones(datosDonaciones){
    this.setState({
      datosDonaciones: datosDonaciones
    });
  }

  handlePaymentDone() {
    sessionStorage.removeItem('datosNecesidades');
    sessionStorage.removeItem('modoDolares');
    this.setState({
      necesidadesCheckout: [],
      paymentId: '',
      payerId: '',
      modoDolares: false
    });
  }

  handleEstablecioContrasena(establecioContrasena) {
    if (establecioContrasena) {
      sessionStorage.setItem('tl-apoya', '');
      sessionStorage.setItem('tl-email', '');
      this.setState({ loginTemp: '', loginTempEmail: '' });
    }
  }

  handleLeaveHome() {
    this.setState({
      firstLoadHome: false
    });
  }

  componentDidMount() {
    window.$.get(
      cicApiUrl + "api/Categories/getCategories",
      (response, status) => {
        if (status === "success") {
          if (response && response.length > 0) {
            console.log(response);
            let listaPorEspecie = [];
            let listaVoluntariado = [];
            let costoTotal = 0;
            let costoTotalCubierto = 0;
            let totalNecesidades = 0;
            let totalNecesidadesCerradas = 0;
            let enEspecie = response.find((value, i, obj) => {
              if (value._id === "En Especie") return true
              else return false;
            });
            let voluntariado = response.find((value, i, obj) => {
              if (value._id === "Voluntariado") return true
              else return false;
            });
            if (
              enEspecie &&
              enEspecie.categories &&
              enEspecie.categories.length > 0
            ) {
              for (let i = 0; i < enEspecie.categories.length; i++) {
                costoTotal += enEspecie.categories[i].categoryNeedsCost;
                costoTotalCubierto += enEspecie.categories[i].categoryClosedNeedsCost;
                totalNecesidadesCerradas += enEspecie.categories[i].beneficiariosTotal;
                totalNecesidades += enEspecie.categories[i].needsCount;
                listaPorEspecie.push(enEspecie.categories[i]);
              }
            }
            /*             if (
                          voluntariado &&
                          voluntariado.categories &&
                          voluntariado.categories.length > 0
                        ) {
                          for (let i = 0; i < voluntariado.categories.length; i++) {
                            costoTotal += voluntariado.categories[i].categoryNeedsCost;
                            costoTotalCubierto += voluntariado.categories[i].categoryClosedNeedsCost;
                            totalNecesidadesCerradas += voluntariado.categories[i].beneficiariosTotal;
                            totalNecesidades += voluntariado.categories[i].needsCount;
                            listaVoluntariado.push(voluntariado.categories[i]);
                          }
                        } */
            console.log(costoTotal);
            this.setState({
              listaPorEspecie: listaPorEspecie,
              listaVoluntariado: listaVoluntariado,
              costoTotalEstimado: costoTotal,
              totalNecesidades: totalNecesidades,
              costoTotalCubierto: costoTotalCubierto,
              totalNecesidadesCerradas: totalNecesidadesCerradas
            });
          }
        } else {
          console.log("error al obtener categorias. ", response, status);
        }
      }
    );
    window.$.get(cicApiUrl + 'api/Needs/getNeedsTicker', (data, status) => {
      if (status === 'success') {
        let necesidadesMapped = data.map((necesidad) => {
          if (necesidad.category === '5e90c3a229657d19444c88cf') {
            if (!necesidad.image) {
              necesidad.image = 'https://apoyacic.s3.amazonaws.com/imagenes/alimentos_despensas.jpg';
            }
          }
          if (necesidad.category === '5e904aae8ed980d4758250eb') {
            if (!necesidad.image) {
              necesidad.image = 'https://apoyacic.s3.amazonaws.com/imagenes/articulos_limpieza.jpg';
            }
          }
          if (necesidad.category === '5e904a298ed980d47582490f') {
            if (!necesidad.image) {
              necesidad.image = 'https://apoyacic.s3.amazonaws.com/imagenes/medicamentos_accesorios.jpg';
            }
          }
          if (necesidad.category === '5e8e0d7aecd79f0b79158608') {
            if (!necesidad.image) {
              necesidad.image = 'https://apoyacic.s3.amazonaws.com/imagenes/equipo_medico.jpg';
            }
          }
          return necesidad;
        });
        this.setState({
          necesidadesTicker: necesidadesMapped
        });
      } else {
        console.log('error al obtener necesidades ticker', status);
      }
    });
    if (sessionStorage.getItem('token-apoya')) {
      window.$.post(
        cicApiUrl + "api/Users/getoptsections", {
        token: sessionStorage.getItem('token-apoya')
      },
        (response, status) => {
          if (response.status === 'OK') {
            this.setState({
              iscic: true
            }, () => {
              generarMobileMegaMenu();
            });
          } else {
            generarMobileMegaMenu();
          }
        });
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Toolbar isLoggedIn={this.state.isLoggedIn} loginTemp={this.state.loginTemp} loginTempEmail={this.state.loginTempEmail}
          iscic={this.state.iscic} onLoginChange={this.handleLoginChange.bind(this)} necesidadesCheckout={this.state.necesidadesCheckout}></Toolbar>
        <div className="cic-content-container">
          <Anuncio isLoggedIn={this.state.isLoggedIn} loginTemp={this.state.loginTemp}></Anuncio>
          <Switch>
            <Route exact path={this.state.categoriasMatchPath}>
              <Categorias redirectAPublica={this.state.redirectAPublica} onDidRedirect={this.handleDidRedirect.bind(this)} listaPorEspecie={this.state.listaPorEspecie}
                listaVoluntariado={this.state.listaVoluntariado} firstLoadHome={this.state.firstLoadHome} onLeaveHome={this.handleLeaveHome.bind(this)}
                costoTotalEstimado={this.state.costoTotalEstimado} totalNecesidades={this.state.totalNecesidades} totalNecesidadesCerradas={this.state.totalNecesidadesCerradas}
                costoTotalCubierto={this.state.costoTotalCubierto} necesidadesTicker={this.state.necesidadesTicker} onSeleccionarNecesidadCheckout={this.handleSeleccionarNecesidadCheckout.bind(this)}></Categorias>
            </Route>
            <Route path="/necesidades/:categoriaId">
              <ListaNecesidades iscic={this.state.iscic} onSeleccionarNecesidadCheckout={this.handleSeleccionarNecesidadCheckout.bind(this)}
                redirectAPublica={this.state.redirectAPublica} onDidRedirect={this.handleDidRedirect.bind(this)}
                listaPorEspecie={this.state.listaPorEspecie}></ListaNecesidades>
            </Route>
            <Route path="/testimonios">
              <ListaTestimonios></ListaTestimonios>
            </Route>
            <Route path="/publica">
              <Publica isLoggedIn={this.state.isLoggedIn} listaPorEspecie={this.state.listaPorEspecie} listaVoluntariado={this.state.listaVoluntariado}></Publica>
            </Route>
            <Route path="/registro-brigadista">
              <RegistroBrigadista isLoggedIn={this.state.isLoggedIn} listaPorEspecie={this.state.listaPorEspecie} listaVoluntariado={this.state.listaVoluntariado}></RegistroBrigadista>
            </Route>
            <Route path="/registro-proveedor">
              <RegistroProveedor isLoggedIn={this.state.isLoggedIn} listaPorEspecie={this.state.listaPorEspecie} listaVoluntariado={this.state.listaVoluntariado}></RegistroProveedor>
            </Route>
            <Route path="/apoya">
              <Apoya redirectAPublica={this.state.redirectAPublica} onDidRedirect={this.handleDidRedirect.bind(this)} listaPorEspecie={this.state.listaPorEspecie} listaVoluntariado={this.state.listaVoluntariado}></Apoya>
            </Route>
            <Route path="/necesidad/:necesidadId">
              <DetalleNecesidad redirectAPublica={this.state.redirectAPublica} onDidRedirect={this.handleDidRedirect.bind(this)}
                onSeleccionarNecesidadCheckout={this.handleSeleccionarNecesidadCheckout.bind(this)}></DetalleNecesidad>
            </Route>
            <Route path="/comofunciona">
              <ComoFunciona redirectAPublica={this.state.redirectAPublica} onDidRedirect={this.handleDidRedirect.bind(this)}></ComoFunciona>
            </Route>
            <Route path="/quienessomos">
              <QuienesSomos redirectAPublica={this.state.redirectAPublica} onDidRedirect={this.handleDidRedirect.bind(this)}></QuienesSomos>
            </Route>
            <Route path="/beneficios">
              <Beneficios redirectAPublica={this.state.redirectAPublica} onDidRedirect={this.handleDidRedirect.bind(this)}></Beneficios>
            </Route>
            <Route path={["/misnecesidades"]}>
              <MisNecesidades iscic={this.state.iscic} isLoggedIn={this.state.isLoggedIn}></MisNecesidades>
            </Route>
            <Route path="/contactos/:necesidadId">
              <Contactos isLoggedIn={this.state.isLoggedIn}></Contactos>
            </Route>
            <Route path="/contactosabiertos">
              <ContactosAbiertos isLoggedIn={this.state.isLoggedIn}></ContactosAbiertos>
            </Route>
            <Route path="/todoscontactos">
              <TodosContactos isLoggedIn={this.state.isLoggedIn}></TodosContactos>
            </Route>
            <Route path="/establecerc">
              <EstablecerContrasena onEstablecioContrasenaChange={this.handleEstablecioContrasena.bind(this)} loginTemp={this.state.loginTemp}
                loginTempEmail={this.state.loginTempEmail}></EstablecerContrasena>
            </Route>
            <Route path="/faq">
              <Faq isLoggedIn={this.state.isLoggedIn}></Faq>
            </Route>
            <Route path="/aliados">
              <Proveedores ></Proveedores>
            </Route>
            <Route path="/validateaccount">
              <RegistroCompletado></RegistroCompletado>
            </Route>
            <Route path={['/datoscheckout', '/dc']}>
              <CheckoutDatos onQuitarNecesidad={this.handleQuitarNecesidadCheckout.bind(this)}
                onSetPayerId={this.handleSetPayerId.bind(this)} onSetPaymentId={this.handleSetPaymentId.bind(this)} necesidadesCheckout={this.state.necesidadesCheckout}
                onSetDatosDonaciones={this.handleSetDatosDonaciones.bind(this)} modoDolares={this.state.modoDolares}></CheckoutDatos>
            </Route>
            <Route path="/checkout">
              <Checkout payerId={this.state.payerId} paymentId={this.state.paymentId} necesidadesCheckout={this.state.necesidadesCheckout}
                datosDonaciones={this.state.datosDonaciones} onPaymentDone={this.handlePaymentDone.bind(this)} modoDolares={this.state.modoDolares}
              ></Checkout>
            </Route>
            <Route path="/bolsa">
              <BolsaApoya onSeleccionarNecesidadCheckout={this.handleSeleccionarNecesidadCheckout.bind(this)}></BolsaApoya>
            </Route>
            <Route path="/pool">
              <BolsaApoyaDolares onSetModoDolares={this.handleSetModoDolares.bind(this)} onSeleccionarNecesidadCheckout={this.handleSeleccionarNecesidadCheckout.bind(this)}></BolsaApoyaDolares>
            </Route>
            <Route path="/admin-testimonios">
              <TestimoniosAdmin isLoggedIn={this.state.isLoggedIn}></TestimoniosAdmin>
            </Route>
            <Route path="/admin-donaciones">
              <Donaciones isLoggedIn={this.state.isLoggedIn}></Donaciones>
            </Route>
            <Route path="/transparencia">
              <Transparencia></Transparencia>
            </Route>
          </Switch>
        </div>
        <Footer></Footer>
      </BrowserRouter>);
  }
}
export default App;
