import React, { useEffect, useState } from "react";
import CarruselProveedores from "../categorias/CarruselProveedores";

import './Proveedores.scss';

function Proveedores(props) {
	return (
		<React.Fragment>
			<CarruselProveedores></CarruselProveedores>
		</React.Fragment>
	);
}

export default Proveedores;
