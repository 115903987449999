import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import './Beneficios.scss';

function Beneficios(props) {

	let history = useHistory();

	useEffect(() => {
		if (props.redirectAPublica) {
			props.onDidRedirect(true);
			history.push('/publica');
		}
	}, [props.redirectAPublica]);


	return (
		<React.Fragment>
			<h1 className="beneficios-titulo">Beneficios</h1>

			<div className="beneficios-content">
				<p>Gracias a los siguientes beneficios de nuestra plataforma podemos articular a la sociedad civil:
				</p>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/1_tecnologias_redes.png"></img>Uso de la tecnología y redes sociales para vincular las necesidades de manera proactiva y efectiva.</div>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/civix2.png"></img>A través de ApoYA mapeamos las necesidades registradas, lo que nos permite conocer hacer un análisis de las zonas más vulnerables.</div>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/2_definicion.png"></img>Facilitamos la definición correcta de las necesidades. Por ejemplo determinando la cantidad, alcance, temporalidad, ubicación, y recurrencia.
				</div>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/3_evitamos.png"></img>Evitamos la duplicidad de necesidades e identificamos áreas de oportunidad donde no se ha recibido apoyo.</div>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/4_validacion_autenticidad.png"></img>Validación de la autenticidad de quienes publican las necesidades.</div>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/5_donar.png"></img>Nos enfocamos en donativos en especie y voluntariado, a diferencia de otras plataformas que solo reciben donativos monetarios.</div>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/6_orientamos.png"></img>Orientamos en el qué y cómo apoyar. Mantenemos las métricas de impacto social en base a lugar, categoría, receptor y donataria.</div>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/7_curriculum_social.png"></img>Creamos un currículum social para ciudadanos, iniciativa privada, y organizaciones de la sociedad civil.</div>
				<div className="paso-como-funciona"><img src="https://apoyacic.s3.amazonaws.com/imagenes/8_sumamos.png"></img>Sumamos voluntades hasta llegar a la meta de la necesidad.</div>
			</div>
		</React.Fragment>
	);
}

export default Beneficios;
