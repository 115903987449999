import React from "react";
import { Link } from "react-router-dom";

import './Contacto.scss';

function Contacto(props) {
	return (
		<React.Fragment>
			<li className="contacto">
				<div className="job-listing">
					<div className="job-listing-details">
						<div className="job-listing-description">
							<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
								{props.contacto.organizationName && <div className="job-listing-title"><span className="label-contacto">Organización: </span>{props.contacto.organizationName}</div>}
								{props.contacto.name && <div className="job-listing-title"><span className="label-contacto">Nombre: </span>{props.contacto.name}</div>}
								{props.contacto.email && <div className="job-listing-title"><span className="label-contacto">Email: </span>{props.contacto.email}</div>}
								{props.contacto.cel && <div className="job-listing-title"><span className="label-contacto">Tel/Celular: </span>{props.contacto.cel}</div>}
								<div className="job-listing-title"><span className="label-contacto">Cantidad: </span>{props.contacto.quantity}</div>
							</div>
							<div className="job-listing-footer">
								<ul>
									<li><i className="icon-material-outline-date-range"></i>
										<span className="job-listing-title" >Fecha: </span>
										<span className="job-listing-title" style={{fontSize: '16px'}}>{new Date(props.contacto.createdAt).toLocaleDateString("es-MX")}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</li>
		</React.Fragment>
	);
}

export default Contacto;
