import React from "react";
import { Link } from "react-router-dom";
import "./CarruselProveedores2.scss";

class CarruselProveedores2 extends React.Component {

  componentDidMount() {
    window.$('.default-slick-carousel-proveedores').slick({
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1292,
          settings: {
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false
          }
        }
      ]
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="section carrusel-proveedores padding-top-65 full-width-carousel-fix">
          <div style={{ textAlign: 'center' }}>
            <div className="brigadistas-titulo margin-top-0 margin-bottom-25">
              <h3>¡Únete como Aliado de ApoYA!</h3>
            </div>
          </div>

          <div style={{ margin: 'auto' }}>
            <div className="default-slick-carousel default-slick-carousel-proveedores freelancers-container freelancers-grid-layout">

              <div className="freelancer">
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    <div className="freelancer-avatar">
                      <a target="_blank" href="https://vitau.mx/">
                        <img src="https://apoyacic.s3.amazonaws.com/imagenes/vitau_logo_2828.png" alt="" />
                      </a>
                    </div>
                    <div className="freelancer-name">
                      <h4>Vitau</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="freelancer">
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    <div className="freelancer-avatar">
                      <a target="_blank" href="https://www.facebook.com/SuperInvernadero/">
                        <img src="https://apoyacic.s3.amazonaws.com/imagenes/sopas_proveedor.jpeg" alt="" />
                      </a>
                    </div>
                    <div className="freelancer-name">
                      <h4>Super Invernadero</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CarruselProveedores2;
