import React, { useEffect } from "react";
import { useHistory, Link, withRouter } from "react-router-dom";

import './Anuncio.scss';

function Anuncio(props) {
	return (
		<React.Fragment>
			{(props.location.pathname !== '/establecerc' && props.loginTemp && props.isLoggedIn) &&
				<Link to="/establecerc" className="anuncio-apoya">Has click aquí para establecer una contraseña y no perder acceso a tu cuenta.</Link>
			}
		</React.Fragment>
	);
}

export default withRouter(Anuncio);
