import React from "react";
import { Link } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { cicApiUrl } from "../common/share-util";
import "./ItemNecesidad.scss";
import Compartir from "../compartir/Compartir";

class ItemNecesidad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      porcentaje: 0,
      editandoCosto: false,
      costoEstimado: 0,
      costoEstimadoPreviousValue: 0
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        porcentaje: this.props.necesidad.porc,
        costoEstimado: this.props.necesidad.estimatedCost || 0,
        costoEstimadoPreviousValue: this.props.necesidad.estimatedCost || 0
      });
    }, 0);
  }
  activarCampoCostoEstimado() {
    this.setState({
      editandoCosto: true
    });
  }
  openApoyaPopup() {
    this.props.onSelectNecesidadId(this.props.necesidad._id);
  }
  desactivarCampoCostoEstimado() {
    this.setState({
      editandoCosto: false,
      costoEstimado: this.state.costoEstimadoPreviousValue
    });
  }
  actualizarCostoEstimado(event) {
    this.setState({
      costoEstimado: event.target.value
    });
  }
  checkoutNecesidad() {
    this.props.onSeleccionarNecesidadCheckout({
      ticket: this.props.necesidad.ticket,
      categoryName: this.props.necesidad.categoryInfo.name,
      costoEstimado: this.props.necesidad.estimatedCost
    });
    window.fbq('track', 'AddToCart');
  }
  agregarACarrito() {
    this.props.onSeleccionarNecesidadCheckout({
      ticket: this.props.necesidad.ticket,
      categoryName: this.props.necesidad.categoryInfo.name,
      costoEstimado: this.props.necesidad.estimatedCost,
      carrito: true
    });
    window.fbq('track', 'AddToCart');
  }
  guardarCostoEstimado() {
    if (this.state.costoEstimado > 0) {
      let payloadData = JSON.stringify({
        token: sessionStorage.getItem('token-apoya'),
        needId: this.props.necesidad._id,
        estimatedCost: this.state.costoEstimado
      });
      window.$.ajax({
        type: 'POST', url: cicApiUrl + 'api/Needs/setEstimatedCost', data: payloadData, success: (data, status) => {
          if (status === 'success' && data && data.status === 'OK') {
            window.Snackbar.show({
              text: 'Necesidad actualizada.',
              pos: 'top-center',
              showAction: false,
              actionText: "Dismiss",
              duration: 3000,
              textColor: '#fff',
              backgroundColor: '#383838'
            });
            this.setState({
              editandoCosto: false,
              costoEstimadoPreviousValue: this.state.costoEstimado
            });
          } else {
            window.alert('Hubo un error al actualizar el costo estimado de la necesidad.');
          }
        }, contentType: 'application/json'
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="item-necesidad-wrapper">
          {!this.props.necesidad.featured &&
            <Link onClick={this.checkoutNecesidad.bind(this)} className="boton-paypal button big" to="/datoscheckout">Donar con tarjeta</Link>
          }
          <Compartir necesidadId={this.props.necesidad._id}></Compartir>
          <button onClick={this.openApoyaPopup.bind(this)} className="apoya-button"> Apoya en especie</button>
          {!this.props.necesidad.featured &&
            <button onClick={this.agregarACarrito.bind(this)} className="boton-carrito">
              <img src="https://apoyacic.s3.amazonaws.com/imagenes/Recurso_5Manitas blanco.png"></img></button>
          }
          <Link className="job-listing" to={"/necesidad/" + this.props.necesidad._id}>
            <div className="job-listing-details">
              {this.props.necesidad.image &&
                <div className="imagen-necesidad">
                  <h3 className="job-listing-title">{this.props.necesidad.title.length < 60
                    ? this.props.necesidad.title : this.props.necesidad.title.substring(0, 60) + '...'
                  }</h3>
                  <img src={this.props.necesidad.image} alt=""></img>
                </div>
              }
              <div className="job-listing-description descripcion-necesidad">
                {!this.props.necesidad.image && <h3 className="job-listing-title">{this.props.necesidad.title.length < 60
                  ? this.props.necesidad.title : this.props.necesidad.title.substring(0, 60) + '...'
                }</h3>
                }
                {
                  this.props.necesidad.requirements &&
                  <>
                    <div className="requisitos-label">Especificaciones: </div>
                    <div className="requisitos-necesidad">
                      {this.props.necesidad.requirements.length < 60 ?
                        this.props.necesidad.requirements : this.props.necesidad.requirements.substring(0, 60) + '...'
                      }
                    </div>
                  </>
                }
                <span className="costo-estimado-label">Costo estimado:&nbsp;</span>
                <span className="costo-estimado" >${this.props.necesidad.estimatedCost || 0} pesos</span>
              </div>
            </div>

            <div className="job-listing-footer">
              <div className="footer-container">
                <div className="footer-item" style={{ color: '#777' }}>
                  <i className="icon-material-outline-location-on"></i> <span>{this.props.necesidad.municipality + ', ' + this.props.necesidad.state}</span>
                </div>
                <div title="Fecha requerida" data-tippy-placement="top" className="footer-item" style={{ color: '#777' }}>
                  {new Date(this.props.necesidad.createdAt).toLocaleDateString("es-MX")}
                </div>
              </div>
            </div>
          </Link>
          {/*           {this.props.iscic &&
            <div className="footer-item editar-costo-estimado">
              {!this.state.editandoCosto &&
                <div onClick={this.activarCampoCostoEstimado.bind(this)} >
                  Costo Estimado: ${this.state.costoEstimado}
                  <i className="icon-line-awesome-pencil"></i>
                </div>
              }
              {
                this.state.editandoCosto && <>
                  <input autoFocus style={{ margin: 0 }} type="number" onChange={this.actualizarCostoEstimado.bind(this)} value={this.state.costoEstimado}></input>
                  <button onClick={this.guardarCostoEstimado.bind(this)} className="margin-right-40">Guardar</button>
                  <button onClick={this.desactivarCampoCostoEstimado.bind(this)}>Cancelar</button>
                </>
              }
            </div>
          } */}
        </div>
      </React.Fragment>
    );
  }
}

export default ItemNecesidad;
