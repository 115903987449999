import React from "react";
import { Link } from "react-router-dom";
import "./CarruselBrigadistas.scss";

class CarruselBrigadistas extends React.Component {

  componentDidMount() {
    window.$('.default-slick-carousel-brigadistas').slick({
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1292,
          settings: {
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false
          }
        }
      ]
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="section carrusel-brigadistas padding-top-45 full-width-carousel-fix">
              <div style={{ textAlign: 'center' }}>
                <div className="brigadistas-titulo margin-top-0 margin-bottom-25">
                  <h3 >Nuestros Brigadistas</h3>
                </div>
              </div>

              <div style={{ margin: 'auto' }}>
                <div className="default-slick-carousel default-slick-carousel-brigadistas freelancers-container freelancers-grid-layout">

                  <div className="freelancer">
                    <div className="freelancer-overview">
                      <div className="freelancer-overview-inner">
                        <div className="freelancer-avatar">
                          <img src="https://apoyacic.s3.amazonaws.com/imagenes/brigadista3.png" alt="" />
                        </div>
                        <div className="freelancer-name">
                          <h4>Adrián Cardeña</h4>
                          <div className="nombre-org">Centro de Integración Ciudadana (CIC)</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="freelancer">
                    <div className="freelancer-overview">
                      <div className="freelancer-overview-inner">
                        <div className="freelancer-avatar">
                          <img src="https://apoyacic.s3.amazonaws.com/imagenes/brigadista1.png" alt="" />
                        </div>
                        <div className="freelancer-name">
                          <h4>César Garza</h4>
                          <div>Centro de Integración Ciudadana (CIC)</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="freelancer">
                    <div className="freelancer-overview">
                      <div className="freelancer-overview-inner">
                        <div className="freelancer-avatar">
                          <img src="https://apoyacic.s3.amazonaws.com/imagenes/brigadista2.png" alt="" />
                        </div>
                        <div className="freelancer-name">
                          <h4>Minerva Vargas</h4>
                          <div>Centro de Integración Ciudadana (CIC)</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Link to="/registro-brigadista" className="freelancer faltas-tu-btn">
                    <div className="freelancer-overview">
                      <div className="freelancer-overview-inner">
                        <div className="freelancer-avatar">
                          <img src="https://apoyacic.s3.amazonaws.com/imagenes/faltastu-brigadista.jpg" alt="" />
                        </div>
                        <div className="freelancer-name">
                          <h4>¡Faltas tú!</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            <div>
              
              <Link to="/registro-brigadista" className="col-xl-12 brigadistas-inscribete">Inscríbete como Brigadista <i className="icon-material-outline-arrow-right-alt"></i></Link>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CarruselBrigadistas;
