import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { cicApiUrl, isInt } from "../common/share-util";

import './MiNecesidadItem.scss';

function MiNecesidad(props) {
	const [editandoCosto, setEditandoCosto] = useState(false);
	const [costoEstimado, setCostoEstimado] = useState(0);
	const [costoEstimadoPreviousValue, setCostoEstimadoPreviousValue] = useState(0);

	const [editandoCantidadRecibida, setEditandoCantidadRecibida] = useState(false);
	const [cantidadRecibida, setCantidadRecibida] = useState(0);
	const [cantidadRecibidaPreviousValue, setCantidadRecibidaPreviousValue] = useState(0);

	const [editandoBeneficiarios, setEditandoBeneficiarios] = useState(false);
	const [beneficiarios, setBeneficiarios] = useState(0);
	const [beneficiariosPreviousValue, setBeneficiariosPreviousValue] = useState(0);

	const [editandoTitulo, setEditandoTitulo] = useState(false);
	const [titulo, setTitulo] = useState('');
	const [tituloPreviousValue, setTituloPreviousValue] = useState('');

	const [editandoDescripcion, setEditandoDescripcion] = useState(false);
	const [descripcion, setDescripcion] = useState('');
	const [descripcionPreviousValue, setDescripcionPreviousValue] = useState('');

	useEffect(() => {
		setCostoEstimado(props.necesidad.estimatedCost || 0);
		setCostoEstimadoPreviousValue(props.necesidad.estimatedCost || 0);
		setCantidadRecibida(props.necesidad.receivedCash || 0);
		setCantidadRecibidaPreviousValue(props.necesidad.receivedCash || 0);
		setBeneficiarios(props.necesidad.beneficiarios || 0);
		setBeneficiariosPreviousValue(props.necesidad.beneficiarios || 0);
		setTitulo(props.necesidad.title);
		setTituloPreviousValue(props.necesidad.title);
		setDescripcion(props.necesidad.description);
		setDescripcionPreviousValue(props.necesidad.description);
	}, []);

	useEffect(() => {
		if(editandoDescripcion){
			let element = document.getElementById('editando-descripcion');
			if(element){
				element.focus();
				if(element.value){
					element.setSelectionRange(element.value.length, element.value.length);
					element.scrollTop = element.scrollHeight;
				}
			}
		}
	}, [editandoDescripcion]);

	function guardarCampo(campo, campoName, endpoint, callback) {
		let payloadData = JSON.stringify({
			token: sessionStorage.getItem('token-apoya'),
			needId: props.necesidad._id,
			[campoName]: campo
		});
		window.$.ajax({
			type: 'POST', url: cicApiUrl + endpoint, data: payloadData, success: (data, status) => {
				if (status === 'success' && data && data.status === 'OK') {
					window.Snackbar.show({
						text: 'Necesidad actualizada.',
						pos: 'top-center',
						showAction: false,
						actionText: "Dismiss",
						duration: 3000,
						textColor: '#fff',
						backgroundColor: '#383838'
					});
					callback(true);
				} else {
					callback(false);
				}
			}, contentType: 'application/json'
		});
	}

	function activarCampoCostoEstimado() {
		setEditandoCosto(true);
	}

	function actualizarCostoEstimado(event) {
		setCostoEstimado(event.target.value);
	}

	function desactivarCampoCostoEstimado() {
		setEditandoCosto(false);
		setCostoEstimado(costoEstimadoPreviousValue);
	}

	function guardarCostoEstimado() {
		if (costoEstimado > 0) {
			guardarCampo(costoEstimado, 'estimatedCost', 'api/Needs/setEstimatedCost', (success) => {
				if (success) {
					setEditandoCosto(false);
					setCostoEstimadoPreviousValue(costoEstimado);
				} else {
					window.alert('Hubo un error al actualizar el costo estimado de la necesidad.');
				}
			});
		}
	}

	function activarCampoCantidadRecibida() {
		setEditandoCantidadRecibida(true);
	}

	function actualizarCantidadRecibida(event) {
		setCantidadRecibida(event.target.value);
	}

	function desactivarCampoCantidadRecibida() {
		setEditandoCantidadRecibida(false);
		setCantidadRecibida(cantidadRecibidaPreviousValue);
	}

	function guardarCantidadRecibida() {
		if (cantidadRecibida > 0) {
			guardarCampo(cantidadRecibida, 'receivedCash', 'api/Needs/setReceivedCash', (success) => {
				if (success) {
					setEditandoCantidadRecibida(false);
					setCantidadRecibidaPreviousValue(cantidadRecibida);
				} else {
					window.alert('Hubo un error al actualizar la cantidad recibida de la necesidad.');
				}
			});
		}
	}

	function activarBeneficiarios() {
		setEditandoBeneficiarios(true);
	}

	function actualizarBeneficiarios(event) {
		setBeneficiarios(event.target.value);
	}

	function desactivarBeneficiarios() {
		setEditandoBeneficiarios(false);
		setBeneficiarios(beneficiariosPreviousValue);
	}

	function guardarBeneficiarios() {
		if (beneficiarios > 0 && isInt(beneficiarios)) {
			guardarCampo(beneficiarios, 'beneficiarios', 'api/Needs/setBeneficiarios', (success) => {
				if (success) {
					setEditandoBeneficiarios(false);
					setBeneficiariosPreviousValue(beneficiarios);
				} else {
					window.alert('Hubo un error al actualizar los beneficiarios de la necesidad.');
				}
			});
		}
	}

	function openTestimonioDialog() {
		props.onOpenTestimonioDialog(props.necesidad);
	}

	function activarEditandoTitulo() {
		setEditandoTitulo(true);
	}

	function actualizarTitulo(event) {
		setTitulo(event.target.value);
	}

	function desactivarEditandoTitulo() {
		setEditandoTitulo(false);
		setTitulo(tituloPreviousValue);
	}

	function guardarTitulo() {
		if (titulo) {
			guardarCampo(titulo, 'title', 'api/Needs/editarTitulo', (success) => {
				if (success) {
					setEditandoTitulo(false);
					setTituloPreviousValue(titulo);
				} else {
					window.alert('Hubo un error al actualizar el título de la necesidad.');
				}
			});
		}
	}

	function activarEditandoDescripcion() {
		setEditandoDescripcion(true);
	}

	function actualizarDescripcion(event) {
		setDescripcion(event.target.value);
	}

	function desactivarEditandoDescripcion() {
		setEditandoDescripcion(false);
		setDescripcion(descripcionPreviousValue);
	}

	function guardarDescripcion() {
		if (descripcion) {
			guardarCampo(descripcion, 'description', 'api/Needs/editarDescripcion', (success) => {
				if (success) {
					setEditandoDescripcion(false);
					setDescripcionPreviousValue(descripcion);
				} else {
					window.alert('Hubo un error al actualizar la descripción de la necesidad.');
				}
			});
		}
	}

	return (
		<React.Fragment>
			<li>
				<div className="job-listing">
					<div className="job-listing-details">
						<div className="job-listing-description">
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<div className="job-listing-title"><Link to={'/necesidad/' + props.necesidad._id}><span className="mi-necesidad-label">Folio: </span>{props.necesidad.ticket}</Link></div>
								{!editandoTitulo &&
									<div className="job-listing-title"><span className="mi-necesidad-label">Título: </span>
										{titulo}
										{props.iscic && <i onClick={activarEditandoTitulo} className="editar-titulo-btn icon-line-awesome-pencil"></i>}
									</div>
								}
								{editandoTitulo && <>
									<input className="campo-editando" autoFocus style={{ margin: 0 }} onChange={actualizarTitulo} value={titulo}></input>
									<div>
										<button onClick={() => { guardarTitulo() }}>Guardar</button>
										<button onClick={() => { desactivarEditandoTitulo() }}>Cancelar</button>
									</div>
								</>
								}
								{!editandoDescripcion &&
									<div className="job-listing-title"><span className="mi-necesidad-label">Descripción: </span>
										{descripcion}
										{props.iscic && <i onClick={activarEditandoDescripcion} className="editar-titulo-btn icon-line-awesome-pencil"></i>}
									</div>
								}
								{editandoDescripcion && <>
									<textarea id="editando-descripcion" className="campo-editando" style={{ margin: 0 }} onChange={actualizarDescripcion} value={descripcion}></textarea>
									<div>
										<button onClick={() => { guardarDescripcion() }}>Guardar</button>
										<button onClick={() => { desactivarEditandoDescripcion() }}>Cancelar</button>
									</div>
								</>
								}
							</div>
							<div className="job-listing-footer">
								<ul>
									<li><i className="icon-material-outline-date-range"></i>
										<span className="detalle-titulo-bold">Fecha creación: </span>
										<span>{new Date(props.necesidad.createdAt).toLocaleDateString("es-MX")}</span>
									</li>
								</ul>
								{props.iscic &&
									<ul className="cic-controls">
										<li>
											<span>Estatus: <span className={props.necesidad.status === 'en proceso' ? 'status-en-proceso' : props.necesidad.status === 'cerrado' ? 'status-cerrado' : 'status-abierto'}>{props.necesidad.status}</span></span>
										</li>
										<li>
											<span>Folio CIC: {props.necesidad.ticketCic}</span>
										</li>
										<li>
											{!editandoCosto &&
												<div className="costo-estimado-label" onClick={activarCampoCostoEstimado} >
													Costo Estimado: ${costoEstimado}
													<i className="icon-line-awesome-pencil"></i>
												</div>
											}
											{
												editandoCosto && <>
													<input autoFocus style={{ margin: 0 }} type="number" onChange={actualizarCostoEstimado} value={costoEstimado}></input>
													<button onClick={() => { guardarCostoEstimado() }} className="margin-right-40">Guardar</button>
													<button onClick={() => { desactivarCampoCostoEstimado() }}>Cancelar</button>
												</>
											}
										</li>
										<li>
											<div>Tipo donación: {props.necesidad.tipoDonacionRecibida}</div>
											{!editandoCantidadRecibida && props.necesidad.tipoDonacionRecibida === 'Efectivo' &&
												<div className="costo-estimado-label" onClick={activarCampoCantidadRecibida} >
													Recibido: ${cantidadRecibida}
													<i className="icon-line-awesome-pencil"></i>
												</div>
											}
											{
												editandoCantidadRecibida && props.necesidad.tipoDonacionRecibida === 'Efectivo' && <>
													<input autoFocus style={{ margin: 0 }} type="number" onChange={actualizarCantidadRecibida} value={cantidadRecibida}></input>
													<button onClick={() => { guardarCantidadRecibida() }} className="margin-right-40">Guardar</button>
													<button onClick={() => { desactivarCampoCantidadRecibida() }}>Cancelar</button>
												</>
											}
										</li>
										<li>
											{!editandoBeneficiarios &&
												<div className="costo-estimado-label" onClick={activarBeneficiarios} >
													Beneficiarios: {beneficiarios}
													<i className="icon-line-awesome-pencil"></i>
												</div>
											}
											{
												editandoBeneficiarios && <>
													<input autoFocus style={{ margin: 0 }} type="number" onChange={actualizarBeneficiarios} value={beneficiarios}></input>
													<button onClick={() => { guardarBeneficiarios() }} className="margin-right-40">Guardar</button>
													<button onClick={() => { desactivarBeneficiarios() }}>Cancelar</button>
												</>
											}
										</li>
										<li>
											<span>{props.necesidad.testimony ? 'Tiene testimonio' : (props.necesidad.status === 'cerrado' || props.necesidad.status === 'en proceso') ? <button onClick={openTestimonioDialog}>Crear testimonio</button> : ''}</span>
										</li>
									</ul>
								}
							</div>
						</div>
					</div>
				</div>

				{(props.necesidad.contactCount > 0) &&
					<div className="buttons-to-right always-visible">
						<Link to={'/contactos/' + props.necesidad._id} className="button ripple-effect">
							<i className="icon-material-outline-supervisor-account"></i> Ver contactos <span className="button-info">{props.necesidad.contactCount}</span>
						</Link>
					</div>
				}
			</li>
		</React.Fragment>
	);
}

export default MiNecesidad;
