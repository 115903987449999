import React, { useEffect, useState } from "react";
import './DonacionesTable.scss';
import $ from 'jquery';
import dt from 'datatables.net-dt';
import dtResponsive from 'datatables.net-responsive-dt';
import dtButtons from 'datatables.net-buttons-dt';
import dtButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
import DetalleDonacionDialog from "./DetalleDonacionDialog";
import { cicApiUrl } from "../common/share-util";

function DonacionesTable(props) {

    const [donacionDonacionDialog, setDonacionDonacionDialog] = useState({});

    useEffect(() => {
        let donaciones = props.data.map((value, i, arr) => {
            let donacion = [value._id, (value.name || '') + ' ' + (value.lastname || ''), value.email || '', value.items, value.paypalPaymentId || '', '$' + value.totalPrice, value.createdAt,
            value.newsletter || false, null];
            return donacion;
        });
        dt.$('#donaciones-table').DataTable({
            data: donaciones,
            columns: [
                {
                    visible: false
                },
                {
                    title: "Nombre"
                },
                {
                    title: "Email",
                },
                {
                    title: "Donaciones",
                    render: function (data, type, row) {
                        if (type === 'display') {
                            if (data && data.length > 1) {
                                return `<button data-donacion-id=${row[0]} class="boton-tabla-donaciones">Ver detalle</button>`
                            } else if (data && data.length === 1) {
                                return `<div>$${data[0].price}</div><div>${data[0].ticket}</div><div>${data[0].categoryName}</div><div>${data[0].message}</div>`;
                            } else {
                                return data;
                            }
                        } else {
                            if (data && data.length > 1) {
                               let donaciones = '';
                               for(let i = 0; i < data.length; i++){
                                   donaciones += ('$' + data[i].price + ', ' + data[i].ticket + ', ' + data[i].categoryName + ', ' + data[i].message) + '; ';
                               }
                               return donaciones;
                            } else if (data && data.length === 1) {
                                return '$' + data[0].price + ', ' + data[0].ticket + ', ' + data[0].categoryName + ', ' + data[0].message;
                            } else {
                                return data;
                            }
                        }
                    }
                },
                {
                    title: "PP ID Transacción"
                },
                {
                    title: "Total Donación"
                },
                {
                    title: "Fecha"
                },
                {
                    title: "Newsletter"
                },
                {
                    className: "control"
                }
            ],
            order: [[6, 'desc']],
            responsive: {
                details: {
                    type: 'column',
                    target: -1
                }
            },
            dom: 'lfrtip<"donaciones-clear">B',
            buttons: [{
                extend: 'csv',
                text: 'Descargar CSV',
                exportOptions: {
                    orthogonal: 'filter'
                }
            }],
            drawCallback: function () {
                window.$('#donaciones-table .boton-tabla-donaciones').off('click');
                window.$('#donaciones-table .boton-tabla-donaciones').on('click', function () {
                    openDonacionDialog(window.$(this).data('donacion-id'));
                });
            },
            pageLength: 25
        });
        window.$('.abrir-detalle-donacion-dialog').magnificPopup({
            type: 'inline',

            fixedContentPos: false,
            fixedBgPos: true,

            overflowY: 'auto',

            closeBtnInside: true,
            preloader: false,

            midClick: true,
            removalDelay: 300,
            mainClass: 'my-mfp-zoom-in',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close necesidad-publicada-close"></button>',
            callbacks: {
                close: function () {
                    setDonacionDonacionDialog({});
                }
            }
        });

        return () => { dt.$('#donaciones-table').DataTable().destroy() };
    }, [props.data]);

    function openDonacionDialog(donacionId) {
        let donacion = props.data.find((val) => val._id === donacionId);
        setDonacionDonacionDialog(donacion);
        window.$('.abrir-detalle-donacion-dialog').magnificPopup('open');
    }

    return (
        <div className="donaciones-wrapper">
            <a style={{ display: 'none' }} href="#small-dialog-2" className=" button ripple-effect big margin-top-30 abrir-detalle-donacion-dialog"></a>
            <DetalleDonacionDialog donacion={donacionDonacionDialog}></DetalleDonacionDialog>
            <h1 className="titulo-tabla-donaciones">Donaciones PayPal</h1>
            <table id="donaciones-table" className="display compact stripe" width="100%"></table>
        </div>
    )
}

export default DonacionesTable;
