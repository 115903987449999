import React, { useEffect, useState } from "react";
import { cicApiUrl, isInt } from "../common/share-util";

import './Ticker.scss';

function Ticker(props) {

	const [currentNecesidad, setCurrentNecesidad] = useState(0);

	const [timeoutId, setTimeoutId] = useState(null);

	const [transitioning, setTransitioning] = useState(false);

	useEffect(() => {
		let timeoutId = null;
		if (props.necesidades.length > 0) {
			timeoutId = window.setTimeout(() => {
				if (currentNecesidad > 4) {
					setTransitioning(true);
					timeoutId = window.setTimeout(() => {
						setCurrentNecesidad(0);
						setTransitioning(false);
					}, 1000);
				} else {
					setTransitioning(true);
					timeoutId = window.setTimeout(() => {
						let newNecesidad = currentNecesidad + 1;
						setCurrentNecesidad(newNecesidad);
						setTransitioning(false);
					}, 1000);
				}
			}, 5000);
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [currentNecesidad, props.necesidades]);

	return (
		<div className="ticker-apoya">
			<div className="ticker-frame">
				<div className="ticker-label"><span>NECESIDADES RECIENTES</span></div>
				{props.necesidades.length > 0 && <a href={'/necesidad/' + props.necesidades[currentNecesidad]._id} target="_blank" className="ticker-content">
						<div className={transitioning ? 'ticker-content-inner-transitioning' : 'ticker-content-inner'}>{props.necesidades[currentNecesidad].description}</div>
					</a>}
				<div className="ticker-ending"></div>
			</div>
		</div>
	);
}

export default Ticker;
