import React from "react";
import { Link, withRouter } from "react-router-dom";
import './Toolbar.scss';
import { cicApiUrl } from "../common/share-util";


class Toolbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      respuesta: false,
      menuAbierto: false,
      emailLogin: '',
      contrasenaLogin: '',
      nombreRegistro: '',
      celularRegistro: '',
      emailRegistro: '',
      direccionRegistro: '',
      organizacionRegistro: '',
      contrasenaRegistro: '',
      contrasena2Registro: '',
      erroresValidacionRegistro: '',
      erroresValidacionLogin: '',
      necesidadesEnCanasta: 0
    }
  }

  componentDidMount() {
    let _this = this;

    window.$("a.close").removeAttr("href").on('click', function () {
      function slideFade(elem) {
        var fadeOut = { opacity: 0, transition: 'opacity 0.5s' };
        elem.css(fadeOut).slideUp();
      }
      slideFade(window.$(this).parent());
    });

    window.$('.usuario-creado-dialog').magnificPopup({
      type: 'inline',

      fixedContentPos: false,
      fixedBgPos: true,

      overflowY: 'auto',

      closeBtnInside: true,
      preloader: false,

      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close usuario-creado-close"></button>',
      callbacks: {
        close: function () {
          console.log(_this.state);
          _this.setState({
            respuesta: false,
            emailLogin: '',
            contrasenaLogin: '',
            nombreRegistro: '',
            celularRegistro: '',
            emailRegistro: '',
            direccionRegistro: '',
            organizacionRegistro: '',
            contrasenaRegistro: '',
            contrasena2Registro: '',
            erroresValidacionRegistro: '',
            erroresValidacionLogin: ''
          });
        }
      }
    });
    if (this.props.loginTemp) {
      this.iniciarSesionTemp();
    }
    if (this.props.necesidadesCheckout) {
      this.setState({ necesidadesEnCanasta: this.props.necesidadesCheckout.length });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let _this = this;
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      window.$(".header-notifications").each(function () {
        var userMenu = window.$(this);
        var userMenuTrigger = window.$(this).find('.header-notifications-trigger a');

        window.$(userMenuTrigger).on('click', function (event) {
          event.preventDefault();

          if (_this.state.menuAbierto) {
            close_user_dropdown();
            _this.setState({ menuAbierto: false });
          } else {
            close_user_dropdown();
            userMenu.addClass('active');
            _this.setState({ menuAbierto: true });
          }
        });
      });
      var mouse_is_inside = false;

      window.$(".header-notifications").on("mouseenter", function () {
        mouse_is_inside = true;
      });
      window.$(".header-notifications").on("mouseleave", function () {
        mouse_is_inside = false;
      });
      function close_user_dropdown() {
        window.$('.header-notifications').removeClass("active");
      }
      window.$("body").mouseup(function () {
        if (!mouse_is_inside) {
          close_user_dropdown();
          _this.setState({ menuAbierto: false });
        }
      });

      window.$(document).keyup(function (e) {
        if (e.keyCode == 27) {
          close_user_dropdown();
          _this.setState({ menuAbierto: false });
        }
      });

      window.$(".header-notifications").on("mouseenter", function () {
        window.mouse_is_inside = true;
      });
      window.$(".header-notifications").on("mouseleave", function () {
        window.mouse_is_inside = false;
      });
    } else if (prevProps.isLoggedIn && !this.props.isLoggedIn) {
      window.$('.popup-with-zoom-anim').magnificPopup({
        type: 'inline',

        fixedContentPos: false,
        fixedBgPos: true,

        overflowY: 'auto',

        closeBtnInside: true,
        preloader: false,

        midClick: true,
        removalDelay: 300,
        mainClass: 'my-mfp-zoom-in'
      });
    }
    if (!prevProps.loginTemp && this.props.loginTemp) {
      this.iniciarSesionTemp();
    }
    if (this.props.necesidadesCheckout && (this.props.necesidadesCheckout.length !== prevProps.necesidadesCheckout.length)) {
      this.setState({ necesidadesEnCanasta: this.props.necesidadesCheckout.length });
    } else {
      if (!this.props.necesidadesCheckout && prevState.necesidadesEnCanasta !== 0) {
        this.setState({ necesidadesEnCanasta: 0 });
      }
    }
  }

  isCurrentSection(section) {
    if (this.props.location.pathname.indexOf(section) > -1) {
      return true;
    } else if (this.props.location.pathname.indexOf(section) > -1) {
      return true;
    } else if (this.props.location.pathname.indexOf(section) > -1) {
      return true;
    } else return false;
  }

  setEmailLogin(event) {
    this.setState({
      emailLogin: event.target.value
    });
  }
  setContrasenaLogin(event) {
    this.setState({
      contrasenaLogin: event.target.value
    });
  }

  setNombreRegistro(event) {
    this.setState({
      nombreRegistro: event.target.value
    });
  }
  setTelefonoRegistro(event) {
    let soloNumeros = /^[0-9]+$/;
    if ((soloNumeros.test(event.target.value) || !event.target.value) && event.target.value.length < 11) {
      this.setState({
        celularRegistro: event.target.value
      });
    }
  }
  setEmailRegistro(event) {
    this.setState({
      emailRegistro: event.target.value
    });
  }
  setDireccionRegistro(event) {
    this.setState({
      direccionRegistro: event.target.value
    });
  }
  setNombreOrganizacionRegistro(event) {
    this.setState({
      organizacionRegistro: event.target.value
    });
  }
  setContrasenaRegistro(event) {
    this.setState({
      contrasenaRegistro: event.target.value
    });
  }

  setContrasena2Registro(event) {
    this.setState({
      contrasena2Registro: event.target.value
    });
  }

  registrarUsuario() {
    let _this = this;

    document.getElementById('errores-validacion-registro').removeAttribute('style');
    let mensajesError = this.validarDatosRegistro();
    if (mensajesError) {
      this.setState({ erroresValidacionRegistro: mensajesError });
    } else {
      this.setState({ erroresValidacionRegistro: '' });
      let payloadData = JSON.stringify({
        "name": this.state.nombreRegistro,
        "password": this.state.contrasenaRegistro,
        "email": this.state.emailRegistro,
        "cel": this.state.celularRegistro,
        "address": this.state.direccionRegistro,
        "organizationName": this.state.organizacionRegistro
      });

      window.$.ajax({
        type: 'POST', url: cicApiUrl + 'api/Users/signup', data: payloadData, success: (data, status) => {
          if (status === 'success' && data && data.status === 'OK') {
            window.$('.log-in-button').magnificPopup('close');
            _this.setState({
              respuesta: true
            });
            setTimeout(() => {
              window.$('.usuario-creado-dialog').magnificPopup('open');
            }, 1000);
          } else {
            if (data && data.status === 'EXISTENTE') {
              _this.setState({
                erroresValidacionRegistro: 'Ya existe una cuenta con ese correo o teléfono.'
              });
            } else {
              window.$('.log-in-button').magnificPopup('close');
              _this.setState({
                respuesta: false
              });
              setTimeout(() => {
                window.$('.usuario-creado-dialog').magnificPopup('open');
              }, 1000);
            }
            console.log(data);
          }
        }, contentType: 'application/json'
      });
    }
  }

  validarDatosRegistro() {
    let mensajeError = '';
    if (!this.state.emailRegistro || !this.state.contrasenaRegistro || !this.state.contrasena2Registro) {
      mensajeError = 'Por favor llene todos los datos requeridos.';
    }
    if (!this.state.organizacionRegistro && !this.state.nombreRegistro) {
      if (mensajeError) {
        mensajeError += ' ';
      }
      mensajeError += 'Por favor proporcione nombre de organización o su nombre.';
    }
    if (this.state.contrasenaRegistro !== this.state.contrasena2Registro) {
      if (mensajeError) {
        mensajeError += ' ';
      }
      mensajeError += 'Las contraseñas no coinciden.';
    }
    return mensajeError;
  }

  iniciarSesionTemp() {
    if (!this.props.isLoggedIn) {
      let payloadData = JSON.stringify({
        "t": this.props.loginTemp,
        "email": this.props.loginTempEmail
      });
      window.$.ajax({
        type: 'POST', url: cicApiUrl + 'api/Users/login', data: payloadData, success: (data, status) => {
          if (status === 'success' && data && data.status === 'OK') {
            this.props.onLoginChange(true, false);
            sessionStorage.setItem('token-apoya', data.data.token);
            sessionStorage.setItem('usuario-org-nombre', data.data.organizationName);
            sessionStorage.setItem('usuario-email', data.data.email);
            window.Snackbar.show({
              text: 'Sesión iniciada',
              pos: 'bottom-center',
              showAction: false,
              actionText: "Dismiss",
              duration: 3000,
              textColor: '#fff',
              backgroundColor: '#383838'
            });
          } else {
            sessionStorage.setItem('token-apoya', '');
            sessionStorage.setItem('usuario-org-nombre', '');
            sessionStorage.setItem('usuario-email', '');
            sessionStorage.setItem('tl-apoya', '');
            sessionStorage.setItem('tl-email', '');
            window.location.replace('https://apoya.cic.mx/');
          }
        }, contentType: 'application/json'
      });
    }
  }

  iniciarSesion() {
    document.getElementById('errores-validacion-login').removeAttribute('style');
    this.setState({ erroresValidacionLogin: '' });
    if (this.state.emailLogin && this.state.contrasenaLogin) {
      let payloadData = JSON.stringify({
        "password": this.state.contrasenaLogin,
        "email": this.state.emailLogin
      });
      window.$.ajax({
        type: 'POST', url: cicApiUrl + 'api/Users/login', data: payloadData, success: (data, status) => {
          if (status === 'success' && data && data.status === 'OK') {
            this.setState({
              respuesta: false,
              emailLogin: '',
              contrasenaLogin: '',
              nombreRegistro: '',
              celularRegistro: '',
              emailRegistro: '',
              direccionRegistro: '',
              organizacionRegistro: '',
              contrasenaRegistro: '',
              contrasena2Registro: '',
              erroresValidacionRegistro: '',
              erroresValidacionLogin: ''
            });
            sessionStorage.setItem('token-apoya', data.data.token);
            sessionStorage.setItem('usuario-org-nombre', data.data.organizationName);
            sessionStorage.setItem('usuario-email', data.data.email);
            this.props.onLoginChange(true, true);
            window.$('.log-in-button').magnificPopup('close');
            window.Snackbar.show({
              text: 'Sesión iniciada',
              pos: 'bottom-center',
              showAction: false,
              actionText: "Dismiss",
              duration: 3000,
              textColor: '#fff',
              backgroundColor: '#383838'
            });
          } else {
            if (status === 'success' && data && data.status === 'WRONG') {
              this.setState({ erroresValidacionLogin: 'El correo o la contraseña son incorrectos.' });
            } else if (status === 'success' && data && data.status === 'NOTVALIDATED') {
              this.setState({ erroresValidacionLogin: 'Por favor verifique su cuenta usando el correo que le enviamos.' });
            } else {
              this.setState({ erroresValidacionLogin: 'Hubo un error al autenticar, por favor intente de nuevo mas tarde.' });
            }
          }
        }, contentType: 'application/json'
      });
    }
  }

  cerrarSesion(event) {
    event.preventDefault();
    this.props.onLoginChange(false);
    sessionStorage.setItem('token-apoya', '');
    sessionStorage.setItem('usuario-org-nombre', '');
    sessionStorage.setItem('usuario-email', '');
    sessionStorage.setItem('tl-apoya', '');
    sessionStorage.setItem('tl-email', '');
    window.Snackbar.show({
      text: 'Sesión cerrada',
      pos: 'bottom-center',
      showAction: false,
      actionText: "Dismiss",
      duration: 3000,
      textColor: '#fff',
      backgroundColor: '#383838'
    });
  }

  render() {
    return (
      <React.Fragment>

        <div id="small-dialog-3" className="zoom-anim-dialog mfp-hide dialog-with-tabs">

          <div className="sign-in-form">
            <div className="popup-tab-content" id="login">

              <div className="welcome-text necesidad-publicada-diag-content">
                {
                  this.state.respuesta ? <><i className="icon-material-outline-check-circle"></i><h3>Tu cuenta ha sido creada.</h3><h3>Por favor actívala usando tu correo.</h3></>
                    :
                    <h3>Hubo un error al crear tu cuenta</h3>
                }
              </div>
            </div>
          </div>
        </div>

        <header id="header-container" className="fullwidth cic-toolbar">
          <div id="header">
            <div className="container">
              <div className={'left-side ' + (this.props.isLoggedIn ? 'logged-in' : '')}>
                <div id="logo">
                  <a style={{ minWidth: 'none' }} href="/">
                    <img src="https://apoyacic.s3.amazonaws.com/imagenes/apoya2b_0.jpg" alt="logo_apoya"></img>
                  </a>
                </div>

                <nav id="navigation">
                  <ul id="responsive">
                    <li className="cic-sub-menu-apoya">
                      <a className={(this.isCurrentSection('/apoya') || this.isCurrentSection('/necesidad')
                      || this.isCurrentSection('/bolsa')) ? 'current' : ''}
                        href="#"><div>Quiero</div><div>ApoYAr</div></a>
                      <ul className="dropdown-nav">
                        <li>
                          <Link to="/apoya">
                            ApoYA
                              </Link>
                        </li>
                        <li>
                          <Link to="/bolsa">
                            Bolsa ApoYA
                              </Link>
                        </li>
                        <li>
                          <Link to="/necesidades/prioritarias">
                            Necesidades prioritarias
                              </Link>
                        </li>
                        <li>
                          <Link to="/necesidades/5e90c3a229657d19444c88cf">
                            Alimentos y artículos de primera necesidad
                              </Link>
                        </li>
                        <li>
                          <Link to="/necesidades/5e904a298ed980d47582490f">
                            Medicamento
                              </Link>
                        </li>
                      </ul>
                    </li>
                    {!this.props.iscic &&
                      <>
                        <li>
                          <Link to="/publica" className={this.isCurrentSection('/publica') ? 'current' : ''}>
                            <div>Registra</div><div>una Necesidad</div>
                          </Link>
                        </li>
                        <li className="cic-single-line-menu">
                          <Link to="/testimonios" className={this.isCurrentSection('/testimonios') ? 'current' : ''}>
                            Testimonios
                              </Link>
                        </li>
                        <li>
                          <Link to="/registro-brigadista" className={this.isCurrentSection('/registro-brigadista') ? 'current' : ''}>
                            <div>Inscríbete</div> <div>como Brigadista</div>
                          </Link>
                        </li>
                        {/*                         <li>
                          <Link to="/registro-proveedor" className={this.isCurrentSection('/registro-proveedor') ? 'current' : ''}>
                            <div>Proveedores</div> <div>Aliados</div>
                           </Link>
                        </li> */}
                      </>
                    }
                    {this.props.iscic &&
                      <li className="cic-sub-menu cic-single-line-menu">
                        <a className={(this.isCurrentSection('/publica') || this.isCurrentSection('/registro-brigadista') ||
                          this.isCurrentSection('/registro-proveedor')) ? 'current' : ''}
                          href="#">Acciones públicas</a>
                        <ul className="dropdown-nav">
                          <li>
                            <Link to="/publica">
                              Registra una Necesidad
                             </Link>
                          </li>
                          <li className="cic-single-line-menu">
                            <Link to="/testimonios">
                              Testimonios
                              </Link>
                          </li>
                          <li>
                            <Link to="/registro-brigadista">
                              Inscríbete como Brigadista
                             </Link>
                          </li>
                          {/*                           <li>
                            <Link to="/registro-proveedor">
                              Aliados
                             </Link>
                          </li> */}
                        </ul>
                      </li>
                    }
                    <li className="cic-sub-menu">
                      <a className={(this.isCurrentSection('/comofunciona') || this.isCurrentSection('/faq') ||
                        this.isCurrentSection('/quienessomos') || this.isCurrentSection('/beneficios') ||
                        this.isCurrentSection('/aliados') || this.isCurrentSection('/transparencia')) ? 'current' : ''}
                        href="#"><div>Sobre</div><div>Nosotros</div></a>
                      <ul className="dropdown-nav">
                        <li>
                          <Link to="/comofunciona">
                            ¿Cómo funciona?
                              </Link>
                        </li>
                        <li>
                          <Link to="/faq">
                            Preguntas Frecuentes
                              </Link>
                        </li>
                        <li>
                          <Link to="/quienessomos">
                            ¿Quiénes Somos?
                              </Link>
                        </li>
                        <li>
                          <Link to="/beneficios">
                            Beneficios
                              </Link>
                        </li>
                        <li>
                          <Link to="/aliados">
                            Nuestros Aliados
                              </Link>
                        </li>
{/*                         <li>
                          <Link to="/transparencia">
                            Transparencia
                              </Link>
                        </li> */}
                      </ul>
                    </li>
                    {this.props.isLoggedIn && !this.props.iscic &&
                      <>
                        <li className="cic-single-line-menu">
                          <Link to="/misnecesidades" className={this.isCurrentSection('/misnecesidades') ||
                            (this.props.location.pathname === '/contactos') ? 'current' : ''}>
                            Mis Necesidades
                         </Link>
                        </li>
                      </>
                    }
                    <li className="cic-single-line-menu">
                      <a target="_blank" href="https://medium.com/@apoyacic" >
                        Blog
                        </a>
                    </li>
                    {this.props.iscic &&
                      <>
                        <li className="cic-sub-menu cic-single-line-menu">
                          <a className={(this.isCurrentSection('/contactosabiertos') || this.isCurrentSection('/todoscontactos') ||
                            this.isCurrentSection('/misnecesidades') || this.isCurrentSection('/admin-testimonios') || this.isCurrentSection('/admin-donaciones')) ? 'current' : ''}
                            href="#">Administración</a>
                          <ul className="dropdown-nav">
                            <li>
                              <Link to="/contactosabiertos">
                                Contactos Abiertos
                              </Link>
                            </li>
                            <li>
                              <Link to="/todoscontactos">
                                Contactos
                              </Link>
                            </li>
                            <li>
                              <Link to="/misnecesidades">
                                Todas las Necesidades
                              </Link>
                            </li>
                            <li>
                              <Link to="/admin-testimonios">
                                Testimonios
                              </Link>
                            </li>
                            <li>
                              <Link to="/admin-donaciones">
                                Donaciones
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    }
                  </ul>
                </nav>
                <div className="clearfix"></div>
              </div>

              {this.props.isLoggedIn ? <div className="right-side">
                <div className="header-widget manos-header-widget">
                  <div className="header-notifications user-menu">
                    <div className="carrito-checkout-toolbar apoya-manos-logged">
                      <Link to="/datoscheckout">{/* <i className="icon-feather-shopping-cart"></i> */}
                      <img src="https://apoyacic.s3.amazonaws.com/imagenes/Recurso_2Manitas.png"></img>
                        {this.state.necesidadesEnCanasta > 0 && <span>{this.state.necesidadesEnCanasta}</span>}</Link>
                    </div>
                  </div>
                </div>
                <div className="header-widget">
                  <div className="header-notifications user-menu">
                    <div className="header-notifications-trigger">
                      <a href="#"><i className="icon-material-outline-account-circle"></i></a>
                    </div>

                    <div className="header-notifications-dropdown">
                      <div className="user-status">
                        <div className="user-details">
                          <div className="user-name">
                            {sessionStorage.getItem('usuario-org-nombre')} <span>{sessionStorage.getItem('usuario-email')}</span>
                          </div>
                        </div>
                      </div>

                      <ul className="user-menu-small-nav">
                        <li>
                          <a onClick={this.cerrarSesion.bind(this)} href="index-logged-out.html">
                            <i className="icon-material-outline-power-settings-new"></i>
                            Cerrar Sesión
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <span className="mmenu-trigger">
                  <button className="hamburger hamburger--collapse" type="button">
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                </span>
              </div>
                :
                <div className="right-side">
                  <div className="header-widget apoya-manos">
                    {/*                     <a href="#"><img src="https://apoyacic.s3.amazonaws.com/imagenes/quieroapoyar2.png"></img>
                    <span>4</span></a> */}
                    <Link to="/datoscheckout">{/* <i className="icon-feather-shopping-cart"></i> */}
                    <img src="https://apoyacic.s3.amazonaws.com/imagenes/Recurso_2Manitas.png"></img>
                      {this.state.necesidadesEnCanasta > 0 && <span>{this.state.necesidadesEnCanasta}</span>}</Link>
                  </div>
                  <div className="header-widget">
                    <a href="#small-dialog-1" className="popup-with-zoom-anim log-in-button"><i className="icon-feather-log-in"></i> <span>Inicio Sesión</span></a>
                  </div>

                  <span className="mmenu-trigger">
                    <button className="hamburger hamburger--collapse" type="button">
                      <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                      </span>
                    </button>
                  </span>

                </div>
              }
            </div>
          </div>
        </header>
        <div className="clearfix"></div>

        <div id="small-dialog-1" className="zoom-anim-dialog mfp-hide dialog-with-tabs">

          <div className="sign-in-form">

            <ul className="popup-tabs-nav">
              <li><a href="#login">Iniciar Sesión</a></li>
              <li><a href="#register">Registro</a></li>
            </ul>

            <div className="popup-tabs-container">

              <div className="popup-tab-content" id="login">

                <div className="welcome-text">
                  <h3>Bienvenido a Apoya CIC</h3>
                  <span>No tienes una cuenta? <a href="#" className="register-tab">Registrarme</a></span>
                </div>

                <div id="errores-validacion-login" className={'notification error closeable ' + (!this.state.erroresValidacionLogin ? 'sin-errores' : '')}>
                  <p>{this.state.erroresValidacionLogin}</p>
                  <a className="close" href="#"></a>
                </div>

                <div className="input-with-icon-left">
                  <i className="icon-material-baseline-mail-outline"></i>
                  <input onChange={this.setEmailLogin.bind(this)} type="text" className="input-text with-border" name="emailaddress"
                    id="email" placeholder="Correo Electrónico" value={this.state.emailLogin} />
                </div>

                <div className="input-with-icon-left">
                  <i className="icon-material-outline-lock"></i>
                  <input onChange={this.setContrasenaLogin.bind(this)} type="password" className="input-text with-border" name="password"
                    id="password" placeholder="Contraseña" value={this.state.contrasenaLogin} />
                </div>

                <button onClick={this.iniciarSesion.bind(this)} className="button full-width button-sliding-icon ripple-effect" >Iniciar Sesión <i className="icon-material-outline-arrow-right-alt"></i></button>

              </div>
              <div className="popup-tab-content" id="register">
                <div className="welcome-text">
                  <h3>Crea tu cuenta</h3>
                </div>

                <div id="errores-validacion-registro" className={'notification error closeable ' + (!this.state.erroresValidacionRegistro ? 'sin-errores' : '')}>
                  <p>{this.state.erroresValidacionRegistro}</p>
                  <a className="close" href="#"></a>
                </div>
                <div title="Te enviaremos un correo de confirmación" data-tippy-placement="bottom" className="input-with-icon-left">
                  <i className="icon-material-baseline-mail-outline"></i>
                  <input type="text" className="input-text with-border" onChange={this.setEmailRegistro.bind(this)} name="emailaddress-register"
                    id="emailaddress-register" placeholder="Correo Electrónico*" value={this.state.emailRegistro} />
                </div>
                <div title="Este nombre aparecera en la necesidad publicada" data-tippy-placement="bottom" className="input-with-icon-left">
                  <i className="icon-material-outline-account-balance"></i>
                  <input type="text" className="input-text with-border" onChange={this.setNombreOrganizacionRegistro.bind(this)}
                    name="organizationName-register" id="organizacionName-register" placeholder="Nombre de Organizacion" value={this.state.organizacionRegistro} />
                </div>
                <div className="input-with-icon-left">
                  <i className="icon-material-outline-account-circle"></i>
                  <input type="text" className="input-text with-border" onChange={this.setNombreRegistro.bind(this)} name="name-register"
                    id="name-register" placeholder="Nombre" value={this.state.nombreRegistro} />
                </div>
                <div className="input-with-icon-left">
                  <i className="icon-feather-phone"></i>
                  <input type="text" className="input-text with-border" onChange={this.setTelefonoRegistro.bind(this)} name="cel-register"
                    id="cel-register" placeholder="Celular" value={this.state.celularRegistro} />
                </div>
                <div className="input-with-icon-left">
                  <i className="icon-material-outline-location-on"></i>
                  <input type="text" className="input-text with-border" onChange={this.setDireccionRegistro.bind(this)} name="address-register"
                    id="address-register" placeholder="Dirección" value={this.state.direccionRegistro} />
                </div>

                <div className="input-with-icon-left">
                  <i className="icon-material-outline-lock"></i>
                  <input type="password" className="input-text with-border" onChange={this.setContrasenaRegistro.bind(this)}
                    name="password-register" id="password-register" placeholder="Contraseña*" value={this.state.contrasenaRegistro} />
                </div>
                <div className="input-with-icon-left">
                  <i className="icon-material-outline-lock"></i>
                  <input type="password" className="input-text with-border" onChange={this.setContrasena2Registro.bind(this)}
                    name="password-repeat-register" id="password-repeat-register" placeholder="Confirma contraseña*" value={this.state.contrasena2Registro} />
                </div>

                <button onClick={this.registrarUsuario.bind(this)} className="button full-width button-sliding-icon ripple-effect margin-top-10"  >Registrarme <i className="icon-material-outline-arrow-right-alt"></i></button>
                <a style={{ display: 'none' }} href="#small-dialog-3" className="usuario-creado-dialog"></a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}



export default withRouter(Toolbar);
