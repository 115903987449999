import React, { useEffect, useState } from "react";
import './TodosContactosTable.scss';
import $ from 'jquery';
import dt from 'datatables.net-dt';
import dtResponsive from 'datatables.net-responsive-dt';
import dtButtons from 'datatables.net-buttons-dt';
import dtButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';

function TodosContactosTable(props) {

    useEffect(() => {
        let todosContactos = props.data.map((value, i, arr) => {
            let categorias = [];
            if (value.interestedInCategories) {
                categorias = value.interestedInCategories.map((value, i, arr) => {
                    return value.name
                });
            }
            let contacto = [value.name || '', value.organizationName || '', value.email || '', value.cel || '', 
            {needTitle: value.needTitle, needId: value.needId}, value.needTicket || '', value.needTicketCic || '', value.quantity || 0,  value.createdAt, null];
            return contacto;
        });
        dt.$('#todos-contactos-table').DataTable({
            data: todosContactos,
            columns: [
                {
                    title: "Nombre",
                    width: "15%"
                },
                {
                    title: "Organización",
                    width: "10%"
                },
                {
                    title: "Email",
                    width: "10%"
                },
                {
                    title: "Tel/cel",
                    width: "10%"
                },
                {
                    title: "Necesidad",
                    width: "15%",
                    render: function(data, type, row){
                        let needId = data.needId;
                        let needTitle = data.needTitle;
                        return `<a target="_blank" href=/necesidad/${needId}>${needTitle}</a>`
                    }
                },
                {
                    title: "Ticket",
                    width: "5%"
                },
                {
                    title: "Ticket CIVIX",
                    width: "5%"
                },
                {
                    title: "Cantidad",
                    width: "10%"
                },
                {
                    title: "Fecha",
                    width: "10%"
                },
                {
                    className: "control",
                    width: "10%"
                }
            ],
            order: [[8, 'desc']],
            responsive: {
                details: {
                    type: 'column',
                    target: -1
                }
            },
            dom: 'lfrtip<"todos-contactos-clear">B',
            buttons: [{
                extend: 'csv',
                text: 'Descargar CSV'
            }]
        });
        return () => { dt.$('#todos-contactos-table').DataTable().destroy() };
    }, []);

    return (
        <table id="todos-contactos-table" className="display compact stripe" width="100%"></table>
    )
}

export default TodosContactosTable;
