import React, { useEffect, useState } from "react";
import './TransparenciaTable.scss';
import $ from 'jquery';
import dt from 'datatables.net-dt';
import dtResponsive from 'datatables.net-responsive-dt';
import dtButtons from 'datatables.net-buttons-dt';
import dtButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';

function TransparenciaTable(props) {

    const [fechaInicial, setFechaInicial] = useState(new Date());
    const [fechaFinal, setFechaFinal] = useState(new Date());
    const [totalNecesidades, setTotalNecesidades] = useState(0);
    const [totalDonacionesEfectivo, setTotalDonacionesEfectivo] = useState(0);
    const [totalDonacionesEnEspecie, setTotalDonacionesEnEspecie] = useState(0);
    const [totalGasto, setTotalGasto] = useState(0);
    const [totalSaldo, setTotalSaldo] = useState(0);

    useEffect(() => {
        let minDate = props.data.reduce((acc, data) => {
            return (new Date(data.createdAt) < acc ? new Date(data.createdAt) : acc);
        }, new Date());
        let maxDate = props.data.reduce((acc, data) => {
            return (new Date(data.createdAt) > acc ? new Date(data.createdAt) : acc);
        }, new Date(0));
        let totalDonacionesEfectivo = props.data.reduce((acc, data) => {
            if (data.tipoDonacionRecibida !== 'En especie') {
                return acc + (data.estimatedCost || 0);
            } else {
                return acc;
            }
        }, 0);
        let totalDonacionesEnEspecie = props.data.reduce((acc, data) => {
            if (data.tipoDonacionRecibida === 'En especie') {
                return acc + (data.estimatedCost || 0);
            } else {
                return acc;
            }
        }, 0);
        let totalGasto = props.data.reduce((acc, data) => {
            return acc + (data.gastos || 0);
        }, 0);
        let saldo = totalDonacionesEfectivo - totalGasto;
        setFechaInicial(minDate);
        setFechaFinal(maxDate);
        setTotalNecesidades(props.data.length);
        setTotalDonacionesEfectivo(Math.trunc(totalDonacionesEfectivo));
        setTotalDonacionesEnEspecie(Math.trunc(totalDonacionesEnEspecie));
        setTotalGasto(Math.trunc(totalGasto));
        setTotalSaldo(Math.trunc(saldo));
        let testimonios = props.data.map((value, i, arr) => {
            let saldo = value.estimatedCost - value.gastos;
            if (value.tipoDonacionRecibida === 'En especie') {
                saldo = 0 - value.gastos;
            }
            let testimonio = [value.ticket || '', value.createdAt, value.testimonyCreatedAt || '', value.tipoDonacionRecibida || '', '$' + (value.estimatedCost || 0), '$' + (value.gastos || 0), '$' + saldo, null];
            return testimonio;
        });
        dt.$('#transparencia-table').DataTable({
            data: testimonios,
            columns: [
                {
                    title: "Ticket"
                },
                {
                    title: "Fecha Necesidad",
                    render: function (data, type, row) {
                        if (type === 'display') {
                            return new Date(data).toLocaleDateString('es-MX');
                        } else {
                            return data;
                        }
                    }
                },
                {
                    title: "Fecha Testimonio",
                    render: function (data, type, row) {
                        if (type === 'display') {
                            return data ? new Date(data).toLocaleDateString('es-MX') : data;
                        } else {
                            return data;
                        }
                    }
                },
                {
                    title: "Tipo donación"
                },
                {
                    title: "Donación/Costo Estimado"
                },
                {
                    title: "Costo Total"
                },
                {
                    title: "Saldo"
                },
                {
                    className: "control"
                }
            ],
            order: [[1, 'desc']],
            responsive: {
                details: {
                    type: 'column',
                    target: -1
                }
            },
            dom: 'lfrtip<"transparencia-clear">B',
            buttons: [{
                extend: 'csv',
                text: 'Descargar CSV'
            }],
            pageLength: 50
        });
        return () => { dt.$('#transparencia-table').DataTable().destroy() };
    }, [props.data]);

    return (
        <div className="transparencia-table-wrapper">
            <h1 className="titulo-tabla-testimonios">Transparencia</h1>
            <div>
                <h3>{totalNecesidades} necesidades atendidas</h3>
            </div>
            <div>
                Datos desde {fechaInicial.toLocaleDateString('es-MX')} a {fechaFinal.toLocaleDateString('es-MX')}
            </div>
            <div className="totales-transparencia">
                <div className="totales-transparencia-row">
                    <span className="bloque-transparencia">
                        <div className="total-label">Donaciones en efectivo</div>
                        <div>${totalDonacionesEfectivo.toLocaleString("es-MX")}</div>
                    </span>
                    <span className="transparencia-operador">
                        +
                    </span>
                    <span className="bloque-transparencia">
                        <div className="total-label">Donaciones en especie</div>
                        <div>${totalDonacionesEnEspecie.toLocaleString("es-MX")}</div>
                    </span>
                    <span className="transparencia-operador">
                        =
                    </span>
                    <span className="bloque-transparencia">
                        <div className="total-label">Total</div>
                        <div>${(totalDonacionesEnEspecie + totalDonacionesEfectivo).toLocaleString("es-MX")}</div>
                    </span>
                </div>
                <div className="totales-transparencia-row">
                    <span className="bloque-transparencia">
                        <div className="total-label">Donaciones en efectivo</div>
                        <div>${totalDonacionesEfectivo.toLocaleString("es-MX")}</div>
                    </span>
                    <span className="transparencia-operador">
                        -
                    </span>
                    <span className="bloque-transparencia">
                        <div className="total-label">Gasto total</div>
                        <div>${totalGasto.toLocaleString("es-MX")}</div>
                    </span>
                    <span className="transparencia-operador">
                        =
                    </span>
                    <span className="bloque-transparencia">
                        <div className="total-label">Saldo</div>
                        <div>${totalSaldo.toLocaleString("es-MX")}</div>
                    </span>
                </div>
            </div>
            <table id="transparencia-table" className="display compact stripe" width="100%"></table>
        </div>
    )
}

export default TransparenciaTable;
