import React, { useEffect, useState } from "react";
import './TestimoniosAdminTable.scss';
import $ from 'jquery';
import dt from 'datatables.net-dt';
import dtResponsive from 'datatables.net-responsive-dt';
import dtButtons from 'datatables.net-buttons-dt';
import dtButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
import AgregarGastosTestimonioDialog from "../dialogs/AgregarGastosTestimonioDialog";
import { cicApiUrl } from "../../common/share-util";

function TestimoniosAdminTable(props) {

    const [testimonioTestimonioDialog, setTestimonioTestimonioDialog] = useState({});

    useEffect(() => {
        let testimonios = props.data.map((value, i, arr) => {
            //let gastosTotal = (value.gastos && value.gastos.length > 0) ? value.gastos.reduce((acc, curr) => { return acc + curr.cantidad }, 0) : 0;
            let comisionPago = 0;
            let mercancia = 0;
            let logistica = 0;
            let gastosTotal = 0;
            let saldo = 0;
            if (value.gastos && value.gastos.length > 0) {
                comisionPago = value.gastos.reduce((acc, gasto) => {
                    if (gasto.tipoGasto === 'Comisión Pago') {
                        return acc + gasto.cantidad;
                    } else {
                        return acc;
                    }
                }, 0);
                mercancia = value.gastos.reduce((acc, gasto) => {
                    if (gasto.tipoGasto === 'Mercancía') {
                        return acc + gasto.cantidad;
                    } else {
                        return acc;
                    }
                }, 0);
                logistica = value.gastos.reduce((acc, gasto) => {
                    if (gasto.tipoGasto === 'Logística') {
                        return acc + gasto.cantidad;
                    } else {
                        return acc;
                    }
                }, 0);
            }
            gastosTotal = comisionPago + mercancia + logistica;
            saldo = value.estimatedCost - gastosTotal;
            let testimonio = [value._id, value.ticket || '', '$' + (value.estimatedCost || 0), value.tipoDonacionRecibida || '', value.hidden || false, value.status || 'faltan datos', '$' + gastosTotal, '$' + saldo,
            value.picture || '', value.ine || '', value.createdAt, null];
            return testimonio;
        });
        dt.$('#testimonios-admin-table').DataTable({
            data: testimonios,
            columns: [
                {
                    visible: false
                },
                {
                    title: "Ticket"
                },
                {
                    title: "Costo Estimado"
                },
                {
                    title: "Tipo donación"
                },
                {
                    title: "Esconder",
                    render: function (data, type, row) {
                        if (type === 'display') {
                            let checked = data ? 'checked' : '';
                            return `<input type="checkbox" class="esconder-testimonio-btn" data-testimony-id=${row[0]} ${checked}></input>`
                        } else {
                            return data;
                        }
                    }
                },
                {
                    title: "Estatus",
                    render: function (data, type, row) {
                        if (type === 'display') {
                            let faltanDatosSelected = '';
                            let finalizadoSelected = '';
                            let selectedClass = '';
                            if (!data || (data === 'faltan datos')) {
                                faltanDatosSelected = 'selected';
                                selectedClass = 'faltan-datos';
                            }
                            if (data === 'finalizado') {
                                finalizadoSelected = 'selected';
                                selectedClass = 'finalizado';
                            }
                            return `<select class="estatus-testimonio-select ${selectedClass}" data-testimony-id=${row[0]}>
                            <option  ${faltanDatosSelected}>faltan datos</option>
                            <option ${finalizadoSelected}>finalizado</option>
                            </select>`
                        } else {
                            return data;
                        }
                    }
                },
                {
                    title: "Gastos Registrados",
                    render: function (data, type, row) {
                        if (type === 'display') {
                            return data + `<button data-need-ticket=${row[1]} class="boton-tabla-testimonios">Ver detalle</button>`
                        } else {
                            return data;
                        }
                    }
                },
                {
                    title: "Saldo"
                },
                {
                    title: "Foto Testimonio",
                    render: function (data, type, row) {
                        if (data && type === 'display') {
                            return '<a href="' + data + '" target="_blank">' + data + '</a>';
                        } else {
                            return data;
                        }
                    }
                },
                {
                    title: "ID. Donatario",
                    render: function (data, type, row) {
                        if (data && type === 'display') {
                            return '<a href="' + data + '" target="_blank">' + data + '</a>';
                        } else {
                            return data;
                        }
                    }
                },
                {
                    title: "Fecha"
                },
                {
                    className: "control"
                }
            ],
            order: [[10, 'desc']],
            responsive: {
                details: {
                    type: 'column',
                    target: -1
                }
            },
            dom: 'lfrtip<"testimonios-admin-clear">B',
            buttons: [{
                extend: 'csv',
                text: 'Descargar CSV',
                exportOptions: {
                    orthogonal: 'filter'
                }
            }],
            drawCallback: function () {
                window.$('#testimonios-admin-table .boton-tabla-testimonios').off('click');
                window.$('#testimonios-admin-table .esconder-testimonio-btn').off('click');
                window.$('#testimonios-admin-table .estatus-testimonio-select').off('change');
                window.$('#testimonios-admin-table .boton-tabla-testimonios').on('click', function () {
                    openTestimonioDialog(window.$(this).data('need-ticket'));
                });
                window.$('#testimonios-admin-table .esconder-testimonio-btn').on('click', function (event) {
                    guardarCampo(event.target.checked, 'hidden', 'api/Testimonies/setHidden', window.$(this).data('testimony-id'), dt);
                });
                window.$('#testimonios-admin-table .estatus-testimonio-select').on('change', function (event) {
                    let row = window.$(event.target).parent().parent();
                    let rowData = [...dt.$('#testimonios-admin-table').DataTable().row(row).data()];
                    rowData[4] = event.target.value;
                    dt.$('#testimonios-admin-table').DataTable().row(row).data(rowData).draw(false);
                    guardarCampo(event.target.value, 'status', 'api/Testimonies/setStatus', window.$(this).data('testimony-id'), dt);
                });
            },
            pageLength: 25
        });
        window.$('.abrir-agregar-gastos-testimonio').magnificPopup({
            type: 'inline',

            fixedContentPos: false,
            fixedBgPos: true,

            overflowY: 'auto',

            closeBtnInside: true,
            preloader: false,

            midClick: true,
            removalDelay: 300,
            mainClass: 'my-mfp-zoom-in',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close necesidad-publicada-close"></button>',
            callbacks: {
                close: function () {
                    setTestimonioTestimonioDialog({});
                }
            }
        });

        return () => { dt.$('#testimonios-admin-table').DataTable().destroy() };
    }, [props.data]);

    function openTestimonioDialog(testimonioTicket) {
        let testimonio = props.data.find((val) => val.ticket === testimonioTicket);
        setTestimonioTestimonioDialog(testimonio);
        window.$('.abrir-agregar-gastos-testimonio').magnificPopup('open');
    }

    function guardarCampo(campo, campoName, endpoint, testimonioId) {
        let payloadData = JSON.stringify({
            token: sessionStorage.getItem('token-apoya'),
            testimonyId: testimonioId,
            [campoName]: campo
        });
        window.$.ajax({
            type: 'POST', url: cicApiUrl + endpoint, data: payloadData, success: (data, status) => {
                if (status === 'success' && data && data.status === 'OK') {
                    window.Snackbar.show({
                        text: 'Testimonio actualizado.',
                        pos: 'top-center',
                        showAction: false,
                        actionText: "Dismiss",
                        duration: 3000,
                        textColor: '#fff',
                        backgroundColor: '#383838'
                    });
                } else {
                    window.alert('Error al actualizar testimonio');
                    window.location.reload();
                }
            }, contentType: 'application/json'
        });
    }

    return (
        <div className="testimonios-admin-wrapper">
            <a style={{ display: 'none' }} href="#small-dialog-2" className=" button ripple-effect big margin-top-30 abrir-agregar-gastos-testimonio"></a>
            <AgregarGastosTestimonioDialog onUpdateTestimonios={props.onUpdateTestimonios} testimonio={testimonioTestimonioDialog}></AgregarGastosTestimonioDialog>
            <h1 className="titulo-tabla-testimonios">Testimonios</h1>
            <table id="testimonios-admin-table" className="display compact stripe" width="100%"></table>
        </div>
    )
}

export default TestimoniosAdminTable;
