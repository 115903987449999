import React, { useEffect, useState } from "react";
import { useHistory, Link, withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl } from "../common/share-util";

import './Faq.scss';

function Faq(props) {

	let history = useHistory();

	useEffect(() => {
		if (props.redirectAPublica) {
			props.onDidRedirect(true);
			history.push('/publica');
		}
	}, [props.redirectAPublica]);

	useEffect(() => {
		var accordion = (function () {

			var $accordion = window.$('.js-accordion-apoya');
			var $accordion_header = $accordion.find('.js-accordion-header-apoya');

			var settings = {
				speed: 400,
				oneOpen: false
			};

			return {
				init: function ($settings) {
					$accordion.addClass('initiated');
					$accordion_header.on('click', function () {
						accordion.toggle(window.$(this));
					});

					window.$.extend(settings, $settings);

					if (settings.oneOpen && window.$('.js-accordion-item-apoya.active').length > 1) {
						window.$('.js-accordion-item-apoya.active:not(:first)').removeClass('active');
					}

					window.$('.js-accordion-item-apoya.active').find('> .js-accordion-body-apoya').show();
				},
				toggle: function ($this) {

					if (settings.oneOpen && $this[0] != $this.closest('.js-accordion-apoya').find('> .js-accordion-item-apoya.active > .js-accordion-header-apoya')[0]) {
						$this.closest('.js-accordion-apoya')
							.find('> .js-accordion-item-apoya')
							.removeClass('active')
							.find('.js-accordion-body-apoya')
							.slideUp();
					}

					$this.closest('.js-accordion-item-apoya').toggleClass('active');
					$this.next().stop().slideToggle(settings.speed);
				}
			};
		})();
		accordion.init({ speed: 300, oneOpen: true });
	}, []);

	return (
		<React.Fragment>
			<h1 className="faq-titulo">Preguntas Frecuentes</h1>

			<div className="faq-content accordion js-accordion-apoya">

			<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Por publicar mi necesidad soy automáticamente acreedor a un donativo?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
						No, la plataforma <a href="https://apoya.cic.mx">apoya.cic.mx</a>, es un medio para incrementar tu alcance de convocatoria como organización o en lo particular, esperando encontrar un candidato que brinde el donativo.
						</div>
					</div>
				</div>

				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Cobra apoya.cic.mx alguna comisión por sus servicios?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
						No. El CIC es una asociación sin fines de lucro que recibe donativos de parte de empresarios lo cual nos permite no cobrar por los servicios ofrecidos en la plataforma <a href="https://apoya.cic.mx">apoya.cic.mx</a>.
						</div>
					</div>
				</div>
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Qué tipo de donativos reciben?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
						Donativos en especie y monetarios.
					</div>
					</div>
				</div>
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Entregan dinero en efectivo por las necesidades?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
						No, el CIC a través de ApoYA recibe donativos monetarios a través de tarjetas bancarias y por la plataforma Paypal, dichos donativos se utilizan para adquirir la necesidad en especie, bajo ninguna circunstancia se entrega efectivo a los solicitantes.
						</div>
					</div>
				</div>
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Entregan recibo por donaciones en monetarias?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
						Sí, si realizaste una donación monetaria que supera la cantidad de 500 pesos puedes solicitar un recibo que puede ser deducible.
				</div>
					</div>
				</div>
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Qué es un donativo en especie?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
						Las donaciones en especie son aquellas que en lugar de aportar dinero en efectivo, aportan bienes. Muchas veces se trata de bienes que el donante ya cuenta o dejará de utilizar. Pero también puede ocurrir que el donante decida adquirirlos para dárselos a un solicitante.
				</div>
					</div>
				</div>
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Qué es un brigadista?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
						Es un voluntario encargado de recibir, recolectar y administrar los apoyos en especie recibidos en los Centro de Centros de Acopio ApoYa, se recomienda que el brigadista labore o tenga presencia dentro de los Centros..
				</div>
					</div>
				</div>
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Qué es un Centro de Acopio ApoYa?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
						El punto de recolección y entrega de apoyos, es administrado por un Brigadista y puede residir en una organización de la sociedad civil, institución pública, escuela, iglesia, empresa, centro comunitario. 
				</div>
					</div>
				</div>
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Qué clase de apoyos en especie se pueden solicitar/realizar?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
							<ul>
								<li>Equipo y accesorios Médicos</li>
								<li>Artículos de limpieza</li>
								<li>Alimentos y artículos de primera necesidad</li>
							</ul>
						</div>
					</div>
				</div>
				{/* <div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Qué tipo de voluntariado puedo solicitar/ofrecer?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
							<ul>
								<li>Profesionales de Salud</li>
								<li>Psicólogos</li>
								<li>Grupos vulnerables</li>
								<li>Movilidad</li>
								<li>Educación y cursos</li>
							</ul>
							<p>Si crees que debemos de agregar una categoría adicional, por favor envíanos los detalles por medio de la sección <Link to={'/apoya'}>
								{' Apoya.'}
							</Link>

							</p>
						</div>
					</div>
				</div> */}
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Quién puede recibir donativos de ApoYA?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
							<ul>
								<li>Personas pertenecientes a grupos vulnerables y con una necesidad apremiante que pudiera poner en riesgo su integridad o vida.</li>
								<li>Organizaciones de la sociedad civil, instituciones de beneficencia y casas hogar que brindan apoyo a grupos vulnerables.</li>
								<li>Escuelas con necesidades derivadas de problemáticas urgentes.</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Quienes pueden donar en ApoYA?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
							<ul>
								<li>Ciudadanos</li>
								<li>Iniciativa privada</li>
								<li>Instituciones gubernamentales y no gubernamentales</li>
								<li>Organizaciones de la sociedad civil</li>
								<li>Cualquier persona mayor de edad que desee realizar apoyos en especie y/o voluntariado</li>
							</ul>
						</div>
					</div>
				</div>
				{/* <div className="accordion__item js-accordion-item-apoya">
					<div className="accordion-header js-accordion-header-apoya">¿Quién puede publicar una Necesidad?</div>
					<div className="accordion-body js-accordion-body-apoya">
						<div className="accordion-body__contents">
							Cualquier organización verificada o ciudadanos certificados por el Centro de Integración Ciudadana.
						</div>
					</div>
				</div> */}
			</div>
		</React.Fragment>
	);
}

export default Faq;
