import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl, paypalPaymentApiUrl } from "../common/share-util";

import './Checkout.scss';

function Checkout(props) {
	const [datosContacto, setDatosContacto] = useState({
		erroresValidacion: '',
		loadingResponse: false,
		errorRespuesta: false
	});

	const [paymentDone, setPaymentDone] = useState(false);

	const [transactionId, setTransactionId] = useState('');

	const [total, setTotal] = useState(0);

	const executingPaymentRef = useRef(false);

	let history = useHistory();

	useEffect(() => {
		window.$("a.close").removeAttr("href").on('click', function () {
			function slideFade(elem) {
				var fadeOut = { opacity: 0, transition: 'opacity 0.5s' };
				elem.css(fadeOut).slideUp();
			}
			slideFade(window.$(this).parent());
		});
	}, []);

	useEffect(() => {
		let total = 0;
		if (props.necesidadesCheckout) {
			props.necesidadesCheckout.map((necesidad) => {
				total += necesidad.costoEstimado;
			});
		} else {
			total = 0;
		}
		setTotal(total);
	}, [props.necesidadesCheckout])

	function executePayment() {
		if (props.paymentId && !executingPaymentRef.current) {

			let total = 0;
			if (props.necesidadesCheckout) {
				props.necesidadesCheckout.map((necesidad) => {
					total += necesidad.costoEstimado;
				});
			} else {
				total = 0;
			}
			window.fbq('track', 'Purchase', {value: total, currency: props.modoDolares ? 'USD' : 'MXN'});

			executingPaymentRef.current = true;
			let payloadData = JSON.stringify({
				"payer_id": props.payerId,
				"paymentId": props.paymentId
			});
			window.$.ajax({
				type: 'POST', url: cicApiUrl + 'api/Users/executePayment', data: payloadData, contentType: 'application/json', success: (data, status) => {
					if (status === 'success' && data && data.id && data.state === 'approved' && data.transactions && data.transactions.length > 0
						&& data.transactions[0] && data.transactions[0].related_resources && data.transactions[0].related_resources.length > 0 &&
						data.transactions[0].related_resources[0] && data.transactions[0].related_resources[0].sale && data.transactions[0].related_resources[0].sale.id) {
						console.log('completado');
						console.log('transaction id: ' + data.transactions[0].related_resources[0].sale.id);
						setTransactionId(data.transactions[0].related_resources[0].sale.id);
						props.datosDonaciones.paypalPaymentId = data.transactions[0].related_resources[0].sale.id;
						if(props.modoDolares){
							props.datosDonaciones.paymentInDollars = true;
						}
						window.$.ajax({
							type: 'POST', url: cicApiUrl + 'api/Donations/create', data: JSON.stringify(props.datosDonaciones), success: (data, status) => {
								// console.log(data, status);
							}, contentType: 'application/json'
						});
						setPaymentDone(true);
						props.onPaymentDone();
					} else {
						console.log('completado - error');
						console.log('transaction id: ' + JSON.stringify(data));
						setPaymentDone(true);
						props.onPaymentDone();
					}
				}
			});
		}
	}

	return (
		<React.Fragment>
			{datosContacto.loadingResponse && <div style={{ position: 'fixed', top: 'calc(50vh - 100px)', left: 'calc(50vw - 100px)', zIndex: 999 }}>
				<Loader
					type="Grid"
					color="#2a41e8"
					height={200}
					width={200}
				/>
			</div>
			}
			{((props.necesidadesCheckout && props.necesidadesCheckout.length > 0) || paymentDone) &&
				<div className="sign-in-form checkout">

					<div className="popup-tabs-container">

						<div id="id-btn-pp" className="popup-tab-content row seccion-formulario">
							{!paymentDone && (props.paymentId && props.payerId) && <>
								<div className="welcome-text">
									<h3>Al hacer clic en el botón se realizará el cargo a tu tarjeta.</h3>

									<div className="datos-necesidad">
										<div><strong>Total: </strong>${total || 0} {props.modoDolares ? 'USD' : 'pesos'}</div>
									</div>
								</div>

								<div id="errores-validacion" className={'notification error closeable ' + (!datosContacto.erroresValidacion ? 'sin-errores' : '')}>
									<p>{datosContacto.erroresValidacion}</p>
									<a className="close" href="#"></a>
								</div>
								<a style={{ display: 'none' }} href="#small-dialog" className="datos-enviados-dialog"></a>
								{!datosContacto.loadingResponse &&
									<button onClick={executePayment} className="button boton-enviar">Confirmar pago </button>
								}
							</>
							}
							{
								(paymentDone && transactionId) &&
								<>
									<div className="welcome-text">
										<h3>Pago realizado con éxito.</h3>

										<div className="transaction-id">
											<div><strong>Id de transacción: </strong>{transactionId}</div>
										</div>
									</div>

									<div id="errores-validacion" className={'notification error closeable ' + (!datosContacto.erroresValidacion ? 'sin-errores' : '')}>
										<p>{datosContacto.erroresValidacion}</p>
										<a className="close" href="#"></a>
									</div>

								</>
							}
							{
								(paymentDone && !transactionId) &&
								<>
									<div className="welcome-text">
										<h3>Hubo un error al realizar el pago. La transacción no se ha completado.</h3>
									</div>

									<div id="errores-validacion" className={'notification error closeable ' + (!datosContacto.erroresValidacion ? 'sin-errores' : '')}>
										<p>{datosContacto.erroresValidacion}</p>
										<a className="close" href="#"></a>
									</div>

								</>
							}
						</div>
					</div>
				</div>
			}
		</React.Fragment>
	);
}

export default Checkout;
