import React from "react";
import { Link } from "react-router-dom";
import { cicApiUrl, isInt } from "../common/share-util";
import "./CarruselNecesidades.scss";

class CarruselNecesidades extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initializedCarousel: false,
      triggerUpdate: false
    };
  }

  componentDidMount() {
    this.setState({ triggerUpdate: true });
  }

  componentDidUpdate() {
    if (!this.state.initializedCarousel && this.props.necesidades && this.props.necesidades.length > 0) {
      this.setState({ initializedCarousel: true });
      window.$('.default-slick-carousel-necesidades').slick({
        infinite: true,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        arrows: false,
        adaptiveHeight: false,
        responsive: [
          {
            breakpoint: 1292,
            settings: {
              dots: true,
              arrows: false
            }
          },
          {
            breakpoint: 1110,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              arrows: false
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: false
            }
          }
        ]
      });
    }
  }

  checkoutNecesidad(necesidad) {
    this.props.onSeleccionarNecesidadCheckout({
      ticket: necesidad.ticket,
      categoryName: necesidad.categoryName,
      costoEstimado: necesidad.estimatedCost
    });
    window.fbq('track', 'AddToCart');
  }

  render() {
    return (
      <React.Fragment>
        <div className="section carrusel-necesidades padding-top-15 padding-bottom-15 full-width-carousel-fix">
          {/*           <div style={{ textAlign: 'center' }}>
            <div className="necesidades-titulo margin-top-0 margin-bottom-25">
              <h3>Testimonios</h3>
            </div>
          </div> */}

          <div style={{ margin: 'auto' }}>
            <div className="default-slick-carousel default-slick-carousel-necesidades">
              {
                this.props.necesidades.map((necesidad) => {
                  return (
                    <div key={necesidad._id} className="job-listing freelancer">
                      <div className="job-listing-details">
                        <div className="imagen-necesidad">
                          <img src={necesidad.image} alt=""></img>
                        </div>
                        <div className="job-listing-description descripcion-necesidad">
                          <h3 className="job-listing-title">{necesidad.title.length < 60
                            ? necesidad.title : necesidad.title.substring(0, 60) + '...'
                          }</h3>
                          <span className="nombre-categoria">{necesidad.categoryName}</span>
                          {
                            necesidad.requirements &&
                            <>
                              <div className="requisitos-label">Especificaciones: </div>
                              <div className="requisitos-necesidad">
                                {necesidad.requirements.length < 60 ?
                                  necesidad.requirements : necesidad.requirements.substring(0, 60) + '...'
                                }
                              </div>
                            </>
                          }
                          <br></br>
                          <span className="costo-estimado-label">Costo estimado:&nbsp;</span>
                          <span className="costo-estimado" >${necesidad.estimatedCost || 0} pesos</span>
                        </div>
                      </div>

                      <div className="job-listing-footer">
                        <div className="footer-container">
                          <div className="footer-item">
                            <div>Municipio</div>
                            <div>
                              <i className="icon-material-outline-location-on"></i> <span>{necesidad.municipality}</span>
                            </div>
                          </div>
                          <div className="footer-item">
                            <div>Monto</div>
                          ${necesidad.estimatedCost || 0} pesos
                          </div>
                          <div className="footer-item">
                            <div>Fecha</div>
                            {new Date(necesidad.createdAt).toLocaleDateString("es-MX")}
                          </div>
                        </div>
                        <div className="footer-container-2">
                          <div className="footer-item">
                            <Link to={"/necesidad/" + necesidad._id} className="button full-width">
                              Ver mas
                            </Link>
                          </div>
                          <div className="footer-item">
                            <Link onClick={this.checkoutNecesidad.bind(this, necesidad)} to="/datoscheckout" className="button full-width donar-tarjeta-btn">
                              Donar con tarjeta
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CarruselNecesidades;
