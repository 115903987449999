import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl, paypalPaymentApiUrl, paypalPaymentMode, paypalExperienceProfile } from "../common/share-util";

import './CheckoutDatos.scss';

function CheckoutDatos(props) {
	const [datosContacto, setDatosContacto] = useState({
		nombre: '',
		apellido: '',
		email: '',
		telefono: '',
		erroresValidacion: '',
		loadingResponse: false,
		errorRespuesta: false
	});

	const [mensajesPersonalizados, setMensajesPersonalizados] = useState({});
	const mensajesRef = useRef(mensajesPersonalizados);
	const itemsRef = useRef([]);

	const [costoTotal, setCostoTotal] = useState(0);

	const [procesandoPago, setProcesandoPago] = useState(false);

	const [newsletter, setNewsletter] = useState(false);

	const paymentCreatedRef = useRef(false);

	useEffect(() => {
		if (props.necesidadesCheckout) {
			let total = 0;
			props.necesidadesCheckout.map((necesidad) => {
				total += necesidad.costoEstimado;
			});
			setCostoTotal(total);
		} else {
			setCostoTotal(0);
		}
	}, [props.necesidadesCheckout]);

	let history = useHistory();

	useEffect(() => {
		window.$("a.close").removeAttr("href").on('click', function () {
			function slideFade(elem) {
				var fadeOut = { opacity: 0, transition: 'opacity 0.5s' };
				elem.css(fadeOut).slideUp();
			}
			slideFade(window.$(this).parent());
		});
	}, []);

	function updateNombre(event) {
		let value = event.target.value;
		if (value) {
			value = value.trim();
		}
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ nombre: value } } });
	}

	function updateApellido(event) {
		let value = event.target.value;
		if (value) {
			value = value.trim();
		}
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ apellido: value } } });
	}

	function updateEmail(event) {
		let value = event.target.value;
		if (value) {
			value = value.trim();
		}
		setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ email: value } } });
	}

	function updateTelefono(event) {
		let soloNumeros = /^[0-9]+$/;
		let value = event.target.value;
		if ((soloNumeros.test(value) || !value) && value.length < 11) {
			setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ telefono: value } } });
		}
	}

	function updateNewsletter(event) {
		setNewsletter(event.target.checked);
	}

	function updateMensaje(event, ticket) {
		let value = event.target.value;
		if (value && value.length >= 127) {
			value = value.slice(0, 127);
		}
		setMensajesPersonalizados((mensajesAnteriores) => {
			mensajesRef.current = { ...mensajesAnteriores, ...{ [ticket]: value } };
			let items = [];
			for (let i = 0; i < props.necesidadesCheckout.length; i++) {
				items.push({
					name: mensajesRef.current[props.necesidadesCheckout[i].ticket] || props.necesidadesCheckout[i].categoryName,
					price: props.necesidadesCheckout[i].costoEstimado,
					currency: props.modoDolares ? 'USD' : 'MXN',
					quantity: 1,
					sku: mensajesRef.current[props.necesidadesCheckout[i].ticket] ? props.necesidadesCheckout[i].ticket + ' - ' + props.necesidadesCheckout[i].categoryName : props.necesidadesCheckout[i].ticket
				});
			}
			itemsRef.current = items;
			return mensajesRef.current;
		});
	}

	function validarDatos() {
		let mensajeError = '';
		if (!datosContacto.nombre || !datosContacto.apellido || !datosContacto.telefono || !datosContacto.email) {
			mensajeError = 'Por favor llene los campos requeridos.';
		}
		let validarEmail = /^\S+@\S+$/;
		if (!validarEmail.test(datosContacto.email)) {
			if (mensajeError) {
				mensajeError += ' ';
			}
			mensajeError += 'Por favor proporcione un correo válido.';
		}
		return mensajeError;
	}

	function initCheckout() {
		document.getElementById('errores-validacion').removeAttribute('style');
		let mensajeError = validarDatos();
		if (mensajeError) {
			window.$('html, body').animate({ scrollTop: 0 }, 500);
			setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ erroresValidacion: mensajeError } } });
		} else {
			setDatosContacto(datosAnteriores => { return { ...datosAnteriores, ...{ erroresValidacion: mensajeError } } });
			let total = 0;
			if (props.necesidadesCheckout) {
				props.necesidadesCheckout.map((necesidad) => {
					total += necesidad.costoEstimado;
				});
			} else {
				total = 0;
			}
			if (total && props.necesidadesCheckout) {
				let items = [];
				for (let i = 0; i < props.necesidadesCheckout.length; i++) {
					items.push({
						name: mensajesRef.current[props.necesidadesCheckout[i].ticket] || props.necesidadesCheckout[i].categoryName,
						price: props.necesidadesCheckout[i].costoEstimado,
						currency: props.modoDolares ? 'USD' : 'MXN',
						quantity: 1,
						sku: mensajesRef.current[props.necesidadesCheckout[i].ticket] ? props.necesidadesCheckout[i].ticket + ' - ' + props.necesidadesCheckout[i].categoryName : props.necesidadesCheckout[i].ticket
					});
				}
				itemsRef.current = items;
				let payloadData = JSON.stringify({
					"intent": "sale",
					"experience_profile_id": paypalExperienceProfile,
					"payer": {
						"payment_method": "paypal"
					},
					"application_context": {
						"user_action": "commit"
					},
					"transactions": [{
						"amount": {
							"currency": props.modoDolares ? "USD" : "MXN",
							"total": total
						},
						"payment_options": {
							"allowed_payment_method": "INSTANT_FUNDING_SOURCE"
						},
						"item_list": {
							"items": itemsRef.current
						}
					}],
					"redirect_urls": {
						"return_url": "https://apoya.cic.mx",
						"cancel_url": "https://apoya.cic.mx"
					}
				});
				window.$.ajax({
					type: 'POST', url: cicApiUrl + 'api/Users/createPayment', data: payloadData, contentType: 'application/json', success: (data, status) => {
						console.log(data);
						if (status === 'success' && data && data.id && data.state === 'created' && data.links && data.links.length > 0) {
							let approvalUrl = '';
							for (let i = 0; i < data.links.length; i++) {
								if (data.links[i].rel === 'approval_url') {
									approvalUrl = data.links[i].href;
								}
							}
							props.onSetPaymentId(data.id);
							if (approvalUrl) {
								setProcesandoPago(true);
								window.ppp = window.PAYPAL.apps.PPP({
									"approvalUrl": approvalUrl,
									"placeholder": "id-btn-pp",
									"mode": paypalPaymentMode,
									"miniBrowser": false,
									"payerEmail": datosContacto.email,
									"payerFirstName": datosContacto.nombre,
									"payerLastName": datosContacto.apellido,
									"payerPhone": datosContacto.telefono,
									"country": "MX",
									"language": "es_MX",
									"payerTaxId": "",
									"disallowRememberedCards": true,
									"onContinue": function (rememberedCardsT, payerId) {
										console.log('onContinue: ', rememberedCardsT, payerId);
										props.onSetPayerId(payerId);
										let donatedItems = [];
										for (let i = 0; i < props.necesidadesCheckout.length; i++) {
											donatedItems.push({
												ticket: props.necesidadesCheckout[i].ticket,
												price: props.necesidadesCheckout[i].costoEstimado,
												categoryName: props.necesidadesCheckout[i].categoryName,
												message: mensajesRef.current[props.necesidadesCheckout[i].ticket] ? mensajesRef.current[props.necesidadesCheckout[i].ticket] : ''
											});
										}
										let datosDonaciones = {
											name: datosContacto.nombre,
											lastname: datosContacto.apellido,
											email: datosContacto.email,
											phone: datosContacto.telefono,
											items: donatedItems,
											newsletter: newsletter,
											//		paypalPaymentId: details.purchase_units[0].payments.captures[0].id,
											totalPrice: total
										};
										props.onSetDatosDonaciones(datosDonaciones);
										history.push('/checkout');
									},
									"onError": function (error) {
										console.log(error);
									}
								});
								window.$('html, body').animate({ scrollTop: 0 }, 500);
							}
						}
					}
				});
			}
		}
	}

	function continuePaypal() {
		if (!paymentCreatedRef.current) {
			paymentCreatedRef.current = true;
			window.ppp.doContinue();
		}
		return false;
	}

	return (
		<React.Fragment>
			{datosContacto.loadingResponse && <div style={{ position: 'fixed', top: 'calc(50vh - 100px)', left: 'calc(50vw - 100px)', zIndex: 999 }}>
				<Loader
					type="Grid"
					color="#2a41e8"
					height={200}
					width={200}
				/>
			</div>
			}
			<div className="sign-in-form checkout-datos">

				<div className="popup-tabs-container">

					{!procesandoPago &&
						<div className="popup-tab-content row seccion-formulario">

							<div className="welcome-text">
								<h3>Dona a la necesidad que seleccionaste con tu tarjeta de crédito o débito.</h3>
								{props.necesidadesCheckout && props.necesidadesCheckout.length > 0 &&
									<>
										<table className="resumen-necesidades-tabla">
											<thead>
												<tr><th>Folio</th><th>Categoría</th><th>Costo</th><th></th></tr>
											</thead>
											<tbody>
												{
													props.necesidadesCheckout && props.necesidadesCheckout.map((necesidad) => {
														return (<React.Fragment key={necesidad.ticket}>
															<tr><td className="ticket-cell">{necesidad.ticket}</td>
																<td className="category-cell">{necesidad.categoryName}</td>
																<td className="costo-cell">${necesidad.costoEstimado}</td>
																<td className="quitar-btn-wrapper" ><div onClick={() => { props.onQuitarNecesidad(necesidad) }} title="Quitar" className="quitar-btn"><i className="icon-material-outline-delete"></i></div></td>
															</tr>
															<tr><td colSpan="4"><input type="text" maxLength="127" onChange={(event) => { updateMensaje(event, necesidad.ticket) }} value={mensajesPersonalizados[necesidad.ticket] || ''} placeholder="Mensaje personalizado para beneficiario"></input></td></tr>
														</React.Fragment>)
													})
												}
												<tr className="total-row"><td></td><td>Total:</td><td className="total-cell">${costoTotal} {props.modoDolares ? 'USD' : 'pesos'}</td><td></td></tr>
											</tbody>
										</table>
										<div className="resumen-necesidades-mobile">
											{props.necesidadesCheckout && props.necesidadesCheckout.map((necesidad) => {
												return (<div key={necesidad.ticket} className="datos-necesidad">
													<div><strong>Folio: </strong>{necesidad.ticket}</div>
													<div className="categoria-row"><strong>Categoría: </strong>{necesidad.categoryName}</div>
													<div><strong>Costo: </strong>${necesidad.costoEstimado || 0} {props.modoDolares ? 'USD' : 'pesos'}</div>
													<div className="mensaje-mobile"><input type="text" maxLength="127" onChange={(event) => { updateMensaje(event, necesidad.ticket) }} value={mensajesPersonalizados[necesidad.ticket] || ''} placeholder="Mensaje personalizado"></input></div>
													<div onClick={() => { props.onQuitarNecesidad(necesidad) }} title="Quitar" className="quitar-btn"><i className="icon-material-outline-delete"></i></div>
												</div>)
											})
											}
											{props.necesidadesCheckout && <>
												<div className="datos-total">
													<div><strong>Total: </strong>${costoTotal} {props.modoDolares ? 'USD' : 'pesos'}</div>
												</div>
											</>}
										</div>
									</>
								}
								{
									!props.necesidadesCheckout || props.necesidadesCheckout.length === 0 &&
									<div style={{ marginTop: '20px' }}>No hay necesidades seleccionadas</div>
								}
							</div>

							<div id="errores-validacion" className={'notification error closeable ' + (!datosContacto.erroresValidacion ? 'sin-errores' : '')}>
								<p>{datosContacto.erroresValidacion}</p>
								<a className="close" href="#"></a>
							</div>
							{props.necesidadesCheckout && props.necesidadesCheckout.length > 0 &&
								<>
									<h3 className="titulo-formulario">Tu nombre y apellido deben coincidir exactamente a los de tu tarjeta.</h3>
									<div className="input-with-icon-left">
										<i className="icon-material-outline-account-circle"></i>
										<input maxLength="200" onChange={updateNombre} type="text" className="input-text with-border" placeholder="Nombre" />
									</div>
									<div className="input-with-icon-left">
										<i className="icon-material-outline-account-circle"></i>
										<input maxLength="200" onChange={updateApellido} type="text" className="input-text with-border" placeholder="Apellido" />
									</div>
									<div className="input-with-icon-left">
										<i className="icon-material-baseline-mail-outline"></i>
										<input maxLength="100" onChange={updateEmail} className="input-text with-border" placeholder="Email" />
									</div>
									<div className="input-with-icon-left">
										<i className="icon-feather-phone"></i>
										<input onChange={updateTelefono} value={datosContacto.telefono} maxLength="50" className="input-text with-border" placeholder="Teléfono" />
									</div>
									<div className="checkbox seccion-newsletter">
										<input type="checkbox" value={newsletter} onChange={updateNewsletter} id="newsletter" />
										<label htmlFor="newsletter"><span className="checkbox-icon"></span>Quiero recibir noticias y actualizaciones de CIC Apoya en mi correo.</label>
									</div>
								</>
							}
							<a style={{ display: 'none' }} href="#small-dialog" className="datos-enviados-dialog"></a>
						</div>
					}
					{procesandoPago &&
						<div id="id-btn-pp"></div>
					}
				</div>
				<div className="seccion-botones">
					<div className="seguir-buscando-wrapper">
						<button onClick={() => { history.goBack() }} className="button full-width boton-enviar ripple-effect"><i className="icon-material-outline-arrow-back"></i>
								Seguir buscando</button>
					</div>
					{!procesandoPago && props.necesidadesCheckout && props.necesidadesCheckout.length > 0 &&
						<div className="pp-btn-seccion-wrapper">
							<h3>Los datos de tu tarjeta serán procesados por PayPal.</h3>
							<button onClick={() => { initCheckout() }} className="button boton-enviar"><i className="icon-material-outline-credit-card"></i>Proporcionar datos de tarjeta </button>
						</div>
					}
					{procesandoPago &&
						<div className="pp-btn-seccion-wrapper">
							<button type="submit" id="continueButton" className="button boton-enviar" onClick={() => { continuePaypal() }}>
								Continuar
							</button>
						</div>
					}
				</div>
			</div>
		</React.Fragment>
	);
}

export default CheckoutDatos;
