import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import MiNecesidadItem from "./MiNecesidadItem";
import Pager from "../common/Pager";
import Loader from "react-loader-spinner";
import { cicApiUrl } from "../common/share-util";

import './MisNecesidades.scss';
import CrearTestimonioDialog from "../testimonios/dialogs/CrearTestimonioDialog";

function MisNecesidades(props) {
	const [loading, setLoading] = useState(false);
	const [necesidades, setNecesidades] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [soloCerradas, setSoloCerradas] = useState(false);
	const [soloEfectivo, setSoloEfectivo] = useState(false);
	const [sinTestimonio, setSinTestimonio] = useState(false);
	const [necesidadesFiltradas, setNecesidadesFiltradas] = useState([]);
	const [necesidadesPage, setNecesidadesPage] = useState([]);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [necesidadTestimonioDialog, setNecesidadTestimonioDialog] = useState({});
	const pageSize = 25;
	const maxPageNumbers = 5;

	let history = useHistory();

	useEffect(() => {
		if (!props.isLoggedIn) {
			history.push('/necesidades');
		}
	});

	useEffect(() => {
		if (props.isLoggedIn) {
			let token = sessionStorage.getItem('token-apoya');
			if (token) {
				setLoading(true);
				window.$.post(cicApiUrl + 'api/Needs/getMyNeeds', {
					"token": token
				}, (data, status) => {
					setLoading(false);
					if (status === 'success' && data && data.length > 0) {
						setNecesidades(data);
						setNecesidadesFiltradas(data);
					} else {
						if (data && data.length === 0) {
							setNecesidades([]);
							setNecesidadesFiltradas([]);
						} else {
							console.log('error al obtener necesidades', status);
						}
					}
				});
			}
			window.$('.abrir-crear-testimonio').magnificPopup({
				type: 'inline',

				fixedContentPos: false,
				fixedBgPos: true,

				overflowY: 'auto',

				closeBtnInside: true,
				preloader: false,

				midClick: true,
				removalDelay: 300,
				mainClass: 'my-mfp-zoom-in',
				closeMarkup: '<button title="%title%" type="button" class="mfp-close necesidad-publicada-close"></button>',
				callbacks: {
					close: function(){
						setNecesidadTestimonioDialog({});
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		handleChangePage(0);
	}, [necesidades]);

	useEffect(() => {
		handleChangePage(0);
	}, [necesidadesFiltradas]);

	useEffect(() => {
		let necesidadesFiltradas = necesidades.filter((necesidad) => {
			if (necesidad.ticket.toUpperCase().includes(searchKeyword.toUpperCase())) return true;
			if ((necesidad.title || '').toUpperCase().includes(searchKeyword.toUpperCase())) return true;
			if ((necesidad.description || '').toUpperCase().includes(searchKeyword.toUpperCase())) return true;
			if ((necesidad.ticketCic || '').toUpperCase().includes(searchKeyword.toUpperCase())) return true;

			return false;
		});
		if (soloCerradas) {
			necesidadesFiltradas = necesidadesFiltradas.filter((necesidad) => {
				if (necesidad.status === 'cerrado' || necesidad.status === 'en proceso') return true;
			});
		}
		if (soloEfectivo) {
			necesidadesFiltradas = necesidadesFiltradas.filter((necesidad) => {
				if (necesidad.tipoDonacionRecibida === 'Efectivo') return true;
			});
		}
		if (sinTestimonio) {
			necesidadesFiltradas = necesidadesFiltradas.filter((necesidad) => {
				if (!necesidad.testimony) return true;
			});
		}
		setNecesidadesFiltradas(necesidadesFiltradas);
	}, [searchKeyword, soloCerradas, sinTestimonio, soloEfectivo]);

	/**
	 * https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
	 * user: Xavier John
	 */
	function exportToCsv(filename, necesidades) {
		if (!necesidades || necesidades.length === 0) {
			return;
		}
		let rows = [['Ticket', 'Título', 'Descripción', 'Categoría', 'Dirección', 'Coordenadas', 'Requisitos',
			'Estado', 'Municipio', 'Fecha requerida', '#Contactos', 'Días restantes', 'Fecha creación', 'Nombre Usuario',
			'Email Usuario', 'Tel/cel Usuario', 'Dirección Usuario', 'Organización Usuario', 'Email usuario validado']];
		for (let i = 0; i < necesidades.length; i++) {
			rows.push([
				necesidades[i].ticket, necesidades[i].title, necesidades[i].description, necesidades[i].categoryName, necesidades[i].address,
				necesidades[i].geoRef.coordinates[1] + ', ' + necesidades[i].geoRef.coordinates[0], necesidades[i].requirements,
				necesidades[i].state, necesidades[i].municipality, necesidades[i].requiredDate, necesidades[i].contactCount, necesidades[i].days,
				necesidades[i].createdAt, necesidades[i].userName, necesidades[i].userEmail, necesidades[i].userCel, necesidades[i].userAddress,
				necesidades[i].userOrganization, necesidades[i].userValidated
			]);
		}
		var processRow = function (row) {
			var finalVal = '';
			for (var j = 0; j < row.length; j++) {
				var innerValue = row[j] === null ? '' : row[j].toString();
				var result = innerValue.replace(/"/g, '""');
				if (result.search(/("|,|\n)/g) >= 0)
					result = '"' + result + '"';
				if (j > 0)
					finalVal += ',';
				finalVal += result;
			}
			return finalVal + '\n';
		};

		var csvFile = '';
		for (var i = 0; i < rows.length; i++) {
			csvFile += processRow(rows[i]);
		}

		var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
		if (navigator.msSaveBlob) { // IE 10+
			navigator.msSaveBlob(blob, filename);
		} else {
			var link = document.createElement("a");
			if (link.download !== undefined) { // feature detection
				// Browsers that support HTML5 download attribute
				var url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", filename);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	function handleChangePage(page) {
		let paginaNecesidades = necesidadesFiltradas.slice(page * pageSize, (page * pageSize) + pageSize);
		setNecesidadesPage(paginaNecesidades);
		setCurrentPage(page);
	}

	function handleChangeKeyword(event) {
		let value = event.target.value;
		setSearchKeyword(value);
	}

	function handleSoloCerradasChange(event) {
		setSoloCerradas(event.target.checked);
	}

	function handleSoloEfectivoChange(event) {
		setSoloEfectivo(event.target.checked);
	}

	function handleSinTestimonioChange(event) {
		setSinTestimonio(event.target.checked);
	}

	function handleOpenTestimonioDialog(necesidad) {
		setNecesidadTestimonioDialog(necesidad);
		window.$('.abrir-crear-testimonio').magnificPopup('open');
	}

	function handleUpdateTestimonioNecesidad(necesidadId){
		let necesidad = necesidades.find((necesidad) => {
			if(necesidad._id === necesidadId) return true;
		});
		necesidad.testimony = true;
	}
	return (
		<React.Fragment>
			<a style={{ display: 'none' }} href="#small-dialog-2" className=" button ripple-effect big margin-top-30 abrir-crear-testimonio"></a>
			<CrearTestimonioDialog onUpdateTestimonioNecesidad={handleUpdateTestimonioNecesidad} necesidad={necesidadTestimonioDialog}></CrearTestimonioDialog>
			<div className="mis-necesidades">
				<div className="controles-container">
					{(necesidades.length !== 0 && !loading) &&
						<input value={searchKeyword} onChange={handleChangeKeyword} className="buscar-necesidad-input" type="text" placeholder="Buscar"></input>
					}
					{
						necesidadesFiltradas.length > pageSize && <Pager currentPage={currentPage} pageSize={pageSize} maxPageNumbers={maxPageNumbers} elementos={necesidadesFiltradas} onPageChange={handleChangePage}></Pager>
					}
				</div>
				{props.iscic &&
					<div className="filtros-necesidades">
						<div className="checkbox">
							<input onChange={handleSoloCerradasChange} checked={soloCerradas} type="checkbox" id="solocerradas" />
							<label htmlFor="solocerradas"><span className="checkbox-icon"></span>Solo cerradas / en proceso</label>
						</div>
						<div className="checkbox">
							<input onChange={handleSinTestimonioChange} checked={sinTestimonio} type="checkbox" id="sintestimonio" />
							<label htmlFor="sintestimonio"><span className="checkbox-icon"></span>Sin testimonio</label>
						</div>
						<div className="checkbox">
							<input onChange={handleSoloEfectivoChange} checked={soloEfectivo} type="checkbox" id="soloefectivo" />
							<label htmlFor="soloefectivo"><span className="checkbox-icon"></span>Solo efectivo</label>
						</div>
					</div>
				}
				<div className="dashboard-content-inner" >
					{loading && <div style={{ position: 'fixed', top: 'calc(50vh - 100px)', left: 'calc(50vw - 100px)', zIndex: 999 }}>
						<Loader
							type="Grid"
							color="#2a41e8"
							height={200}
							width={200}
						/>
					</div>
					}
					{
						(necesidades.length === 0 && !loading) ?
							<div className="welcome-text">
								<h3>Aún no tienes necesidades publicadas.</h3>
							</div> : <>
								<div className="row">

									<div className="col-xl-12">
										<div className="dashboard-box margin-top-0">

											<div className="headline mis-necesidades-headline">
												<h3><i className="icon-material-outline-business-center"></i> Mis Necesidades</h3>
												<button onClick={() => { exportToCsv('necesidades.csv', necesidades) }} className="button ripple-effect">Descargar CSV</button>
											</div>

											<div className="content">
												<ul className="dashboard-box-list">
													{
														necesidadesPage.map((necesidad) => {
															return (<MiNecesidadItem iscic={props.iscic} key={necesidad._id} necesidad={necesidad} onOpenTestimonioDialog={handleOpenTestimonioDialog}>
															</MiNecesidadItem>)
														})
													}
												</ul>
											</div>
										</div>
									</div>

								</div>
								{
									necesidadesFiltradas.length > pageSize && <Pager currentPage={currentPage} pageSize={pageSize} maxPageNumbers={maxPageNumbers} elementos={necesidadesFiltradas} onPageChange={handleChangePage}></Pager>
								}
								<div className="dashboard-footer-spacer"></div>
							</>
					}
				</div>
			</div>
		</React.Fragment>
	);
}

export default MisNecesidades;
