import React from "react";
import { Link } from "react-router-dom";
import { cicApiUrl, isInt } from "../common/share-util";
import "./CarruselTestimonios.scss";

class CarruselTestimonios extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      testimonios: [],
      initializedCarousel: false
    };
  }

  componentDidMount() {
    window.$.get(cicApiUrl + 'api/Testimonies/getTestimonies', (data, status) => {
      if (status === 'success') {
        let testimoniosMapped = data.map((testimonio) => {
          if (!testimonio.image) {
            testimonio.image = 'https://apoyacic.s3.amazonaws.com/imagenes/alimentos_despensas.jpg';
          }
          return testimonio;
        });
        testimoniosMapped = testimoniosMapped.slice(0, 4);
        this.setState({ testimonios: testimoniosMapped });
      } else {
        console.log('error al obtener testimonios', status);
      }
    });
  }

  componentDidUpdate() {
    if (!this.state.initializedCarousel && this.state.testimonios && this.state.testimonios.length > 0) {
      this.setState({ initializedCarousel: true });
      window.$('.default-slick-carousel-testimonios').slick({
        infinite: true,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: false,
        arrows: false,
        adaptiveHeight: false,
        responsive: [
          {
            breakpoint: 1292,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              arrows: false
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              arrows: false
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: false
            }
          }
        ]
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="section carrusel-testimonios padding-top-65 full-width-carousel-fix">
          <div style={{ textAlign: 'center' }}>
            <div className="testimonios-titulo margin-top-0 margin-bottom-25">
              <h3>Testimonios</h3>
            </div>
          </div>

          <div style={{ margin: 'auto' }}>
            <div className="default-slick-carousel default-slick-carousel-testimonios">
              {
                this.state.testimonios.map((testimonio) => {
                  return (
                    <div key={testimonio.needId} className="job-listing freelancer">
                      <div className="job-listing-details">
                        {!testimonio.videoFlag && testimonio.picture && <div className="imagen-testimonio">
                          <img src={testimonio.picture} alt=""></img>
                        </div>
                        }
                        {testimonio.videoFlag && testimonio.video &&
                          <div style={{ height: '146px' }}>
                            <iframe className="apoya-yt" height="146px" width="100%" src={'https://www.youtube.com/embed/' + testimonio.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                          </div>
                        }
                        <div className="job-listing-description descripcion-testimonio">
                          <h3 className="job-listing-title">{testimonio.firstName.length < 60
                            ? testimonio.firstName : testimonio.firstName.substring(0, 60) + '...'
                          }</h3>
                          <span className="nombre-categoria">{testimonio.categoryName}</span>
                        </div>
                      </div>

                      <div className="job-listing-footer">
                        <div className="footer-container">
                          <div className="footer-item">
                            <div>Municipio</div>
                            <div>
                              <i className="icon-material-outline-location-on"></i> <span>{testimonio.municipality}</span>
                            </div>
                          </div>
                          <div className="footer-item">
                            <div>Monto</div>
                          ${testimonio.estimatedCost || 0} pesos
                        </div>
                          <div className="footer-item">
                            <div>Fecha</div>
                            {new Date(testimonio.createdAt).toLocaleDateString("es-MX")}
                          </div>
                          <div className="footer-item">
                            <div>Folio</div>
                            {testimonio.ticket}
                          </div>
                          {/* <div className="footer-item"> */}
                          {/* <Link to={'/necesidad/' + testimonio.needId} className="button button-sliding-icon ripple-effect">
                              Ver más
                             <i className="icon-material-outline-arrow-right-alt"></i></Link> */}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div>

            <Link to="/testimonios" className="col-xl-12 testimonios-inscribete">Ver todos los testimonios<i className="icon-material-outline-arrow-right-alt"></i></Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CarruselTestimonios;
