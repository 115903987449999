import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Contacto from "./Contacto";
import Loader from "react-loader-spinner";
import { cicApiUrl } from "../common/share-util";


import './Contactos.scss';

function Contactos(props) {
	const [loading, setLoading] = useState(false);
	const [contactos, setContactos] = useState([]);
	const [necesidadInfo, setNecesidadInfo] = useState({});

	let history = useHistory();
	let { necesidadId } = useParams();

	useEffect(() => {
		if (!props.isLoggedIn) {
			history.push('/necesidades');
		}
	});

	useEffect(() => {
		if (props.isLoggedIn) {
			let token = sessionStorage.getItem('token-apoya');
			if (token) {
				setLoading(true);
				window.$.post(cicApiUrl + 'api/Needs/getContactsByNeed', {
					"token": token,
					"need": necesidadId
				}, (data, status) => {
					setLoading(false);
					if (status === 'success' && data && data.contactos && data.contactos.length > 0) {
						setContactos(data.contactos);
						setNecesidadInfo(data.necesidadInfo);
					} else {
						if (data && data.length === 0) {
							setContactos([]);
						} else {
							console.log('error al obtener contactos', status);
						}
					}
				});
			}
		}
	}, []);

	return (
		<React.Fragment>
			<div className="contactos">
				<div className="dashboard-content-inner" >
					{loading && <div style={{ position: 'fixed', top: 'calc(50vh - 100px)', left: 'calc(50vw - 100px)', zIndex: 999 }}>
						<Loader
							type="Grid"
							color="#2a41e8"
							height={200}
							width={200}
						/>
					</div>
					}
					{
						(contactos.length === 0 && !loading) ?
							<>
								<span className="margin-top-7"><i className="icon-material-outline-arrow-back"></i>Volver a
						<Link to={'/misnecesidades'}>
										{' Mis Necesidades'}
									</Link></span>
								<div className="welcome-text">
									<h3>Esta necesidad aún no tiene contactos.</h3>
								</div> </> : <>
								{
								  !loading	&& <div className="dashboard-headline">
										<span className="margin-top-7">Contactos para
									<Link to={'/necesidad/' + necesidadId}>
												{' Folio: ' + necesidadInfo.ticket + ', Título: ' + necesidadInfo.titulo}
											</Link></span>
										<span className="margin-top-7"><i className="icon-material-outline-arrow-back"></i>Volver a
										<Link to={'/misnecesidades'}>
												{' Mis Necesidades'}
											</Link></span>
									</div>
								}
								<div className="row">

									<div className="col-xl-12">
										<div className="dashboard-box margin-top-0">

											<div className="headline">
												<h3><i className="icon-material-outline-business-center"></i> Contactos</h3>
											</div>

											<div className="content">
												<ul className="dashboard-box-list">
													{
														contactos.map((contacto) => {
															return (<Contacto key={contacto._id} contacto={contacto}>
															</Contacto>)
														})
													}
												</ul>
											</div>
										</div>
									</div>

								</div>
								<div className="dashboard-footer-spacer"></div>
							</>
					}
				</div>
			</div>
		</React.Fragment>
	);
}

export default Contactos;
