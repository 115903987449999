import React, { useEffect, useState } from "react";
import ItemTestimonio from "./ItemTestimonio";
import { useParams, useHistory, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl, isInt } from "../common/share-util";
import "./ListaTestimonios.scss";

function ListaTestimonios(props) {

  const [testimonios, setTestimonios] = useState([]);

  let history = useHistory();

  useEffect(() => {
    window.tippy('[data-tippy-placement]', {
      delay: 100,
      arrow: true,
      arrowType: 'sharp',
      size: 'regular',
      duration: 200,
      animation: 'shift-away',
      animateFill: true,
      theme: 'dark',
      distance: 10,
    });
  }, [testimonios]);

  useEffect(() => {
    window.$.get(cicApiUrl + 'api/Testimonies/getTestimonies', (data, status) => {
      if (status === 'success') {
        let testimoniosMapped = data.map((testimonio) => {
          if (!testimonio.image) {
            testimonio.image = 'https://apoyacic.s3.amazonaws.com/imagenes/alimentos_despensas.jpg';
          }
          return testimonio;
        });
        setTestimonios(testimoniosMapped);
      } else {
        console.log('error al obtener testimonios', status);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div>
        <div className="listings-container margin-top-35">
          <div style={{ width: '100%' }}><Link to={'/'}>
            <i className="icon-material-outline-arrow-back"></i>Volver a Inicio
                  </Link></div>{
            testimonios.map((testimonio) => {
              testimonio.description = testimonio.description || '';
              return <ItemTestimonio testimonio={testimonio} key={testimonio.needId} ></ItemTestimonio>
            }
            )}
        </div>
      </div>
    </React.Fragment>
  );

}

export default ListaTestimonios;