import React, { useEffect, useState } from "react";
import "./CrearTestimonioDialog.scss";
import Loader from "react-loader-spinner";
import { subirImagen, cicApiUrl } from "../../common/share-util";

function CrearTestimonioDialog(props) {

  const [loadingResponse, setLoadingResponse] = useState(false);
  const [errorRespuesta, setErrorRespuesta] = useState(false);
  const [imagenDataUri, setImagenDataUri] = useState('');
  const [imagenBlob, setImagenBlob] = useState(null);
  const [documentoDataUri, setDocumentoDataUri] = useState('');
  const [documentoBlob, setDocumentoBlob] = useState(null);
  const [documentoEsPDF, setDocumentoEsPDF] = useState(false);
  const [ytVideoId, setYtVideoId] = useState('');
  const [erroresValidacion, setErroresValidacion] = useState('');

  useEffect(() => {
    window.$("a.close").removeAttr("href").on('click', function () {
      function slideFade(elem) {
        var fadeOut = { opacity: 0, transition: 'opacity 0.5s' };
        elem.css(fadeOut).slideUp();
      }
      slideFade(window.$(this).parent());
    });
    window.$('.datos-enviados-link').magnificPopup({
      type: 'inline',

      fixedContentPos: false,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close necesidad-publicada-close"></button>',
      callbacks: {
        close: function () {
        }
      }
    });
    window.$('.datos-enviados-error-link').magnificPopup({
      type: 'inline',

      fixedContentPos: false,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close necesidad-publicada-close"></button>',
      callbacks: {
        close: function () {
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!props.necesidad || !props.necesidad.ticket) {
      setImagenDataUri('');
      setImagenBlob(null);
      setDocumentoDataUri('');
      setDocumentoBlob(null);
      setDocumentoEsPDF(false);
      setYtVideoId('');
      setErroresValidacion('');
      setLoadingResponse(false);
      setErrorRespuesta(false);
    }
  }, [props.necesidad]);

  function fileChangeImagen(event) {
    let targetElement = event.target;
    if (targetElement.files) {
      for (let i = 0; i < targetElement.files.length; i++) {
        if (targetElement.files[i].type.includes('image/')) {
          let reader = new FileReader();
          setImagenBlob(targetElement.files[i]);
          reader.readAsDataURL(targetElement.files[i]);
          reader.onload = (event) => {
            setImagenDataUri(reader.result);
          }
        }
      }
    }
  }

  function fileChangeDocumento(event) {
    let targetElement = event.target;
    if (targetElement.files) {
      for (let i = 0; i < targetElement.files.length; i++) {
        if (targetElement.files[i].type.includes('image/') || targetElement.files[i].type.includes('pdf')) {
          if (targetElement.files[i].type.includes('pdf')) {
            setDocumentoEsPDF(true);
          } else {
            setDocumentoEsPDF(false);
          }
          let reader = new FileReader();
          setDocumentoBlob(targetElement.files[i]);
          reader.readAsDataURL(targetElement.files[i]);
          reader.onload = (event) => {
            setDocumentoDataUri(reader.result);
          }
        }
      }
    }
  }

  function quitarImagen() {
    setImagenDataUri('');
    setImagenBlob(null);
  }

  function quitarDocumento() {
    setDocumentoDataUri('');
    setDocumentoBlob(null);
    setDocumentoEsPDF(false);
  }

  function updateYtVideoId(event) {
    let input = event.target.value;
    let videoId = youtube_parser(input);
    if (!videoId) {
      videoId = input;
    }
    setYtVideoId(videoId);
  }

  function crearTestimonio() {
    document.getElementById('errores-validacion').removeAttribute('style');
    let mensajeError = validarInput();
    if (!mensajeError) {
      setLoadingResponse(true);
      subirImagen('apoyacic/testimonios', documentoBlob, (error, documentoS3Url) => {
        if (!error) {
          if (!ytVideoId && imagenBlob) {
            subirImagen('apoyacic/testimonios', imagenBlob, (error, imagenS3Url) => {
              if (!error) {
                enviarDatos(imagenS3Url, documentoS3Url);
              }
            });
          } else if (ytVideoId) {
            enviarDatos('', documentoS3Url);
          }
        }
      });
    } else {
      setErroresValidacion(mensajeError);
    }
  }

  function enviarDatos(imagenS3Url, documentoS3Url) {
    let payloadData = JSON.stringify({
      ticket: props.necesidad.ticket,
      picture: imagenS3Url || '',
      ine: documentoS3Url || '',
      video: ytVideoId || '',
      videoFlag: ytVideoId ? true : false,
      token: sessionStorage.getItem('token-apoya')
    });
    window.$.ajax({
      type: 'POST', url: cicApiUrl + 'api/Testimonies/create', data: payloadData, success: (data, status) => {
        window.$('.abrir-crear-testimonio').magnificPopup('close');
        setLoadingResponse(false);
        if (status === 'success' && data && data.status === 'OK') {
          setImagenDataUri('');
          setImagenBlob(null);
          setDocumentoDataUri('');
          setDocumentoBlob(null);
          setDocumentoEsPDF(false);
          setYtVideoId('');
          setErroresValidacion('');
          setLoadingResponse(false);
          props.onUpdateTestimonioNecesidad(props.necesidad._id);
          setTimeout(() => {
            window.$('.datos-enviados-link').magnificPopup('open');
          }, 500);
        } else {
          setImagenDataUri('');
          setImagenBlob(null);
          setDocumentoDataUri('');
          setDocumentoBlob(null);
          setDocumentoEsPDF(false);
          setYtVideoId('');
          setErroresValidacion('');
          setLoadingResponse(false);
          setErrorRespuesta(true);
          setTimeout(() => {
            window.$('.datos-enviados-error-link').magnificPopup('open');
          }, 500);
        }
      }, contentType: 'application/json'
    });
  }

  function validarInput() {
    let mensajeError = '';
    if (!imagenBlob && !ytVideoId) {
      mensajeError = 'Se requiere una imagen o video de youtube.';
    }
    if (!documentoBlob) {
      if (mensajeError) {
        mensajeError += ' ';
      }
      mensajeError += 'Se requiere identificación del donatario.';
    }
    return mensajeError;
  }

  /**
   * https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
   * user: webstrap. Dec 16 '11 at 17:14 
   */
  function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\??v?=?))([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[7] && match[7].length === 11) ? match[7] : false;
  }

  return (
    <>
      <a style={{ display: 'none' }} href="#small-dialog" className="datos-enviados-link"></a>
      <div id="small-dialog" className="zoom-anim-dialog mfp-hide dialog-with-tabs">
        <div className="sign-in-form">
          <div className="popup-tab-content">
            <div className="welcome-text">
              <h3>El testimonio para esta necesidad ha sido creado</h3>
            </div>
          </div>
        </div>
      </div>
      <a style={{ display: 'none' }} href="#small-dialog-4" className="datos-enviados-error-link"></a>
      <div id="small-dialog-4" className="zoom-anim-dialog mfp-hide dialog-with-tabs">
        <div className="sign-in-form">
          <div className="popup-tab-content">
            <div className="welcome-text">
              <h3>Hubo un error al crear el testimonio.</h3>
            </div>
          </div>
        </div>
      </div>
      <div id="small-dialog-2" className="zoom-anim-dialog mfp-hide dialog-with-tabs crear-testimonio-dialog">
        {loadingResponse && <div className="datos-enviados-loading">
          <Loader
            type="Grid"
            color="#2a41e8"
            height={200}
            width={200}
          />
        </div>
        }
        <div className="crear-testimonio-form">

          <div className="popup-tabs-container">

            <div className="popup-tab-content">

              <div className="welcome-text">
                <h3>Crear testimonio</h3>
                <span>Para necesidad: {props.necesidad.ticket}</span>
              </div>

              <div id="errores-validacion" className={'notification error closeable ' + (!erroresValidacion ? 'sin-errores' : '')}>
                <p>{erroresValidacion}</p>
                <a className="close" href="#"></a>
              </div>
              {!imagenBlob &&
                <div className="uploadButton">
                  <input className="uploadButton-input" type="file" accept="image/*" id="upload" onChange={fileChangeImagen} />
                  <label className="uploadButton-button ripple-effect" htmlFor="upload">Subir imagen</label>
                  <span className="uploadButton-file-name">Imagen del testimonio.</span>
                </div>
              }
              {(imagenBlob && imagenDataUri) &&
                <>
                  <h5>Imagen seleccionada</h5>
                  <img className="imagen-testimonio-preview" src={imagenDataUri}></img>
                  <span onClick={quitarImagen} style={{ height: '42px', marginLeft: '10px', marginTop: '20px' }} className="button dark quitar-img-btn ripple-effect">
                    Quitar imagen
                  </span>
                </>
              }
              <div>
                O
              </div>
              <div className="video-id-input">
                <input type="text" value={ytVideoId} onChange={updateYtVideoId} className="input-text with-border" placeholder="Video ID Youtube" />
              </div>
              <div className="separador-crear-testimonio">
              </div>
              {!documentoBlob &&
                <div className="uploadButton">
                  <input className="uploadButton-input" type="file" accept="image/*,.pdf" id="upload-doc" onChange={fileChangeDocumento} />
                  <label className="uploadButton-button ripple-effect" htmlFor="upload-doc">Subir documento</label>
                  <span className="uploadButton-file-name">Identificación del donatario. (Imagen o PDF)</span>
                </div>
              }
              {(documentoBlob && documentoDataUri) &&
                <>
                  <h5 style={{marginBottom: '10px'}}>Documento seleccionado</h5>
                  {!documentoEsPDF && <img className="imagen-testimonio-preview" src={documentoDataUri}></img>}
                  {documentoEsPDF && <span className="pdf-preview">PDF</span>}
                  <span onClick={quitarDocumento} style={{ height: '42px', marginLeft: '10px', marginTop: '20px' }} className="button dark quitar-img-btn ripple-effect">
                    Quitar documento
                  </span>
                </>
              }
              {!loadingResponse &&
                <button onClick={() => { crearTestimonio() }} className="button full-width button-sliding-icon ripple-effect">Crear <i className="icon-material-outline-arrow-right-alt"></i></button>
              }

            </div>

          </div>
        </div>
      </div>
    </>
  );

}

export default CrearTestimonioDialog;