import React, { useEffect, useState } from "react";
import './ContactosAbiertosTable.scss';
import $ from 'jquery';
import dt from 'datatables.net-dt';
import dtResponsive from 'datatables.net-responsive-dt';
import dtButtons from 'datatables.net-buttons-dt';
import dtButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';

function ContactosAbiertosTable(props) {

    useEffect(() => {
        let contactosAbiertos = props.data.map((value, i, arr) => {
            let categorias = [];
            if (value.interestedInCategories) {
                categorias = value.interestedInCategories.map((value, i, arr) => {
                    return value.name
                });
            }
            let contactoAbierto = [value.name || '', value.organizationName || '', value.description || '', value.email || '', value.money || false,
            value.cel || '', value.createdAt, categorias.toString(), null];
            return contactoAbierto;
        });
        dt.$('#contactos-abiertos-table').DataTable({
            data: contactosAbiertos,
            columns: [
                {
                    title: "Nombre",
                    width: "10%"
                },
                {
                    title: "Organización",
                    width: "10%"
                },
                {
                    title: "Descripción",
                    width: "25%"
                },
                {
                    title: "Email",
                    width: "5%"
                },
                {
                    title: "Unir y Dar",
                    width: "5%"
                },
                {
                    title: "Tel/cel",
                    width: "10%"
                },
                {
                    title: "Fecha",
                    width: "10%"
                },
                {
                    title: "Categorías",
                    width: "10%"
                },
                {
                    className: "control",
                    width: "10%"
                }
            ],
            order: [[6, 'desc']],
            responsive: {
                details: {
                    type: 'column',
                    target: -1
                }
            },
            dom: 'lfrtip<"contactos-abiertos-clear">B',
            buttons: [{
                extend: 'csv',
                text: 'Descargar CSV'
            }]
        });
        return () => { dt.$('#contactos-abiertos-table').DataTable().destroy() };
    }, []);

    return (
        <table id="contactos-abiertos-table" className="display compact stripe" width="100%"></table>
    )
}

export default ContactosAbiertosTable;
