import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl } from "../common/share-util";


import './TodosContactos.scss';
import TodosContactosTable from "./TodosContactosTable";

function TodosContactos(props) {
	const [loading, setLoading] = useState(false);
	const [todosContactos, setTodosContactos] = useState([]);

	let history = useHistory();

	useEffect(() => {
		if (!props.isLoggedIn) {
			history.push('/');
		}
	});

	useEffect(() => {
		if (props.isLoggedIn) {
			let token = sessionStorage.getItem('token-apoya');
			if (token) {
				setLoading(true);
				window.$.post(cicApiUrl + 'api/Contacts/getAllContacts', {
					"token": token
				}, (data, status) => {
					setLoading(false);
					if (status === 'success' && data && data.contactos && data.contactos.length > 0) {
						setTodosContactos(data.contactos);
					} else {
						if (data && data.contactos && data.contactos.length === 0) {
							setTodosContactos([]);
						} else {
							console.log('error al obtener contactos', status);
						}
					}
				});
			}
		}
	}, []);

	return (
		<React.Fragment>
			<div className="todos-contactos">
				<div className="dashboard-content-inner" >
					{loading && <div style={{ position: 'fixed', top: 'calc(50vh - 100px)', left: 'calc(50vw - 100px)', zIndex: 999 }}>
						<Loader
							type="Grid"
							color="#2a41e8"
							height={200}
							width={200}
						/>
					</div>
					}
					{
						(todosContactos.length === 0 && !loading) ?
							<>
								<div className="welcome-text">
									<h3>Aún no hay contactos.</h3>
								</div> </> : <>
								<TodosContactosTable data={todosContactos}></TodosContactosTable>
								<div className="dashboard-footer-spacer"></div>
							</>
					}
				</div>
			</div>
		</React.Fragment>
	);
}

export default TodosContactos;
