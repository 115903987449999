import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { cicApiUrl, isInt } from "../common/share-util";
import './BolsaApoyaDolares.scss';

function BolsaApoyaDolares(props) {
	const [montoDonacion, setMontoDonacion] = useState(200);
	const [errorMontoDonacion, setErrorMontoDonacion] = useState(false);
	const [isPoolUrl, setIsPoolUrl] = useState(false);
	let history = useHistory();

	useEffect(() => {
		window.$('.slick-carousel-bolsa-apoya').slick({
			infinite: true,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			speed: 2000,
			autoplaySpeed: 4000,
			arrows: false,
			fade: true,
			adaptiveHeight: false
		});
		if (window.location.pathname === '/pool') {
			setIsPoolUrl(true);
			setMontoDonacion(28);
		}
	}, []);

	function checkoutNecesidad(monto) {
		if (isPoolUrl) {
			props.onSetModoDolares();
		}
		props.onSeleccionarNecesidadCheckout({
			ticket: '0000000',
			categoryName: 'Necesidades prioritarias',
			costoEstimado: monto
		});
	}

	function actualizarMontoDonacion(event) {
		let value = event.target.value;
		if (isInt(value) || value === '') {
			setMontoDonacion(value);
		}
	}

	function donarAhoraCheckout() {
		let minDonacion = isPoolUrl ? 28 : 50;
		if (montoDonacion >= minDonacion) {
			let monto = parseInt(montoDonacion);
			checkoutNecesidad(monto);
			history.push('/datoscheckout');
		} else {
			setErrorMontoDonacion(true);
		}
	}

	return (
		<div className="bolsa-apoya-dolares-wrapper">
			<div className="bolsa-apoya">
				<div>
					{isPoolUrl &&
						<div className="descripcion-bolsa-apoya">
							<h1>
								Éntrale a la Bolsa ApoYA Paisano
							</h1>
							<h3>
								Aunque estés lejos sabemos que tu corazón está en México.  ApoYA hoy a tus hermanos mexicanos desde 28 dólares.
							</h3>
							<div>
								Durante la contingencia sanitaria provocada por el Covid 19, muchas personas lo han perdido todo, familiares, su hogar, sus empleos, su estabilidad económica, hoy tu puedes ayudarlos con una pequeña contribución.
							</div>
							<div>
								Los donativos registrados en nuestra bolsa, estarán destinados a cubrir necesidades de ciudadanos neoloneses que hoy nos necesitan más que nunca, puedes donar la cantidad que tu desees desde 28 dólares monto establecido para la donación de una despensa.
							</div>
							<div>
							Para conocer los casos y necesidades registradas en nuestra plataforma puedes ingresar a: <a href="https://apoya.cic.mx/" target="_blank">https://apoya.cic.mx/</a>
							</div>
							<div>Esta es una iniciativa del Centro de Integración Ciudadana A.C. <a href="https://cic.mx/" target="_blank">https://cic.mx/</a> que tiene 9 años trabajando para promover la participación ciudadana en Nuevo León.</div>
							<div>Súmate a esta iniciativa de Ciudadano a Ciudadano.</div>
						</div>
					}
					<div className="donaciones-block">
						<div className="donaciones-border-block">
							{isPoolUrl &&
								<div>
									<Link className="boton-donacion button" to="/datoscheckout" onClick={() => { checkoutNecesidad(28) }}>$28</Link>
									<Link className="boton-donacion-2 button" to="/datoscheckout" onClick={() => { checkoutNecesidad(50) }}>$50</Link>
									<Link className="boton-donacion button" to="/datoscheckout" onClick={() => { checkoutNecesidad(100) }}>$100</Link>
									<Link className="boton-donacion-2 button" to="/datoscheckout" onClick={() => { checkoutNecesidad(150) }}>$150</Link>
								</div>
							}
							<div className="donar-ahora-wrapper">
								{errorMontoDonacion && <div className="error-monto-mensaje">El monto mínimo son ${isPoolUrl ? '28' : '50'}</div>}
								<div className="otra-cantidad-label" style={{ marginBottom: '10px' }}>Otra cantidad:</div>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<span className="signo-moneda-label">$</span><input type="number" min={isPoolUrl ? '28' : '50'} step="1" value={montoDonacion} onChange={(event) => { actualizarMontoDonacion(event) }} className={'input-monto-donacion ' + (errorMontoDonacion ? 'error-monto' : '')}>
									</input>
									<button onClick={() => { donarAhoraCheckout() }} className="boton-donar-ahora">Donar ahora</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="default-slick-carousel slick-carousel-bolsa-apoya freelancers-container freelancers-grid-layout">

					<div className="freelancer">
						<div className="freelancer-overview">
							<div className="freelancer-overview-inner">
								<div className="freelancer-avatar">
									<img src="https://apoyacic.s3.amazonaws.com/imagenes/imagenes_bolsa_apoya_1.jpg" alt="" />
								</div>
							</div>
						</div>
					</div>
					<div className="freelancer">
						<div className="freelancer-overview">
							<div className="freelancer-overview-inner">
								<div className="freelancer-avatar">
									<img src="https://apoyacic.s3.amazonaws.com/imagenes/imagenes_bolsa_apoya_2.jpg" alt="" />
								</div>
							</div>
						</div>
					</div>
					<div className="freelancer">
						<div className="freelancer-overview">
							<div className="freelancer-overview-inner">
								<div className="freelancer-avatar">
									<img src="https://apoyacic.s3.amazonaws.com/imagenes/imagenes_bolsa_apoya_3.jpg" alt="" />
								</div>
							</div>
						</div>
					</div>
					<div className="freelancer">
						<div className="freelancer-overview">
							<div className="freelancer-overview-inner">
								<div className="freelancer-avatar">
									<img src="https://apoyacic.s3.amazonaws.com/imagenes/imagenes_bolsa_apoya_4.jpg" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="donaciones-block donaciones-block-mobile">
				<div className="donaciones-border-block">
					{isPoolUrl &&
						<div>
							<Link className="boton-donacion button" to="/datoscheckout" onClick={() => { checkoutNecesidad(28) }}>$28</Link>
							<Link className="boton-donacion-2 button" to="/datoscheckout" onClick={() => { checkoutNecesidad(50) }}>$50</Link>
							<Link className="boton-donacion button" to="/datoscheckout" onClick={() => { checkoutNecesidad(100) }}>$100</Link>
							<Link className="boton-donacion-2 button" to="/datoscheckout" onClick={() => { checkoutNecesidad(150) }}>$150</Link>
						</div>
					}
					<div className="donar-ahora-wrapper">
						{errorMontoDonacion && <div className="error-monto-mensaje">El monto mínimo son ${isPoolUrl ? '28' : '50'}</div>}
						<div className="otra-cantidad-label" style={{ marginBottom: '10px' }}>Otra cantidad:</div>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<span className="signo-moneda-label">$</span><input type="number" min={isPoolUrl ? '28' : '50'} step="1" value={montoDonacion} onChange={(event) => { actualizarMontoDonacion(event) }} className={'input-monto-donacion ' + (errorMontoDonacion ? 'error-monto' : '')}>
							</input>
							<button onClick={() => { donarAhoraCheckout() }} className="boton-donar-ahora">Donar ahora</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BolsaApoyaDolares;
