import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { cicApiUrl, isInt } from "../common/share-util";
import './BolsaApoya.scss';

function BolsaApoya(props) {
	const [montoDonacion, setMontoDonacion] = useState(200);
	const [errorMontoDonacion, setErrorMontoDonacion] = useState(false);
	const [isPoolUrl, setIsPoolUrl] = useState(false);
	let history = useHistory();

	useEffect(() => {
		window.$('.slick-carousel-bolsa-apoya').slick({
			infinite: true,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			speed: 2000,
			autoplaySpeed: 4000,
			arrows: false,
			fade: true,
			adaptiveHeight: false
		});
		if (window.location.pathname === '/pool') {
			setIsPoolUrl(true);
			setMontoDonacion(28);
		}
	}, []);

	function checkoutNecesidad(monto) {
		if (isPoolUrl) {
			props.onSetModoDolares();
		}
		props.onSeleccionarNecesidadCheckout({
			ticket: '0000000',
			categoryName: 'Necesidades prioritarias',
			costoEstimado: monto
		});
		window.fbq('track', 'AddToCart');
	}

	function actualizarMontoDonacion(event) {
		let value = event.target.value;
		if (isInt(value) || value === '') {
			setMontoDonacion(value);
		}
	}

	function donarAhoraCheckout() {
		let minDonacion = isPoolUrl ? 28 : 50;
		if (montoDonacion >= minDonacion) {
			let monto = parseInt(montoDonacion);
			checkoutNecesidad(monto);
			history.push('/datoscheckout');
		} else {
			setErrorMontoDonacion(true);
		}
		window.fbq('track', 'AddToCart');
	}

	return (
		<div className="bolsa-apoya-wrapper">
			<div className="bolsa-apoya">
				<div>
					<div className="descripcion-bolsa-apoya">
						<h1>
							Éntrale a la <span className="marcador-amarillo">Bolsa ApoYA</span>
						</h1>
						<h3 className="subtitulo-bolsa-apoya">
							De ciudadano a ciudadano la ayuda a quien la necesita, la cantidad que tu desees aportar marcará la diferencia.
							</h3>
						<div className="simple-con-bold">
							Sabemos que puede ser complicado aportar el monto total de las necesidades publicadas, pero que esto no impida ayudar a quien realmente lo necesita.
							</div>
						<div>
							Por ello ponemos a tu disposición nuestra bolsa, destinada a cubrir necesidades de ciudadanos que hoy nos necesitan, puedes donar la cantidad que tu desees. Cuando
							se junte una cantidad considerable se destinará para cubrir necesidades prioritarias.
							</div>
						<div className="donaciones-block">
							<div className="donaciones-border-block">
								{!isPoolUrl &&
									<div>
										<Link className="boton-donacion button" to="/datoscheckout" onClick={() => { checkoutNecesidad(50) }}>$50</Link>
										<Link className="boton-donacion-2 button" to="/datoscheckout" onClick={() => { checkoutNecesidad(100) }}>$100</Link>
										<Link className="boton-donacion button" to="/datoscheckout" onClick={() => { checkoutNecesidad(200) }}>$200</Link>
										<Link className="boton-donacion-2 button" to="/datoscheckout" onClick={() => { checkoutNecesidad(500) }}>$500</Link>
									</div>
								}
								<div className="donar-ahora-wrapper">
									{errorMontoDonacion && <div className="error-monto-mensaje">El monto mínimo son ${isPoolUrl ? '28' : '50'}</div>}
									<div className="otra-cantidad-label" style={{ marginBottom: '10px' }}>Otra cantidad:</div>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<span className="signo-moneda-label">$</span><input type="number" min={isPoolUrl ? '28' : '50'} step="1" value={montoDonacion} onChange={(event) => { actualizarMontoDonacion(event) }} className={'input-monto-donacion ' + (errorMontoDonacion ? 'error-monto' : '')}>
										</input>
										<button onClick={() => { donarAhoraCheckout() }} className="boton-donar-ahora">Donar ahora</button>
									</div>
								</div>
							</div>
						</div>
						<div className="como-funciona-block">
							<h2>
								<span className="marcador-amarillo">¿Cómo funciona?</span>
							</h2>
							<ul>
								<li>
									El monto obtenido en la Bolsa será destinado a cubrir las necesidades consideradas como prioritarias por el equipo CIC.
								</li>
								<li>
									Puedes solicitar un recibo deducible a partir de un donativo.
								</li>
								<li>Selecciona o registra la cantidad que deseas donar y da clic en Donar ahora.</li>
								<li>
									Si tienes algún problema o tienes alguna duda, puedes escribirnos en cualquiera de nuestras redes sociales.
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div>
					<div className="default-slick-carousel slick-carousel-bolsa-apoya freelancers-container freelancers-grid-layout">

						<div className="freelancer">
							<div className="freelancer-overview">
								<div className="freelancer-overview-inner">
									<div className="freelancer-avatar">
										<img src="https://apoyacic.s3.amazonaws.com/imagenes/imagenes_bolsa_apoya_1.jpg" alt="" />
									</div>
								</div>
							</div>
						</div>
						<div className="freelancer">
							<div className="freelancer-overview">
								<div className="freelancer-overview-inner">
									<div className="freelancer-avatar">
										<img src="https://apoyacic.s3.amazonaws.com/imagenes/imagenes_bolsa_apoya_2.jpg" alt="" />
									</div>
								</div>
							</div>
						</div>
						<div className="freelancer">
							<div className="freelancer-overview">
								<div className="freelancer-overview-inner">
									<div className="freelancer-avatar">
										<img src="https://apoyacic.s3.amazonaws.com/imagenes/imagenes_bolsa_apoya_3.jpg" alt="" />
									</div>
								</div>
							</div>
						</div>
						<div className="freelancer">
							<div className="freelancer-overview">
								<div className="freelancer-overview-inner">
									<div className="freelancer-avatar">
										<img src="https://apoyacic.s3.amazonaws.com/imagenes/imagenes_bolsa_apoya_4.jpg" alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="preview-post-medium-50-pesos">
						<div>
							Leer más en...
						</div>
						<a className="preview-post-titulo" target="_blank" href="https://medium.com/@apoyacic/plataforma-apoya-habilita-donaci%C3%B3n-desde-50-pesos-3141b42eb0fb">
							Plataforma ApoYA habilita donación desde 50 pesos
						</a>
						<a target="_blank" href="https://medium.com/@apoyacic/plataforma-apoya-habilita-donaci%C3%B3n-desde-50-pesos-3141b42eb0fb">
							<img src="https://apoyacic.s3.amazonaws.com/imagenes/thumbnail-apoya-50-pesos.png"></img>
						</a>
					</div>
				</div>
			</div>
			<div className="como-funciona-block-mobile">
				<h2>
					<span className="marcador-amarillo">¿Cómo funciona?</span>
				</h2>
				<ul>
					<li>
						El monto obtenido en la Bolsa será destinado a cubrir las necesidades consideradas como prioritarias por el equipo CIC.
								</li>
					<li>
						Puedes solicitar un recibo deducible a partir de un donativo.
								</li>
					<li>Selecciona o registra la cantidad que deseas donar y da clic en Donar ahora.</li>
					<li>
						Si tienes algún problema o tienes alguna duda, puedes escribirnos en cualquiera de nuestras redes sociales.
								</li>
				</ul>
			</div>
		</div>
	);
}

export default BolsaApoya;
