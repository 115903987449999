import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { cicApiUrl } from "../common/share-util";


import './EstablecerContrasena.scss';

function EstablecerContrasena(props) {
	let history = useHistory();

	useEffect(() => {
		if (!props.loginTemp && !datosContrasena.contrasenaEstablecida) {
			history.push('/');
		}
	});
	const [datosContrasena, setDatosContrasena] = useState({
		contrasena: '',
		contrasena2: '',
		erroresValidacion: '',
		contrasenaEstablecida: false
	});

	useEffect(() => {
		window.$("a.close").removeAttr("href").on('click', function () {
			function slideFade(elem) {
				var fadeOut = { opacity: 0, transition: 'opacity 0.5s' };
				elem.css(fadeOut).slideUp();
			}
			slideFade(window.$(this).parent());
		});
	}, []);

	function updateContrasena(event) {
		let value = event.target.value;
		setDatosContrasena(datosAnteriores => { return { ...datosAnteriores, ...{ contrasena: value } } });
	}

	function updateContrasena2(event) {
		let value = event.target.value;
		setDatosContrasena(datosAnteriores => { return { ...datosAnteriores, ...{ contrasena2: value } } });
	}

	function guardarContrasena() {
		document.getElementById('errores-validacion').removeAttribute('style');
		let mensajeError = validarDatos();
		if (mensajeError) {
			window.$('html, body').animate({ scrollTop: 0 }, 500);
			setDatosContrasena(datosAnteriores => { return { ...datosAnteriores, ...{ erroresValidacion: mensajeError } } });
		} else {
			let payloadData = JSON.stringify({
				"t": props.loginTemp,
				"email": props.loginTempEmail,
				"password": datosContrasena.contrasena
			});
			window.$.ajax({
				type: 'POST', url: cicApiUrl + 'api/Users/setpassword', data: payloadData, success: (data, status) => {
					if (status === 'success' && data && data.status === 'OK') {
						setDatosContrasena(datosAnteriores => { return { ...datosAnteriores, ...{ contrasenaEstablecida: true } } });
						props.onEstablecioContrasenaChange(true);
					} else {
						window.$('html, body').animate({ scrollTop: 0 }, 500);
						setDatosContrasena(datosAnteriores => { return { ...datosAnteriores, ...{ erroresValidacion: 'Hubo un error al establecer la contraseña.' } } });
					}
				}, contentType: 'application/json'
			});
		}
	}

	function validarDatos() {
		let mensajeError = '';
		if (!datosContrasena.contrasena || (datosContrasena.contrasena !== datosContrasena.contrasena2)) {
			mensajeError = 'Las contraseñas no coinciden, o no son válidas.';
		}
		return mensajeError;
	}

	return (
		<React.Fragment>
			<div className="establecer-contrasena">
				{!datosContrasena.contrasenaEstablecida && <>
					<div className="welcome-text">
						<h3>Establece una contraseña para tu cuenta.</h3>
					</div>

					<div id="errores-validacion" className={'notification error closeable ' + (!datosContrasena.erroresValidacion ? 'sin-errores' : '')}>
						<p>{datosContrasena.erroresValidacion}</p>
						<a className="close" href="#"></a>
					</div>

					<div className="input-with-icon-left">
						<input onChange={updateContrasena} maxLength="200" type="password" className="input-text with-border" placeholder="Contraseña" />
					</div>
					<div className="input-with-icon-left">
						<input onChange={updateContrasena2} maxLength="200" type="password" className="input-text with-border" placeholder="Confirmar contraseña" />
					</div>
					<button onClick={guardarContrasena} className="button full-width button-sliding-icon boton-enviar ripple-effect">Guardar <i className="icon-material-outline-arrow-right-alt"></i></button>
				</>
				}
				{datosContrasena.contrasenaEstablecida &&
					<div className="welcome-text">
						<h3>Gracias por completar tu registro.</h3>
						<div>De ahora en adelante puedes entrar a tu cuenta con tu correo y contraseña.</div>
					</div>
				}
			</div>
		</React.Fragment>
	);
}

export default EstablecerContrasena;
