import React, { useEffect, useState, useRef } from "react";
import { cicApiUrl, isInt } from "../common/share-util";

import './Pager.scss';

function Pager(props) {
    const pageSize = props.pageSize;
    const maxPageNumbers = props.maxPageNumbers;
    const [currentPage, setCurrentPage] = useState(0);
    const [pageNumbers, setPageNumbers] = useState([1]);
    const pageNumbersRef = useRef(pageNumbers);
    const elementosRef = useRef(props.elementos);

    useEffect(() => {
        setCurrentPage(props.currentPage);
    }, [props.currentPage]);

    useEffect(() => {
        let pageNumbers = [1];
        let changedElementos = false;
        if (elementosRef.current !== props.elementos) {
            elementosRef.current = props.elementos;
            changedElementos = true;
            setCurrentPage(0);
        }
        if (!props.elementos || (props.elementos.length === 0)) {
            setPageNumbers(pageNumbers);
            return;
        }
        if (changedElementos || !pageNumbersRef.current.includes(currentPage + 1) || pageNumbersRef.current.length === 1) {
            pageNumbers = [];
            let totalElementos = props.elementos.length;
            let totalPaginas = Math.min(Math.ceil(totalElementos / pageSize), maxPageNumbers);
            for (let i = 0, num = currentPage + 1; i < totalPaginas; i++, num++) {
                if (num <= (Math.ceil(props.elementos.length / pageSize))) {
                    pageNumbers.push(num);
                }
            }
            setPageNumbers(pageNumbers);
            pageNumbersRef.current = pageNumbers;
        }
    }, [props.elementos, currentPage]);

    function changePage(page, goToLast) {
        if (props.elementos) {
            let lastPage = Math.ceil(props.elementos.length / pageSize) - 1;
            if (page === null && goToLast) {
                props.onPageChange(lastPage);
                setCurrentPage(lastPage)
            } else {
                if (page < 0) {
                    page = 0;
                }
                if (page > lastPage) {
                    page = lastPage;
                }
                props.onPageChange(page);
                setCurrentPage(page);
            }
        }
    }

    return (
        <div className="cic-pager">
            {currentPage > 0 && <a href="#" onClick={() => { changePage(0) }} className="button btn-full">Primera</a>
            }
            {currentPage > 0 && <a href="#" onClick={() => { changePage(0) }} className="button button-mobile">&lt;&lt;</a>
            }
            <a href="#" onClick={() => { changePage(currentPage - 1) }} className="button btn-full">Anterior</a>
            <a href="#" onClick={() => { changePage(currentPage - 1) }} className="button button-mobile">&lt;</a>
            {
                pageNumbers.map((num) => {
                    return (
                        <a href="#" key={num} className={(currentPage + 1) === num ? 'current-page button' : 'button'} onClick={() => { changePage(num - 1) }}>{num}</a>
                    )
                })
            }
            <a href="#" onClick={() => { changePage(currentPage + 1) }} className="button btn-full">Siguiente</a>
            <a href="#" onClick={() => { changePage(currentPage + 1) }} className="button button-mobile">&gt;</a>
            {currentPage < (Math.ceil(props.elementos.length / pageSize) - 1) && <a href="#" onClick={() => { changePage(null, true) }} className="button btn-full">{'Última(' + (Math.ceil(props.elementos.length / pageSize)) + ')'}</a>
            }
            {currentPage < (Math.ceil(props.elementos.length / pageSize) - 1) && <a href="#" onClick={() => { changePage(null, true) }} className="button button-mobile">&gt;&gt;</a>
            }
        </div>
    );
}

export default Pager;
