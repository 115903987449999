import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { cicApiUrl } from "../common/share-util";

import './QuienesSomos.scss';

function QuienesSomos(props) {

	let history = useHistory();

	useEffect(() => {
		if (props.redirectAPublica) {
			props.onDidRedirect(true);
			history.push('/publica');
		}
	}, [props.redirectAPublica]);


	return (
		<React.Fragment>
			<h1 className="quienessomos-titulo">¿Quiénes Somos?</h1>

			<div className="quienessomos-content">
				<p>Este esfuerzo conjunto del CIC y <a href="https://www.conl.mx/" target="_blank">Consejo Nuevo León</a> permitirá enlazar a ciudadanos y OSC de todo el estado, identificar y dar seguimiento a las necesidades, grupos vulnerables y beneficiarios de manera oportuna, así como transparentar los beneficios otorgados y su impacto social.</p>
				<a href="https://www.cic.mx/" target="_blank"><img style={{width: '227px'}} src="https://apoyacic.s3.amazonaws.com/imagenes/logo_cic2.png"></img></a>
				<ul className="social-links">
					<li>
						<a href="https://www.facebook.com/CICMonterrey/"  target="_blank" title="Facebook" data-tippy-placement="bottom" data-tippy-theme="light">
							<i className="icon-brand-facebook-f"></i>
						</a>
					</li>
					<li>
						<a href="https://twitter.com/Cicmty"  target="_blank" title="Twitter" data-tippy-placement="bottom" data-tippy-theme="light">
							<i className="icon-brand-twitter"></i>
						</a>
					</li>
					<li>
						<a href="https://www.instagram.com/cicmonterrey/"  target="_blank" title="Instagram" data-tippy-placement="bottom" data-tippy-theme="light">
							<i className="icon-brand-instagram"></i>
						</a>
					</li>
					<li>
						<a href="https://www.youtube.com/channel/UCJX6Ot3zcYLebvx4yAkrbbQ" target="_blank" title="Youtube" data-tippy-placement="bottom" data-tippy-theme="light">
							<i className="icon-brand-youtube"></i>
						</a>
					</li>
					<li>
						<a href="https://www.linkedin.com/company/cicmx/"  target="_blank" title="LinkedIn" data-tippy-placement="bottom" data-tippy-theme="light">
							<i className="icon-brand-linkedin-in"></i>
						</a>
					</li>
				</ul>
			</div>
		</React.Fragment>
	);
}

export default QuienesSomos;
