import React, { useEffect, useState } from "react";
import "./DetalleDonacionDialog.scss";

function DetalleDonacionDialog(props) {

  return (
    <>
      <div id="small-dialog-2" className="zoom-anim-dialog mfp-hide dialog-with-tabs detalle-donacion-dialog">
        <div className="detalle-donacion-form">

          <div className="popup-tabs-container">

            <div className="popup-tab-content">

              <div className="welcome-text">
                <h3>Detalle donacion</h3>
                <span>De: {(props.donacion.name || '') + ' ' + (props.donacion.lastname || '')}</span>
              </div>
              <ul>
                {props.donacion.items && props.donacion.items.length > 0 &&
                  props.donacion.items.map((donacion) => {
                    return <li key={donacion._id}>
                      <span>{donacion.ticket}</span><div>${donacion.price}</div><div>{donacion.categoryName}</div><div>{donacion.message}</div>
                    </li>
                  })
                }
              </ul>
              {(!props.donacion.items || props.donacion.items.length === 0) &&
                <div style={{ textAlign: 'center' }}>No hay donaciones registradas</div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default DetalleDonacionDialog;